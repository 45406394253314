import moment from "moment";

export const genBeautifulDate = (date) => {
    const momentDate = moment(date);
    const diff = Math.abs(momentDate.diff(moment(), 'days'));

    const isToday = momentDate.isSame(new Date(), "day");

    if (diff > 60)
        return moment(date).format("DD/MM/YYYY HH:mm")
    if (isToday)
        return moment(date).format("HH:mm")
    else
        return moment(date).format("DD/MM HH:mm")
}