import React from 'react';

function SwapCamera(props:any) {
    return (
        <svg {...props} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 14C10 16.2133 11.7867 18 14 18C16.2133 18 18 16.2133 18 14C18 11.7867 16.2133 10 14 10C11.7867 10 10 11.7867 10 14Z" fill="white"/>
            <path d="M8.66663 9.99996C8.66663 9.26663 8.06663 8.66663 7.33329 8.66663H4.78663C6.62663 5.47996 10.0666 3.33329 14 3.33329C18.6533 3.33329 22.6 6.31996 24.0533 10.48C24.24 11 24.76 11.3333 25.3066 11.3333C26.2133 11.3333 26.88 10.44 26.5866 9.58663C24.76 4.38663 19.8133 0.666626 14 0.666626C9.63996 0.666626 5.75996 2.77329 3.33329 6.01329V4.66663C3.33329 3.93329 2.73329 3.33329 1.99996 3.33329C1.26663 3.33329 0.666626 3.93329 0.666626 4.66663V9.99996C0.666626 10.7333 1.26663 11.3333 1.99996 11.3333H7.33329C8.06663 11.3333 8.66663 10.7333 8.66663 9.99996Z" fill="white"/>
            <path d="M19.3333 18C19.3333 18.7333 19.9333 19.3333 20.6666 19.3333H23.2133C21.3733 22.52 17.9333 24.6666 14 24.6666C9.34663 24.6666 5.39997 21.68 3.94663 17.52C3.75997 17 3.23997 16.6666 2.6933 16.6666C1.78663 16.6666 1.11997 17.56 1.4133 18.4133C3.23997 23.6133 8.18664 27.3333 14 27.3333C18.36 27.3333 22.24 25.2266 24.6666 21.9866V23.3333C24.6666 24.0666 25.2666 24.6666 26 24.6666C26.7333 24.6666 27.3333 24.0666 27.3333 23.3333V18C27.3333 17.2666 26.7333 16.6666 26 16.6666H20.6666C19.9333 16.6666 19.3333 17.2666 19.3333 18Z" fill="white"/>
        </svg>
    );
}

export default SwapCamera;