import React from 'react';
import styled, {css} from "styled-components";

const StyledPhone = styled.svg(({theme: {svg}}) => css`
  path {
    fill: ${svg.color1};
  }
`);

function Phone(props: any) {
    return (
        <StyledPhone {...props} width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M10.3136 8.62987L12.9102 10.2421C13.3149 10.5619 13.0308 11.3518 12.695 11.7945L12.6224 11.8837L12.4958 12.0178C11.9282 12.5746 11.12 12.814 10.3407 12.6562L10.1615 12.6128L9.75106 12.4885C7.9935 11.9223 6.34076 10.9484 4.79284 9.56687L4.77997 9.58052C4.62683 9.43671 4.47609 9.29063 4.32775 9.14228L4.18943 9.00044L4.04739 8.86193L3.82657 8.63761L3.60915 8.40971L3.62205 8.396C2.2409 6.84832 1.26727 5.19585 0.70119 3.43861L0.576911 3.02816C0.340755 2.19538 0.572285 1.30518 1.17187 0.693854L1.30595 0.567247C1.72264 0.202914 2.6049 -0.154121 2.9476 0.279484L4.5598 2.87612C4.73134 3.1539 4.76993 3.49106 4.67044 3.79717L4.61934 3.9262L4.09611 4.55007C3.93411 4.89425 3.96191 5.29408 4.16185 5.61012L4.24486 5.72444C4.71326 6.41742 4.86925 6.71474 5.67364 7.51914C6.47804 8.32354 7.46523 8.94481 7.46523 8.94481L7.57955 9.02782C7.85609 9.20277 8.19677 9.24592 8.5082 9.14545L8.6396 9.09356L9.26347 8.57033L9.3925 8.51923C9.65488 8.43396 9.94007 8.45013 10.1911 8.56451L10.3136 8.62987L12.9102 10.2421L10.3136 8.62987Z"
                  fill="#8E8E93"/>
        </StyledPhone>
    );
}

export default Phone;