import React from 'react';
import styled, {css} from "styled-components";

const StyledUserBackgroundSvg = styled.svg(({theme: {svg}}) => css`
  path, circle {
    stroke: ${svg.color4};
  }
`);

function UserBackgroundSvg(props: any) {
    return (
        <StyledUserBackgroundSvg {...props} width="428" height="428" viewBox="0 0 428 428" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
            <path
                d="M351.807 364.639C343.679 341.887 325.768 321.783 300.852 307.444C275.936 293.105 245.407 285.333 214.002 285.333C182.596 285.333 152.068 293.105 127.152 307.444C102.236 321.783 84.3247 341.887 76.1962 364.639"
                stroke="#E9ECF6" strokeWidth="35.6667" strokeLinecap="round"/>
            <circle cx="213.998" cy="142.666" r="71.3333" stroke="#E9ECF6" strokeWidth="35.6667"
                    strokeLinecap="round"/>
        </StyledUserBackgroundSvg>
    );
}

export default UserBackgroundSvg;