import {initialState} from "../initialState";
import {
    CHANGE_CHATS,
    CHANGE_CONTACTS,
    CHANGE_DIALOGS,
    CHANGE_ENCRYPTION, CHANGE_I_CREATOR,
    CHANGE_LOCK, CHANGE_OPTIONS,
    CHANGE_STREAMS, MENU_COLLAPSED
} from "../actionTypes";

export function pageReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_DIALOGS:
            return {...state, dialogs: action.payload};
        case CHANGE_CHATS:
            return {...state, chats: action.payload};
        case CHANGE_CONTACTS:
            return {...state, contacts: action.payload};
        case CHANGE_LOCK:
            return {...state, lockInfo: action.payload};
        case CHANGE_ENCRYPTION:
            return {...state, encryption: action.payload};
        case CHANGE_STREAMS:
            return {...state, streams: action.payload[0], editedStreams: action.payload[1]};
        case CHANGE_OPTIONS:
            return {...state, hideOptions: action.payload};
        case CHANGE_I_CREATOR:
            return {...state, iCreator: action.payload};
        case MENU_COLLAPSED:
            return {...state, menuCollapsed: action.payload};
        default:
            return state;
    }
}