import React from 'react';

function BigLockerLogin(props: any) {
    return (
        <svg {...props} version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
             x="0px" y="0px"
             width="428px" height="336px" viewBox="0 0 428 336"
             xmlSpace="preserve">
        <g>
        <path fill="#FFFFFF" d="M385.7,82.3l-27.6-21.9c-1.5-1.2-3.4-1.9-5.2-1.9h-37.5c-0.9-1.8-2.1-3.3-3.6-4.5L273.8,24
    c-2.1-1.6-4.6-2.5-7.2-2.5h-71.9h-46.5c-2.6,0-5.1,0.9-7.2,2.5l-37.9,30.1c-1.5,1.2-2.7,2.8-3.6,4.5H62.1c-1.9,0-3.7,0.6-5.2,1.9
    L29.3,82.3c-2.4,1.9-3.8,4.9-3.8,8.2V98h0c0,28.5,0,56.9,0,85.4c0,12.3,4.1,23.1,10.4,33c8,12.5,18.3,22.4,29.3,31.3
    c11.5,9.3,23.8,17.2,36.7,24.1c2.1,1.1,4.6,1.1,6.7,0c7-3.8,13.8-7.8,20.4-12.1c1,1.1,2,2.1,3,3.2c21.1,21.4,45.2,37.8,70.9,51.6
    c2.9,1.5,6.2,1.5,9.1,0c17.7-9.4,34.6-20.3,50.4-33.1c8.3-6.7,16.3-13.9,23.6-21.7c6.6,4.4,13.5,8.4,20.5,12.2
    c2.1,1.1,4.6,1.1,6.7,0c12.8-6.9,25.1-14.8,36.7-24.1c11.1-8.9,21.3-18.8,29.3-31.3c6.3-9.9,10.4-20.6,10.4-33
    c0-28.5,0-56.9,0-85.4h0v-7.5C389.5,87.2,388.1,84.2,385.7,82.3z M369,210.8c-7.2,10.6-16.1,19.2-25.8,26.9
    c-10.2,8.2-20.9,15.2-32.2,21.4c-0.7,0.4-1.9,0.4-2.6,0c-15.7-8.8-30.7-18.8-44-31.8c-0.9-0.8-1.7-1.7-2.6-2.6
    c-4.6-4.8-11.5-5.7-17-2.1l-0.1,0.1c0,0,0,0,0,0.1c3.1,4.1,6.5,7.9,10.1,11.5c5.7,5.8,11.7,11,17.9,15.9
    c-6.1,6.3-12.6,12.1-19.4,17.5c-14,11.2-28.8,20.9-44.2,29.3c-1,0.5-2.6,0.5-3.6,0c-21.6-12-42.2-25.8-60.4-43.7c-1-1-2-2-3-3
    c6.3-4.9,12.3-10.2,18-15.9c3.6-3.6,7-7.4,10.1-11.5c0,0,0,0,0-0.1l-0.1-0.1c-5.4-3.6-12.3-2.7-17,2.1c-0.9,0.9-1.7,1.7-2.6,2.6
    c-13.2,13-28.2,23-44,31.8c-0.7,0.4-1.9,0.4-2.6,0c-11.2-6.2-22-13.2-32.2-21.4c-9.6-7.7-18.5-16.3-25.8-26.9
    c-6.3-9.2-9.6-19.3-9.5-31L36.6,98h0v-2.3c0-1.2,0.5-2.2,1.4-2.9L64.2,72c0.5-0.4,1.2-0.7,1.9-0.7h48.4h29.9c0.7,0,1.3,0.2,1.9,0.7
    c4.2,3.4,9.9,3.2,14-0.3c1.2-1,2.5-2,3.9-2.9l-10.6-8.4c-1.5-1.2-3.4-1.9-5.2-1.9h-20.8L151,39.9c0.7-0.6,1.6-0.9,2.6-0.9h41.1
    h66.5c0.9,0,1.8,0.3,2.6,0.9l23.5,18.6h-20.6c-1.9,0-3.7,0.6-5.2,1.9l-10.6,8.4c1.3,0.9,2.6,1.9,3.9,2.9c4.2,3.4,9.8,3.6,14,0.3
    c0.5-0.4,1.2-0.7,1.9-0.7h29.9h48.4c0.7,0,1.3,0.2,1.9,0.7L377,92.8c0.8,0.7,1.4,1.8,1.4,2.9V98h0l0.1,81.8
    C378.6,191.6,375.2,201.7,369,210.8z"/>
            <path fill="#FFFFFF" d="M260.6,109.4c-2.7-17.5-14.9-27.2-15.5-27.6l-4.5-3.4L226,100c-6-2.4-12.5-3.7-19.2-3.6
    c-5.9,0.1-11.6,1.3-17,3.3l-15.1-22.3l-4.5,3.4c-0.5,0.4-12.8,10.1-15.5,27.6c-1.2,7.8-0.3,15.8,2.7,23.9c-3.8,8.8-5.9,18.6-5.9,29
    v27.3c0,2.5,1.1,4.8,3,6l13.6,9.2c5.2,3.6,9.8,8.2,13.5,13.8c1.1,1.6,2.2,3.4,3.5,5.4c4,9.3,13.2,8.2,13.2,8.2h9.2h9.2
    c0,0,9.2,1.1,13.2-8.2c1.3-2,2.5-3.8,3.5-5.4c3.7-5.5,8.3-10.2,13.5-13.8l13.6-9.2c1.9-1.3,3-3.6,3-6v-28.3h0
    c0-9.9-2-19.2-5.4-27.6C260.9,125,261.7,117.1,260.6,109.4z M242.6,96.9c2.6,3.3,5.7,8.4,6.6,15c0.3,2.1,0.4,4.2,0.2,6.3
    c-3.7-4.8-8.1-9-12.9-12.4L242.6,96.9z M166.1,110.9c1-6.6,4-11.7,6.6-15l6.4,9.5c-4.9,3.4-9.4,7.7-13.2,12.6
    C165.7,115.6,165.7,113.2,166.1,110.9z M249.5,184.7l-13.7,9.3c-3.6,2.4-6.6,5.7-8.9,9.6c-3.3,5.6-9.7,13.3-19.4,13.3
    c-9.8,0-16.2-7.9-19.4-13.5c-2.3-3.9-5.4-7.2-9-9.6l-13.6-9.2c0,0-1.4-0.8-1.4-3.9l1-20.2c0-26.8,18.9-48.5,42.3-48.5
    c23.4,0,42.3,21.7,42.3,48.5h0l1,20.2C250.8,183.9,249.5,184.7,249.5,184.7z"/>
            <path fill="#FFFFFF" d="M207.5,182.5c-3.9,0-7,8.3-7,12.8c0,4.5,3.1,8.2,7,8.2c3.9,0,7-3.6,7-8.2
    C214.5,190.8,211.4,182.5,207.5,182.5z"/>
            <path fill="#FFFFFF" d="M127.8,158.7c1.4-3.6,1.8-7.2,1.2-10.7c-1.3-8-7.5-12.4-7.7-12.6l-2.2-1.6l-7.3,9.8c-3-1.1-6.2-1.7-9.6-1.6
    c-3,0-5.8,0.6-8.5,1.5l-7.6-10.1l-2.2,1.6c-0.3,0.2-6.4,4.6-7.7,12.6c-0.6,3.5-0.1,7.2,1.3,10.9c-1.9,4-3,8.5-3,13.2v12.4
    c0,1.1,0.6,2.2,1.5,2.7l6.8,4.2c2.6,1.6,4.9,3.7,6.7,6.3c0.5,0.7,1.1,1.5,1.8,2.5c2,4.2,6.6,3.7,6.6,3.7h4.6h4.6
    c0,0,4.6,0.5,6.6-3.7c0.6-0.9,1.2-1.7,1.8-2.5c1.8-2.5,4.1-4.6,6.7-6.3l6.8-4.2c0.9-0.6,1.5-1.6,1.5-2.7v-12.9h0
    C130.5,166.8,129.5,162.5,127.8,158.7z M120,142.3c1.3,1.5,2.8,3.8,3.3,6.8c0.1,0.9,0.2,1.9,0.1,2.9c-1.9-2.2-4-4.1-6.4-5.6
    L120,142.3z M81.8,148.7c0.5-3,2-5.3,3.3-6.8l3.2,4.3c-2.5,1.5-4.7,3.5-6.6,5.7C81.6,150.8,81.6,149.7,81.8,148.7z M123.5,182.2
    l-6.9,4.2c-1.8,1.1-3.3,2.6-4.4,4.4c-1.7,2.5-4.8,6.1-9.7,6.1c-4.9,0-8.1-3.6-9.7-6.1c-1.2-1.8-2.7-3.3-4.5-4.4l-6.8-4.2
    c0,0-0.7-0.4-0.7-1.8l0.5-9.2c0-12.2,9.5-22,21.2-22c11.7,0,21.2,9.9,21.2,22h0l0.5,9.2C124.1,181.9,123.5,182.2,123.5,182.2z"/>
            <path fill="#FFFFFF" d="M99,182.5c-1.9,0-3.5,2.8-3.5,4.3c0,1.5,1.6,2.7,3.5,2.7c1.9,0,3.5-1.2,3.5-2.7
    C102.5,185.3,100.9,182.5,99,182.5z"/>
            <path fill="#FFFFFF" d="M337.8,158.7c1.4-3.6,1.8-7.2,1.2-10.7c-1.3-8-7.5-12.4-7.7-12.6l-2.2-1.6l-7.3,9.8c-3-1.1-6.2-1.7-9.6-1.6
    c-3,0-5.8,0.6-8.5,1.5l-7.6-10.1l-2.2,1.6c-0.3,0.2-6.4,4.6-7.7,12.6c-0.6,3.5-0.1,7.2,1.3,10.9c-1.9,4-3,8.5-3,13.2v12.4
    c0,1.1,0.6,2.2,1.5,2.7l6.8,4.2c2.6,1.6,4.9,3.7,6.7,6.3c0.5,0.7,1.1,1.5,1.8,2.5c2,4.2,6.6,3.7,6.6,3.7h4.6h4.6
    c0,0,4.6,0.5,6.6-3.7c0.6-0.9,1.2-1.7,1.8-2.5c1.8-2.5,4.1-4.6,6.7-6.3l6.8-4.2c0.9-0.6,1.5-1.6,1.5-2.7v-12.9h0
    C340.5,166.8,339.5,162.5,337.8,158.7z M330,142.3c1.3,1.5,2.8,3.8,3.3,6.8c0.1,0.9,0.2,1.9,0.1,2.9c-1.9-2.2-4-4.1-6.4-5.6
    L330,142.3z M291.8,148.7c0.5-3,2-5.3,3.3-6.8l3.2,4.3c-2.5,1.5-4.7,3.5-6.6,5.7C291.6,150.8,291.6,149.7,291.8,148.7z
    M333.5,182.2l-6.9,4.2c-1.8,1.1-3.3,2.6-4.4,4.4c-1.7,2.5-4.8,6.1-9.7,6.1c-4.9,0-8.1-3.6-9.7-6.1c-1.2-1.8-2.7-3.3-4.5-4.4
    l-6.8-4.2c0,0-0.7-0.4-0.7-1.8l0.5-9.2c0-12.2,9.5-22,21.2-22c11.7,0,21.2,9.9,21.2,22h0l0.5,9.2
    C334.1,181.9,333.5,182.2,333.5,182.2z"/>
            <path fill="#FFFFFF" d="M309,182.5c-1.9,0-3.5,2.8-3.5,4.3c0,1.5,1.6,2.7,3.5,2.7c1.9,0,3.5-1.2,3.5-2.7
    C312.5,185.3,310.9,182.5,309,182.5z"/>
    </g>
</svg>
    );
}

export default BigLockerLogin;