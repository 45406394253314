export const initialState = {
    loginReducer: {
        loading: true,
        userData: {}
    },
    pageReducer: {
        hideOptions: false,
        encryption: localStorage.getItem("defender_encryption") === "true" || false,
        lockInfo: {},
        dialogs: {},
        chats: [],
        contacts: [],
        streams: [],
        editedStreams: [],
        iCreator: false,
        menuCollapsed: false
    }
}