import React from 'react';
import styled, {css} from "styled-components";

const StyledModalAddButtonSVG = styled.svg(({theme: {svg}}) => css`
  rect {
    fill: ${svg.color2};
  }
`);

function ModalAddButtonSvg(props: any) {
    return (
        <StyledModalAddButtonSVG {...props} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="48" height="48" rx="10" fill="#3497F9"/>
            <path d="M24 18L24 30" stroke="#CCD2E3" strokeWidth="2" strokeLinecap="round"/>
            <path d="M30 24L18 24" stroke="#CCD2E3" strokeWidth="2" strokeLinecap="round"/>
        </StyledModalAddButtonSVG>
    );
}

export default ModalAddButtonSvg;