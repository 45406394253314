import React, {useEffect, useRef} from 'react';
import {Route, Router, Switch} from 'react-router-dom';
import 'antd/dist/antd.compact.css';
import './styles/fonts/roboto/roboto.css';
import history from "./utils/history";
import styled, {ThemeProvider} from "styled-components";
import {theme} from "./theme";
import Layout from "./layouts/Layout";
import store from "./redux/store";
import {refreshTokenAction} from "./redux/actions/loginActions";
import Login from "./pages/Login";
import Chats from "./pages/Chats";
import Room from "./pages/Room";
import Calls from "./pages/Calls";
import Contacts from "./pages/Contacts";
import MyProfile from "./pages/MyProfile";
import {connect} from "react-redux";
import Dialog from "./pages/Dialog";
import {getWS} from "./utils/websocket";
import {changeEncryption, changeHideOptions, getLock} from "./redux/actions/pageActions";
import Options from "./pages/Options";
import Users from "./pages/Users";
import axios from "axios";
import Visualiser from "./components/Visualiser";
import Groups from "./pages/Groups";
import Dashboard from "./pages/Dashboard";
import DialogSettings from "./pages/DialogSettings";
import RestartApp from "./pages/RestartApp";
import Settings from "./pages/Settings";

export const StyledApp = styled.div`
  display: flex;
  overflow: hidden;
  max-width: 100vw;
  min-height: 100vh;
  //max-height: 100vh;

  .callModal {
    .ant-modal-confirm-btns {
      display: none;
    }

    .ant-modal-body {
      padding: 0;
    }

    .ant-modal-content {
      background: none;
      box-shadow: none;
    }
  }

  * {
    font-family: Roboto, sans-serif;
  }
`;

function App({loading, encryption}: any) {

    const visualizer = useRef<any>({});


    axios.interceptors.response.use(function (response) {
        // Do something with response data
        return response;
    }, function (error) {
        // Do something with response error
        if (error.response && error.response.status === 401 && history.location.pathname !== "/login") {
            history.push("/restart");
        }
        return Promise.reject(error);
    });

    useEffect(() => {
        store.dispatch(refreshTokenAction());
        const [, interval]: any = getWS(visualizer);
        //
        // setInterval(()=>{
        //     console.log("inerval");
        //     console.log(!!visualizer.current);
        //     visualizer.current && visualizer.current();
        // }, 1000)

        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        console.log(window.location);
        console.log(params);

        if (params.settings) {
            store.dispatch(changeHideOptions(params.settings === "false"))
        }
        if (params.encrypt) {
            console.log(params.encrypt);
            localStorage.setItem("defender_encryption", params.encrypt);
            store.dispatch(changeEncryption(params.encrypt === "true"));
        }
        if (params.rate) {
            console.log(params.encrypt);
            localStorage.setItem("defender_rate", params.rate);
        }
        if (params.resolution) {
            console.log(params.encrypt);
            localStorage.setItem("defender_resolution", params.resolution);
        }
        if (store.getState().pageReducer.encryption)
            store.dispatch(getLock());

        const lockInterval = setInterval(() => {
            if (store.getState().pageReducer.encryption)
                store.dispatch(getLock());
        }, 5000)


        // console.log(navigator.mediaDevices.getSupportedConstraints());
        // navigator.mediaDevices.enumerateDevices().then((el) => console.log(el.map(el => el)))
        return () => {
            clearInterval(interval);
            clearInterval(lockInterval);
        }
    }, [])

    return (
        <Router history={history}>
            <ThemeProvider theme={theme}>
                <StyledApp>
                    <Switch>
                        <Route path={"/restart"} exact>
                            <RestartApp/>
                        </Route>
                        <Route path={"/login"} exact>
                            <Login/>
                        </Route>
                        <Route path={"/visualiser"} exact>
                            <Visualiser visualizer={visualizer}/>
                        </Route>
                        <React.Fragment>
                            {loading ? <div>loading</div> : <React.Fragment>
                                <Route path={"/"} exact>
                                    <Layout title={"Chats"}>
                                        <Chats/>
                                    </Layout>
                                </Route>
                                <Route path={"/calls"} exact>
                                    <Layout title={"Calls"}>
                                        <Calls/>
                                    </Layout>
                                </Route>
                                <Route path={"/contacts"} exact>
                                    <Layout title={"Contacts"}>
                                        <Contacts/>
                                    </Layout>
                                </Route>
                                <Route path={"/profile"} exact>
                                    <Layout title={"Your Profile"}>
                                        <MyProfile/>
                                    </Layout>
                                </Route>
                                <Route path={"/options"} exact>
                                    <Layout title={"Options"}>
                                        <Options/>
                                    </Layout>
                                </Route>
                                <Route path={"/users"} exact>
                                    <Layout title={"Users"}>
                                        <Users/>
                                    </Layout>
                                </Route>
                                <Route path={"/groups"} exact>
                                    <Layout title={"Groups"}>
                                        <Groups/>
                                    </Layout>
                                </Route>
                                <Route path={"/settings"} exact>
                                    <Layout className={"scrollable"} title={"Settings"}>
                                        <Settings/>
                                    </Layout>
                                </Route>
                                <Route path={"/dashboard"} exact>
                                    <Layout title={"Dashboard"}>
                                        <Dashboard visualizer={visualizer}/>
                                    </Layout>
                                </Route>
                                <Route path={"/dialog/:chatId"} exact render={props => <Dialog {...props}/>}/>
                                <Route path={"/dialog/:chatId/settings"} exact render={props => <DialogSettings {...props}/>}/>
                                <Route path={"/video/:userId"} exact render={props => <Room {...props}/>}/>
                            </React.Fragment>}
                        </React.Fragment>
                    </Switch>
                </StyledApp>
            </ThemeProvider>
        </Router>
    );
}

const mapStateToProps = (state: any) => {
    return {
        loading: state.loginReducer.loading,
        encryption: state.pageReducer.encryption,
    }
}
export default connect(mapStateToProps, null)(App);
