import React from 'react';

function ZoomSvg(props:any) {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="11" cy="11" r="7" stroke="#A9A9A9" strokeWidth="2"/>
            <path d="M20 20L17 17" stroke="#A9A9A9" strokeWidth="2" strokeLinecap="round"/>
        </svg>
    );
}

export default ZoomSvg;