import React from 'react';

function HeadphonesSvg(props: any) {
    return (
        <svg {...props} width="24" height="23" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="13" y="9" width="4" height="7" rx="2" stroke="white" strokeWidth="2" strokeLinejoin="round"/>
            <rect x="1" y="9" width="4" height="7" rx="2" stroke="white" strokeWidth="2" strokeLinejoin="round"/>
            <path d="M1 10V13" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17 10V13" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M17 10C17 7.61305 16.1571 5.32387 14.6569 3.63604C13.1566 1.94821 11.1217 1 9 1C6.87827 1 4.84344 1.94821 3.34315 3.63604C1.84286 5.32387 1 7.61305 1 10"
                stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    );
}

export default HeadphonesSvg;