import styled, {css} from "styled-components";
import {Input, InputNumber} from "antd";

const styleBase = css`
  border: none;
  border-bottom: 1px solid #DADADA;
  border-radius: 0;
  box-shadow: none !important;
  font-size: 16px;
  background: none;

  input {
    font-size: 16px;
    color: #010b2b;
    background: none;
  }
`

const StyledInput = styled(Input)`
  ${styleBase};
`;

const StyledInputPassword = styled(Input.Password)`
  ${styleBase};
`;
const StyledInputNumber = styled(InputNumber)`
  width: 100%;
  ${styleBase};
`;

StyledInput.Password = StyledInputPassword
// @ts-ignore
export const StyledNumber = StyledInputNumber

export default StyledInput;