import React from 'react';

function CheckedOnSvg(props:any) {
    return (
        <svg {...props} width="64" height="34" viewBox="0 0 64 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="64" height="34" rx="17" fill="#1DC20F"/>
            <ellipse cx="47.0593" cy="16.999" rx="13.1765" ry="13.2222" fill="#EBEBEB"/>
            <path
                d="M19.6826 16.8711C19.6826 17.8464 19.514 18.7031 19.1768 19.4414C18.8395 20.1751 18.3564 20.7402 17.7275 21.1367C17.1032 21.5286 16.3831 21.7246 15.5674 21.7246C14.7607 21.7246 14.0407 21.5286 13.4072 21.1367C12.7783 20.7402 12.2907 20.1774 11.9443 19.4482C11.6025 18.7191 11.4294 17.8783 11.4248 16.9258V16.3652C11.4248 15.3945 11.5957 14.5378 11.9375 13.7949C12.2839 13.0521 12.7692 12.4847 13.3936 12.0928C14.0225 11.6963 14.7425 11.498 15.5537 11.498C16.3649 11.498 17.0827 11.694 17.707 12.0859C18.3359 12.4733 18.8213 13.0339 19.1631 13.7676C19.5049 14.4967 19.6781 15.3467 19.6826 16.3174V16.8711ZM17.9531 16.3516C17.9531 15.2487 17.7435 14.4033 17.3242 13.8154C16.9095 13.2275 16.3193 12.9336 15.5537 12.9336C14.8063 12.9336 14.2207 13.2275 13.7969 13.8154C13.3776 14.3988 13.1634 15.2259 13.1543 16.2969V16.8711C13.1543 17.9648 13.3662 18.8102 13.79 19.4072C14.2184 20.0042 14.8109 20.3027 15.5674 20.3027C16.333 20.3027 16.9209 20.0111 17.3311 19.4277C17.7458 18.8444 17.9531 17.9922 17.9531 16.8711V16.3516ZM22.7861 14.1914L22.834 15.0459C23.3809 14.3851 24.0986 14.0547 24.9873 14.0547C26.5277 14.0547 27.3115 14.9365 27.3389 16.7002V21.5879H25.6777V16.7959C25.6777 16.3265 25.5752 15.9801 25.3701 15.7568C25.1696 15.529 24.8392 15.415 24.3789 15.415C23.709 15.415 23.21 15.7181 22.8818 16.3242V21.5879H21.2207V14.1914H22.7861Z"
                fill="white"/>
        </svg>
    );
}

export default CheckedOnSvg;