import React from 'react';
import styled from "styled-components";
import {connect} from "react-redux";

const StyledLock = styled.div<any>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;

  &.error {
    svg {
      path {
        fill: #ca4747;
      }
    }
  }

  .filling {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    overflow: hidden;
    width: ${props => {
      console.log("props", props.Percent)
      return props.Percent
    }}%;

    svg {
      position: absolute;

      path {
        fill: #33CC33;
      }
    }
  }

  .text {
    width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    color: white;
    font-size: 7px;
    font-weight: bold;
  }
`;

function Lock({lockInfo: {State, Percent, Status}, ...props}: any) {

    return (
        <StyledLock Percent={Percent} className={!Status? "error" : ""}>
            <svg {...props} width="39" height="28" viewBox="0 0 39 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M13.58 24.6909C19.7164 24.6909 24.6909 19.7164 24.6909 13.58C24.6909 7.44361 19.7164 2.46909 13.58 2.46909C7.44361 2.46909 2.46909 7.44361 2.46909 13.58C2.46909 19.7164 7.44361 24.6909 13.58 24.6909ZM13.58 27.16C17.0241 27.16 20.1687 25.8779 22.5626 23.765H28.3945C34.0196 23.765 38.5795 19.205 38.5795 13.58C38.5795 7.95497 34.0196 3.39499 28.3945 3.39499H22.5626C20.1686 1.28207 17.024 0 13.58 0C6.07997 0 0 6.07997 0 13.58C0 21.08 6.07997 27.16 13.58 27.16ZM28.3945 20.6786H25.1592C26.4282 18.6131 27.16 16.182 27.16 13.58C27.16 10.978 26.4282 8.54693 25.1592 6.48135H28.3945C32.315 6.48135 35.4932 9.65952 35.4932 13.58C35.4932 17.5005 32.315 20.6786 28.3945 20.6786ZM13.58 23.4564C19.0346 23.4564 23.4564 19.0346 23.4564 13.58C23.4564 8.12543 19.0346 3.70363 13.58 3.70363C8.12546 3.70363 3.70366 8.12543 3.70366 13.58C3.70366 19.0346 8.12546 23.4564 13.58 23.4564Z"
                      fill="#577DC3"/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M2.46909 13.58C2.46909 7.44361 7.44361 2.46909 13.58 2.46909V0C6.07997 0 0 6.07997 0 13.58C0 21.08 6.07997 27.16 13.58 27.16V24.6909C7.44361 24.6909 2.46909 19.7164 2.46909 13.58ZM13.58 23.4564C8.12546 23.4564 3.70366 19.0346 3.70366 13.58C3.70366 8.12543 8.12546 3.70363 13.58 3.70363V23.4564Z"
                      fill="#577DC3"/>
            </svg>
            {Status && <div className={"filling"}>
                <svg width="39" height="28" viewBox="0 0 39 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M13.58 24.6909C19.7164 24.6909 24.6909 19.7164 24.6909 13.58C24.6909 7.44361 19.7164 2.46909 13.58 2.46909C7.44361 2.46909 2.46909 7.44361 2.46909 13.58C2.46909 19.7164 7.44361 24.6909 13.58 24.6909ZM13.58 27.16C17.0241 27.16 20.1687 25.8779 22.5626 23.765H28.3945C34.0196 23.765 38.5795 19.205 38.5795 13.58C38.5795 7.95497 34.0196 3.39499 28.3945 3.39499H22.5626C20.1686 1.28207 17.024 0 13.58 0C6.07997 0 0 6.07997 0 13.58C0 21.08 6.07997 27.16 13.58 27.16ZM28.3945 20.6786H25.1592C26.4282 18.6131 27.16 16.182 27.16 13.58C27.16 10.978 26.4282 8.54693 25.1592 6.48135H28.3945C32.315 6.48135 35.4932 9.65952 35.4932 13.58C35.4932 17.5005 32.315 20.6786 28.3945 20.6786ZM13.58 23.4564C19.0346 23.4564 23.4564 19.0346 23.4564 13.58C23.4564 8.12543 19.0346 3.70363 13.58 3.70363C8.12546 3.70363 3.70366 8.12543 3.70366 13.58C3.70366 19.0346 8.12546 23.4564 13.58 23.4564Z"
                          fill="#577DC3"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M2.46909 13.58C2.46909 7.44361 7.44361 2.46909 13.58 2.46909V0C6.07997 0 0 6.07997 0 13.58C0 21.08 6.07997 27.16 13.58 27.16V24.6909C7.44361 24.6909 2.46909 19.7164 2.46909 13.58ZM13.58 23.4564C8.12546 23.4564 3.70366 19.0346 3.70366 13.58C3.70366 8.12543 8.12546 3.70363 13.58 3.70363V23.4564Z"
                          fill="#03134C"/>
                </svg>
            </div>}
            <div className="text">
                {Status ? `${Percent}%` : false}
            </div>
        </StyledLock>
    );
}

const mapStateToProps = (state: any) => {
    return {
        lockInfo: state.pageReducer.lockInfo
    }
}
export default connect(mapStateToProps, null)(Lock);