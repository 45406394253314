import React from 'react';
import styled, {css} from "styled-components";
import logo from "../styles/images/logo.svg";
import DashboardDeskSvg from "../styles/images/DashboardDeskSVG";
import UserDeskSvg from "../styles/images/UserDeskSVG";
import OptionsDeskSvg from "../styles/images/OptionsDeskSVG";
import HideMenuSvg from "../styles/images/HideMenuSVG";
import {Link} from "react-router-dom";
import LittleLogoMenuDeskSvg from "../styles/images/LittleLogoMenuDeskSVG";
import LogoutSvg from "../styles/images/LogoutSVG";
import {detectIOS} from "../utils/detectIOS";
import {connect} from "react-redux";
import {api, localCrypterIp, localCrypterIp_IOS} from '../config';
import GroupSvg from "../styles/images/GroupSvg";
import store from "../redux/store";
import {switchMenuCollapsableAction} from "../redux/actions/pageActions";
import FingerprintKeySvg from "../styles/images/FingerprintKeySvg";
import FingerprintScanSvg from "../styles/images/FingerprintScanSvg";
import axios from "axios";
import {message} from "antd";
import UpgradeSvg from "../styles/images/UpgradeSvg";


export const Logo = styled.div`
  display: flex;

  img, svg {
    height: 45px;
  }
`;

export const Text = styled.div`
  color: #fff;

  &.notActive {
    color: #8189A5;
  }
`;

export const Buttons = styled.div`
  margin-top: 100px;
  width: 100%;
  margin-bottom: 25px;
`;

export const FooterItem = styled.div<any>`
  display: flex;
  align-items: center;
  color: #8189a5;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;

  &.disabled {
    cursor: not-allowed;

    ${Text} {
      color: #8189A5;
    }
  }

  svg {
    margin-right: 25px;
    flex: 0 0 auto;
    width: 28px;
    height: 28px;
  }
`;

export const FooterItemLink = styled.a`
  display: flex;
  align-items: center;
  color: #8189a5;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;

  &.disabled {
    cursor: not-allowed;
  }

  svg {
    margin-right: 25px;
  }
`;

export const AdminPart = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: 50px;
`;

export const Footer = styled.div`
  margin-top: auto;
  width: 100%;
  padding-left: 25px;

  ${FooterItem}:not(:last-child) {
    margin-bottom: 50px;
  }
`;

export const StyledGroupSvg = styled(GroupSvg)`
  height: 28px;
`;

export const MenuItem = styled(Link)(({theme: {svg}}) => css`
  display: flex;
  align-items: center;
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 25px;
  border-radius: 12px;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }

  svg {
    path {
      fill: ${svg.color5};
    }
  }

  &:hover {
    background: white;
    color: #4f4f4f;

    ${Text} {
      color: #4f4f4f;
    }

    svg {
      path {
        fill: ${svg.color4};
      }
    }
  }
`);


export const StyledDesktopMenu = styled.div<any>(({isHidden, theme: {desktopMenu}}) => css`
  display: none;
  flex-direction: column;
  align-items: center;
  max-width: ${isHidden ? "120px" : "none"};
  min-width: ${isHidden ? "none" : "280px"};
  background: ${desktopMenu.background};
  width: 100%;
  height: 100%;
  flex: 1 1 auto;
  padding: 40px 20px;
  overflow-y: auto;

  svg {
    flex: 0 0 auto;
  }

  &::-webkit-scrollbar {
    width: 0;
  }

  svg.menuHidden {
    transform: rotateY(180deg);
  }

  ${Text} {
    display: ${isHidden ? "none" : "inline"};
  }

  ${MenuItem} {

    svg {
      margin-right: ${isHidden ? "0" : "25px"};
    }
  }

  img {
    width: 153px;
  }
`);

type DesktopMenuProps = {
    encryption?: any,
    userData?: any,
    menuCollapsed?: boolean
}

function DesktopMenu({encryption, menuCollapsed, userData}: DesktopMenuProps) {

    const upgrade = () => {
        axios(`${api}/upgrade`).then(({data}) => {
            message.success(data)
        }).catch(({response: {data}}) => {
            message.error(data || "error")
        })
    }

    const addFingerprint = () => {
        axios.post(`${api}/fingerprints`).then(({data}) => {
            message.success(data)
        }).catch(({response: {data}}) => {
            message.error(data || "error")
        })
    }

    const openLockDoor = () => {
        axios.put(`${api}/fingerprints/check`).then(({data}) => {
            message.success(data)
        }).catch(({response: {data}}) => {
            message.error(data || "error")
        })
    }

    return (
        <StyledDesktopMenu isHidden={menuCollapsed}>
            <Logo>{menuCollapsed ? <LittleLogoMenuDeskSvg/> : <img src={logo} alt="logo"/>}</Logo>
            <Buttons>
                <MenuItem to={"/dashboard"}><DashboardDeskSvg/> <Text>Dashboard</Text></MenuItem>
                <MenuItem to={"/users"}><UserDeskSvg/> <Text>Users</Text></MenuItem>
                <MenuItem to={"/groups"}><StyledGroupSvg/> <Text>Groups</Text></MenuItem>
                <MenuItem to={"/settings"}><OptionsDeskSvg/> <Text>Settings</Text></MenuItem>
            </Buttons>
            <Footer>
                {userData.Role === 90 && <AdminPart>
                    <FooterItem onClick={upgrade}>
                        <UpgradeSvg/>
                        <Text>Upgrade</Text>
                    </FooterItem>
                    <FooterItem onClick={addFingerprint}>
                        <FingerprintScanSvg/>
                        <Text>Add fingerprint</Text>
                    </FooterItem>
                    <FooterItem onClick={openLockDoor}><FingerprintKeySvg/>
                        <Text>Open lockdoor</Text></FooterItem>
                </AdminPart>}
                <FooterItem onClick={() => store.dispatch(switchMenuCollapsableAction(!menuCollapsed))}><HideMenuSvg
                    className={menuCollapsed ? "menuHidden" : ""}/>
                    <Text>Hide the
                        menu</Text></FooterItem>
                {/*<FooterItem className={"disabled"}><AdminDeskSvg/>*/}
                {/*    <Text>{userData.Name || userData.Login}</Text></FooterItem>*/}
                <FooterItemLink
                    href={encryption ? `${detectIOS() ? localCrypterIp_IOS : localCrypterIp}/logout` : `${api}/exit`}><LogoutSvg
                    style={{fill: "white"}}/>
                    <Text>Log out</Text></FooterItemLink>
            </Footer>
        </StyledDesktopMenu>
    );
}

const mapStateToProps = (state: any) => {
    return {
        encryption: state.pageReducer.encryption,
        menuCollapsed: state.pageReducer.menuCollapsed,
        userData: state.loginReducer.userData
    }
}

export default connect(mapStateToProps, null)(DesktopMenu);