import React from 'react';
import styled from "styled-components";
import {Form, Input, message, Upload} from "antd";
import {connect} from "react-redux";
import axios from "axios";
import {api} from "../config";
import store from "../redux/store";
import {refreshTokenAction} from "../redux/actions/loginActions";
import noava from "../styles/images/noava.png"
import Button from "../components/Button";

const StyledForm = styled(Form)`
  input, .ant-input-password {
    line-height: 20px;
    background: none;
    color: ${({theme}) => theme.login.textColor};
    padding: 0 0 5px;
    font-size: 17px;
    border: none;
    border-bottom: 1px solid ${({theme}) => theme.login.borderColor};
    border-radius: 0;
    box-shadow: none !important;
  }


  .ant-form-item-children-icon {
    top: 44% !important;
    right: -5px !important;
  }

  .ant-input-disabled {
    background: none;
  }

  .ant-col {
    padding-bottom: 0;
  }

  label {
    font-size: 14px;
    line-height: 16px;
  }

  label {
    color: ${({theme}) => theme.login.labelColor};
  }


  .ant-btn {
    border: none;
    padding: 15px 50px;
    width: 100%;
    height: auto;
    line-height: 18px;
    font-weight: bold;
    box-shadow: -4px 4px 14px rgba(0, 0, 0, 0.08);
    border-radius: 7px;
    background: ${({theme}) => theme.login.buttonBackground};
    color: ${({theme}) => theme.login.buttonColor};
    font-size: 15px;
  }

`;

const StyledMyProfile = styled.div`
  padding-right: 50px;
  padding-left: 27px;
  overflow-y: auto;
  overflow-x: hidden;

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 35px;

    .avatar {
      img {
        height: 96px;
        width: 96px;
        object-fit: cover;
        border-radius: 100%;
      }

      margin-right: 22px;
    }

    .content {
      .name {
        font-size: 20px;
        line-height: 23px;
        letter-spacing: -0.3px;
        color: ${({theme}) => theme.layout.textColor};

      }

      .status {
        font-size: 17px;
        line-height: 20px;
        letter-spacing: -0.3px;
        color: ${({theme}) => theme.layout.nonActiveColor};
      }
    }
  }
`;

function MyProfile({userData}: any) {

    const editProfile = (fields: any) => {
        axios.put(`${api}/user`, fields).then(() => {
            message.success("Profile updated successfully");
            store.dispatch(refreshTokenAction());
        }).catch(({response: {data}}: any) => {
            message.error(data);
        })
    }

    return (
        <StyledMyProfile>
            <div className="header">
                <Upload action={`${api}/user/photo`} method={"PUT"} customRequest={(_)=>{
                    console.log(_);
                    axios.put(`${api}/user/photo`, _.file).then(()=>{
                        message.success("Photo uploaded successfully");
                        store.dispatch(refreshTokenAction());
                    }).catch(({response: {data}})=>{
                        message.error(data);
                    })
                }
                } showUploadList={false}>
                    <div className="avatar">
                        <img
                            src={userData.Photo ? `/${userData.Photo}` : noava}
                            alt=""/>
                    </div>
                </Upload>
                <div className="content">
                    <div className="name">{userData.Name || userData.Login}</div>
                    <div className="status">Online</div>
                </div>
            </div>
            <StyledForm hideRequiredMark initialValues={userData} onFinish={editProfile} layout="vertical">
                <Form.Item name={"Login"} label={"Defender ID:"}>
                    <Input disabled/>
                </Form.Item>
                <Form.Item name={"Email"} label={"Email:"}>
                    <Input/>
                </Form.Item>
                <Form.Item name={"Name"} label={"Name:"}>
                    <Input/>
                </Form.Item>
                <Form.Item name={"Phone"} label={"Phone:"}>
                    <Input/>
                </Form.Item>
                {/*<Form.Item name={"Password"} label={"Password:"}>*/}
                {/*    <Input.Password/>*/}
                {/*</Form.Item>*/}
                {/*<Form.Item dependencies={['Password']} rules={[*/}
                {/*    ({getFieldValue}) => ({*/}
                {/*        required: getFieldValue('Password') !== "***",*/}
                {/*        message: 'Please confirm your password!'*/}
                {/*    })*/}
                {/*    ,*/}
                {/*    ({getFieldValue}) => ({*/}
                {/*        validator(_, value) {*/}
                {/*            if (!value || getFieldValue('Password') === value) {*/}
                {/*                return Promise.resolve();*/}
                {/*            }*/}
                {/*            return Promise.reject(new Error('The two passwords that you entered do not match!'));*/}
                {/*        },*/}
                {/*    })*/}
                {/*]} hasFeedback name={"Password_"} label={" ConfirmPassword:"}>*/}
                {/*    <Input.Password/>*/}
                {/*</Form.Item>*/}
                <Form.Item>
                    <Button className={"blue"} style={{width: "100%"}} type={"submit"}>Save</Button>
                </Form.Item>
            </StyledForm>
        </StyledMyProfile>
    );
}

const mapStateProps = (state: any) => {
    return {
        userData: state.loginReducer.userData
    }
}

export default connect(mapStateProps, null)(MyProfile);