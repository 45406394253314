import React from 'react';
import styled from "styled-components";
import loginBackground from "../styles/images/loginBackground.svg";
import logo from "../styles/images/logo.svg";
import {Button, Form, Input} from "antd";
import store from "../redux/store";
import {loginAction} from "../redux/actions/loginActions";
import BigLockerLogin from "../styles/images/BigLockerLogin";
import PhoneLoginImage from "../styles/images/PhoneLogin.png";

const ContentForm = styled(Form)`
  margin: 15px 50px;

  h1 {
    display: none;
  }

  input, .ant-input-password {
    line-height: 20px;
    color: ${({theme}) => theme.login.textColor};
    padding: 0 0 5px;
    font-size: 17px;
    border: none;
    border-bottom: 1px solid ${({theme}) => theme.login.borderColor};
    border-radius: 0;
    box-shadow: none !important;
  }

  .forgot {
    align-self: flex-end;
    font-size: 13px;
    line-height: 15px;
  }

  label {
    font-size: 14px;
    line-height: 16px;
  }

  label, .forgot a {
    color: ${({theme}) => theme.login.labelColor};
  }

  .ant-form-item:last-child {
    align-self: center;
  }

  .ant-btn {
    display: flex;
    border: none;
    margin: 40px auto 0 auto;
    padding: 15px 50px;
    height: auto;
    line-height: 18px;
    font-weight: bold;
    box-shadow: -4px 4px 14px rgba(0, 0, 0, 0.08);
    border-radius: 7px;
    background: ${({theme}) => theme.login.buttonBackground};
    color: ${({theme}) => theme.login.buttonColor};
    font-size: 15px;
  }
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;

  @media (max-width: 320px) {
    height: 280px;
    top: -70px;
  }

  .logo {
    width: 100%;
    height: 100%;
    //position: absolute;
    display: flex;
    flex-direction: column;
    position: absolute;
    align-items: center;
    margin-top: 90px;

    img {
      height: 50px;
    }

    .logo_text {
      color: white;
      font-size: 17px;
    }
  }
`;

const Lines = styled.div`
  position: absolute;
  height: 100%;
  width: 60%;
  top: 0;

  svg {
    position: absolute;

    &:last-child {
      top: 30%;
      right: 30%;
    }
  }

`;
const DesktopBackGround = styled.div`
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  align-items: center;
  top: 0;

  img {
    position: absolute;
    right: 0;
    height: 35vw;
  }
`;

const StyledLogin = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  align-items: center;

  @media (min-width: 1100px) {
    justify-content: center;


    ${ContentForm} {
      max-width: 370px;
      align-self: flex-end;
      position: relative;
      right: 30%;

      h1 {
        display: inline;
        color: #4F4F4F;
        font-weight: bold;
        font-size: 32px;
        margin-bottom: 56px;
      }

      .ant-btn {
        width: 100%;
        justify-content: center;
      }
    }

    ${DesktopBackGround} {
      display: flex;
    }

    ${Logo} {
      display: none;
    }
  }
`;

function Login() {
    return (
        <StyledLogin>
            <DesktopBackGround>
                <Lines>
                    <svg height="100%" width="100%" viewBox="0 0 1141 1080" fill="none" preserveAspectRatio="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M1141 -0.5L147 -6.5L170.5 1080.5H325.5L1141 -0.5Z" fill="#e8f0ef"/>
                        <path d="M0 -1.5H974L325 1080.36H0V-1.5Z" fill="#057477"/>
                    </svg>
                    <BigLockerLogin/>
                </Lines>
                <img src={PhoneLoginImage} alt={"phoneLogin"}/>
            </DesktopBackGround>
            <Logo>
                <div className="logo">
                    <img src={logo} alt=""/>
                    <div className="logo_text">
                        EPOS VPN
                    </div>
                </div>
                <img src={loginBackground} alt=""/>
            </Logo>
            <ContentForm onFinish={(fields: any) => store.dispatch(loginAction(fields))} layout={"vertical"}>
                <h1>Login</h1>
                <Form.Item name={"login"} label={"Username:"}>
                    <Input/>
                </Form.Item>
                <Form.Item name={"password"} label={"Password:"}>
                    <Input.Password/>
                </Form.Item>
                <Form.Item>
                    <Button htmlType={"submit"}>Login</Button>
                </Form.Item>
            </ContentForm>
        </StyledLogin>
    );
}

export default Login;