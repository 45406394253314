import {
    CHANGE_CHATS,
    CHANGE_CONTACTS,
    CHANGE_DIALOGS,
    CHANGE_ENCRYPTION,
    CHANGE_I_CREATOR,
    CHANGE_LOCK,
    CHANGE_OPTIONS,
    CHANGE_STREAMS,
    MENU_COLLAPSED
} from "../actionTypes";
import axios from "axios";
import {api, localCrypterIp, localCrypterIp_IOS} from "../../config";
import {message} from "antd";
import store from "../store";
import {detectIOS} from "../../utils/detectIOS";
import {createRef} from "react";

export function getChats() {
    return (dispatch) => {
        return new Promise((res, rej) => {
            axios(`${api}/chats`).then(({data}) => {
                dispatch({type: CHANGE_CHATS, payload: data})
                res();
            }).catch(({response: {data}}) => {
                message.error(data);
                rej();
            })

        })
    }
}

export function getContacts() {
    return (dispatch) => {
        axios(`${api}/contacts`).then(({data}) => {
            dispatch({type: CHANGE_CONTACTS, payload: data})
        }).catch(({response: {data}}) => {
            message.error(data || "error");
        })
    }
}

export function switchMenuCollapsableAction(value) {
    return (dispatch) => {
        dispatch({type: MENU_COLLAPSED, payload: value})
    }
}

export function getLock() {
    return (dispatch) => {
        axios(`${detectIOS() ? localCrypterIp_IOS : localCrypterIp}/status`).then(({data}) => {
            console.log(store.getState().pageReducer.lockInfo);
            dispatch({type: CHANGE_LOCK, payload: data})
        }).catch(({response}) => {
            setTimeout(() => {
                axios(`${detectIOS() ? localCrypterIp_IOS : localCrypterIp}/status`).then(({data}) => {
                    console.log(store.getState().pageReducer.lockInfo);
                    dispatch({type: CHANGE_LOCK, payload: data})
                }).catch(() => {
                    message.error(response?.data || "Couldn't find encryptor");
                    localStorage.setItem("defender_encryption", false);
                    dispatch({type: CHANGE_ENCRYPTION, payload: false})
                })
            }, 1000)
        })
    }
}

export function changeEncryption(encryption) {
    return (dispatch) => {
        dispatch({type: CHANGE_ENCRYPTION, payload: encryption})
    }
}

export function changeStreams(streams) {
    return (dispatch) => {
        dispatch({
            type: CHANGE_STREAMS, payload: [streams, streams.map((stream) => {
                return {ref: createRef(), stream: stream.stream, userId: stream.id}
            })]
        })
    }
}

export function changeICreator(value) {
    return (dispatch) => {
        dispatch({type: CHANGE_I_CREATOR, payload: value})
    }
}

export function changeHideOptions(value) {
    return (dispatch) => {
        dispatch({type: CHANGE_OPTIONS, payload: value})
    }
}

export function setDialogsAction(dialogs) {
    return (dispatch) => {
        store.dispatch(getChats());
        dispatch({type: CHANGE_DIALOGS, payload: dialogs})
    }
}