import React from 'react';

function DisabledCaptureNav(props: any) {
    return (
        <svg {...props} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             width="24px"
             height="16px" viewBox="0 0 24 16" xmlSpace="preserve">
            <path fill="#FFFFFF" d="M18.7,6V1.3c0-0.7-0.6-1.3-1.3-1.3h-16C0.6,0,0,0.6,0,1.3v13.3C0,15.4,0.6,16,1.3,16h16
		c0.7,0,1.3-0.6,1.3-1.3V10l3.1,3.1c0.8,0.8,2.3,0.2,2.3-0.9V3.9c0-1.2-1.4-1.8-2.3-0.9L18.7,6z"/>
            <path fill="#F47171" d="M0.8,14.9L0.8,14.9c0.5,0.6,1.3,0.7,1.9,0.2L17.5,3c0.6-0.5,0.7-1.3,0.2-1.9l0,0c-0.5-0.6-1.3-0.7-1.9-0.2
		L1,13C0.5,13.5,0.4,14.3,0.8,14.9z"/>
        </svg>
    );
}

export default DisabledCaptureNav;