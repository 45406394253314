import React, {useEffect, useState} from 'react';
import styled, {css} from "styled-components";
import {Dropdown, Form, message, Modal} from "antd";
import RoundRoundedPlusSvg from "../styles/images/RoundRoundedPlusSvg";
import MoreSvg from "../styles/images/MoreSvg";
import ExitSvg from "../styles/images/ExitSvg";
import {Link} from "react-router-dom";
import BackButton from "../styles/images/BackButton";
import Capture from "../styles/images/Capture";
import Phone from "../styles/images/phone";
import StyledInput from '../styled/StyledInput';
import StyledForm from '../styled/StyledForm';
import CheckFillSvg from "../styles/images/CheckFillSvg";
import axios from "axios";
import {api} from "../config";
import {connect} from "react-redux";
import store from "../redux/store";
import {changeICreator, getChats} from "../redux/actions/pageActions";
import noava from "../styles/images/noava.png";
import AddUserModal from "../components/AddUserModal";
import history from "../utils/history";
import Button from "../components/Button";
import {callToUsers} from "../utils/webrtc";
import defender_template from "../styles/images/DefenderTemplate.svg";

const RemoveButton = styled(Button)`
  align-items: center;
  background: #ff4d4f;
  color: white;
  border-radius: 7px;
  font-size: 18px;
  padding: 5px 10px;
`;

const StyledDialogSettings = styled.div(({theme: {layout}}) => css`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  background: ${layout.background};
  padding: 0 16px 20px 16px;

  .ant-modal-content {
    background: none;
    box-shadow: none;
  }
`);
const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  flex: 0 1 20%;
  justify-content: flex-end;

  svg {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
`;

const Header = styled.div(({theme: {dialogSettings}}) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  color: ${dialogSettings.headerColor};
  font-size: 16px;
  padding: 20px 0;

  a {
    flex: 1 1 20%;
  }

  svg {
    height: 20px;
    width: 20px;
  }
`);
const StyledCheckFillSvg = styled(CheckFillSvg)`
  margin-left: 20px;
`;

const ChatName = styled.div`
  flex: 1 0 60%;
  display: flex;
  justify-content: center;
`;

const Name = styled.div`
  display: flex;
  align-items: center;

  .ant-form {
    width: 100%;
  }
`;
const Users = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;
const User = styled.div`
  display: flex;
  align-items: center;
`;
const UsersList = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  svg {
    height: 100%;
  }

  &::-webkit-scrollbar {
    width: 0;
  }

  ${User} {
    &:not(:last-child) {
      margin-bottom: 18px;
    }
  }
`;

const UsersHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
`;
const UsersInfo = styled.div(({theme: {dialogSettings}}) => css`
  display: flex;
  font-weight: 500;
  color: ${dialogSettings.headerColor};
  font-size: 16px;
  align-items: center;
`);


const Count = styled.div`
  margin-left: 10px;
  font-weight: bold;
  font-size: 16px;
  color: #bdbdbd;
`;
const AddUser = styled.div(({theme: {dialogSettings}}) => css`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${dialogSettings.headerColor};

  svg {
    margin-right: 10px;
  }
`);
const Avatar = styled.img`
  width: 52px;
  height: 52px;
  margin-right: 14px;
  object-fit: cover;
  border-radius: 100%;
  overflow: hidden;
`;
const Info = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: auto;
`;
const UserName = styled.div(({theme: {dialogSettings}}) => css`
  font-size: 16px;
  color: ${dialogSettings.userColor};
  margin-bottom: 6px;
  line-height: 19px;

  &.red {
    color: #ff5252;
  }
`);

const Status = styled.div(({theme: {dialogSettings}}) => css`
  color: ${dialogSettings.statusColor};
  font-size: 14px;
  line-height: 16px;
`);
const Actions = styled.div`
  display: flex;
  margin-top: 20px;
`;

const Action = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;

  svg {
    margin-right: 9px;
  }

  &.exit {
    color: #f82020;
  }
`;

const GroupAvatar = styled.img`
  width: 42px;
  height: 42px;
  margin-right: 14px;
  object-fit: cover;
  border-radius: 100%;
  overflow: hidden;
`;

function DialogSettings({chats, contacts, match, userData}: any) {
    const chatId = parseInt(match.params.chatId);
    const chat = chats?.filter((x: any) => x.Id === chatId)[0]

    const [name, setName] = useState<string>('');
    const [modalVisible, setModalVisible] = useState(false);

    useEffect(() => {
        if (chat !== undefined)
            setName(chat.Name || `Chat ${chat?.Id}`)
        // eslint-disable-next-line
    }, [chats])

    const addUser = (userId: number) => {
        axios.put(`${api}/chats/${chatId}/user/${userId}`).then(() => {
            store.dispatch(getChats())
        }).catch(({response: {data}}) => {
            message.error(data || "error");
        })
    }

    const removeUserFromChat = (userId: number) => {
        axios.delete(`${api}/chats/${chatId}/user/${userId}`).then(() => {
            store.dispatch(getChats());
        }).catch(({response: {data}}) => {
            message.error(data || "error")
        })
    }

    const changeChatSettings = () => {
        axios.post(`${api}/chats/${chatId}`, {
            Name: name
        }).then(() => {
            store.dispatch(getChats())
            message.success("Successful!")
        }).catch(({response: {data}}) => {
            message.error(data || "error");
        })
    }

    const handleLeaveChat = () => {
        axios.delete(`${api}/chats/${chatId}/user/me`).then(() => {
            history.push("/")
        }).catch(({response: {data}}) => {
            message.error(data || "error")
        })
    }

    return (
        <StyledDialogSettings>
            <Modal style={{background: "none"}}
                   getContainer={() => document.querySelector("." + StyledDialogSettings.styledComponentId)!}
                   forceRender
                   visible={modalVisible} closable={false} footer={null}>

                <AddUserModal
                    onAdd={(selectedUser) => {
                        console.log(selectedUser);
                        addUser(selectedUser);
                        setModalVisible(false);
                    }}
                    onCancel={() => setModalVisible(false)}
                    users={contacts.filter((contact: any) => !chat?.User?.some((user: any) => user.Id === contact.Id))}/>
            </Modal>

            <Header>
                <Link to={`/dialog/${chatId}`}><BackButton/></Link>
                <ChatName>Group chat</ChatName>
                <HeaderActions>
                    <Capture onClick={() => {
                        /*Устанавливаем что мы являемся создателем конференции*/
                        store.dispatch(changeICreator(true));
                        callToUsers(chat.Id, chat.User.filter((el: any) => el.Id !== userData.Id), "video")
                    }}/>
                    <Phone onClick={() => {
                        /*Устанавливаем что мы являемся создателем конференции*/
                        store.dispatch(changeICreator(true));
                        callToUsers(chat.Id, chat.User.filter((el: any) => el.Id !== userData.Id), "audio")
                    }}/>
                </HeaderActions>
            </Header>
            <Name>
                <GroupAvatar src={defender_template} alt={"avatar"}/>
                <StyledForm layout={"vertical"}>
                    <Form.Item label={"Name"}>
                        <StyledInput onChange={(value) => setName(value.currentTarget.value)} value={name}/>
                    </Form.Item>
                </StyledForm>
                <StyledCheckFillSvg onClick={changeChatSettings}/>
            </Name>
            <Users>
                <UsersHeader>
                    <UsersInfo>Users in chat <Count>{chat?.User?.length}</Count> </UsersInfo>
                    <AddUser onClick={() => setModalVisible(true)}><RoundRoundedPlusSvg/> Add user</AddUser>
                </UsersHeader>
                <UsersList>
                    {chat?.User?.map((user: any) => <User>
                        <Avatar src={user.Photo ? "/" + user.Photo : noava} alt={"avatar"}/>
                        <Info>
                            <UserName
                                className={`name ${user.State === false ? "red" : ""}`}>{user.Name || user.Login}</UserName>
                            <Status>{user.Online ? "Online" : "Offline"}</Status>
                        </Info>
                        <Dropdown
                            trigger={["click", "contextMenu"]}
                            overlay={<RemoveButton onClick={() => removeUserFromChat(user.Id)}>Remove</RemoveButton>}>
                            <MoreSvg/>
                        </Dropdown>
                    </User>)}
                </UsersList>
            </Users>
            <Actions>
                <Action onClick={handleLeaveChat} className={"exit"}><ExitSvg/> Exit</Action>
            </Actions>
        </StyledDialogSettings>
    );
}

const mapStateToProps = (state: any) => {
    return {
        userData: state.loginReducer.userData,
        contacts: state.pageReducer.contacts,
        chats: state.pageReducer.chats
    }
}

export default connect(mapStateToProps, null)(DialogSettings);