import React, {useEffect} from 'react';
import styled, {css} from "styled-components";
import {Form, message, Select, Switch} from "antd";
import StyledSelect from "../styled/StyledSelect";
import StyledInput, {StyledNumber} from '../styled/StyledInput';
import StyledForm from "../styled/StyledForm";
import Button from "../components/Button";
import axios from "axios";
import {api} from "../config";
import OptionsDeskSVG from "../styles/images/OptionsDeskSVG";

const {Option} = Select;

const StyledButton = styled(Button)`
  padding: 20px 0;
  font-size: 16px;
  width: 100%;
  font-weight: bold;
`;

const Config = styled.div`
  max-width: 610px;
  background: white;
  border: 1px solid #E8E8E8;
  border-radius: 18px;
  padding: 20px 90px 0px 25px;
`;

const OptionBackground = styled(OptionsDeskSVG)(({theme: {svg}}) => css`
  position: absolute;
  right: 10%;
  top: 30%;
  width: 30%;
  height: 30%;
  z-index: -1;

  path {
    fill: ${svg.color6};
  }
`);

const StyledSettings = styled.div`
  padding-right: 80px;
`;

function Settings() {

    const [formRef] = Form.useForm();

    const getConfig = () => {
        axios(`${api}/config`).then(({data}) => {
            formRef.setFieldsValue(data);
            console.log(data);
        })
    }
    const setConfig = (fields: any) => {
        axios.put(`${api}/config`, fields).then(({data}) => {
            message.success("Config saved successfully");
            console.log(data);
        })
    }

    useEffect(() => {
        getConfig();
        // eslint-disable-next-line
    }, [])

    return (
        <StyledSettings>
            <Config>
                <StyledForm hideRequiredMark onFinish={setConfig} form={formRef} layout={"vertical"}>
                    <Form.Item name={"WebPort"} label={"Inner web port"} rules={[{
                        required: true,
                        message: "Inner web port is required"
                    }]}>
                        <StyledNumber/>
                    </Form.Item>
                    <Form.Item name={"TypeLog"} label={"Log level"}>
                        <StyledSelect>
                            <Option value={"debug"}>debug</Option>
                            <Option value={"info"}>info</Option>
                            <Option value={"warn"}>warn</Option>
                            <Option value={"error"}>error</Option>
                        </StyledSelect>
                    </Form.Item>
                    <Form.Item name={"MasterPassword"} label={"Master password"}>
                        <StyledInput.Password/>
                    </Form.Item>
                    <Form.Item name={"DomainName"} label={"Domain name"}>
                        <StyledInput/>
                    </Form.Item>
                    <Form.Item name={"TURNIp"} label={"TURN ip address"}>
                        <StyledInput/>
                    </Form.Item>
                    <Form.Item name={"TURNPort"} label={"TURN port"} rules={[{
                        required: true,
                        message: "TURN port is required"
                    }]}>
                        <StyledNumber min={1024} max={65535}/>
                    </Form.Item>
                    <Form.Item name={"Encryptor"} label={"Encryptor type"}>
                        <StyledSelect>
                            <Option value={"embedded"}>embedded</Option>
                            <Option value={"local"}>local</Option>
                            <Option value={"demo"}>demo</Option>
                        </StyledSelect>
                    </Form.Item>
                    <Form.Item valuePropName={"checked"} name={"AdminLocalhostOnly"}
                               label={"Allow admin from localhost only"}>
                        <Switch/>
                    </Form.Item>
                    <Form.Item name={"FCMKey"} label={"Firebase Cloud Messaging key"}>
                        <StyledInput/>
                    </Form.Item>
                    <Form.Item>
                        <div style={{display: "flex"}}>
                            <StyledButton style={{marginRight: 10}} className={"blue"}>Save</StyledButton>
                            <StyledButton className={"blue"} onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                e.preventDefault();
                                window.open(`${api}/log`)
                            }}>Logs</StyledButton>
                        </div>
                    </Form.Item>
                </StyledForm>
            </Config>
            <OptionBackground/>
        </StyledSettings>
    );
}

export default Settings;