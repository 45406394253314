import React, {useEffect, useState} from 'react';
import styled, {css} from "styled-components";
import Visualiser from "../components/Visualiser";
import axios from "axios";
import {api} from "../config";
import {message} from "antd";

const DashboardWrap = styled.div`
  display: flex;
  flex: 1 1 auto;
  position: relative;
  border: 1px solid #E8E8E8;
  background: #FFFFFF;
  border-radius: 24px;
  margin-right: 60px;
  justify-content: center;
  margin-bottom: 80px;
  overflow: hidden;
`;

const Info = styled.div(({theme: {dashboard}}) => css`
  display: flex;
  background: ${dashboard.infoBackground};
  padding: 25px;
  border-radius: 18px;
  position: absolute;
  top: 14px;
  left: 14px;
  z-index: 1;
  pointer-events: none;
`);

const InfoItem = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-right: 40px;
  }
`;

const Label = styled.div(({theme: {dashboard}}) => css`
  color: ${dashboard.labelColor};
  font-weight: 500;
  font-size: 14px;
`);

const Value = styled.div(({theme: {dashboard}}) => css`
  color: ${dashboard.textColor};
  font-weight: bold;
  font-size: 18px;
  display: flex;
  align-items: center;

  &.good, &.bad, &.alert {
    &::before {
      content: '';
      width: 12px;
      height: 12px;
      background: ${dashboard.greenColor};
      display: flex;
      margin-right: 5px;
      border-radius: 100%;
    }
  }

  &.good {
    color: ${dashboard.greenColor};

    &::before {
      background: ${dashboard.greenColor};
    }
  }
  
  &.alert {
    color: ${dashboard.orangeColor};

    &::before {
      background: ${dashboard.orangeColor};
    }
  }

  &.bad {
    color: ${dashboard.redColor};

    &::before {
      background: ${dashboard.redColor};
    }
  }
`);

const StyledDashboard = styled.div`
  display: flex;
  flex: 1 1 auto;
`;

function Dashboard({visualizer}: any) {
    const [serverInfo, setServerInfo] = useState<ServerInfo>();

    const getServerInfo = () => {
        axios(`${api}/dashboard`).then(({data}) => {
            setServerInfo(data)
        }).catch(({response: {data}}) => {
            message.error(data || "error")
        })
    }
    useEffect(() => {
        getServerInfo()
        const interval = setInterval(() => {
            getServerInfo()
        }, 1000)
        return () => {
            clearInterval(interval)
        }
    }, []);


    return (
        <StyledDashboard>
            <DashboardWrap>
                <Info>
                    <InfoItem>
                        <Label>IP Address</Label>
                        <Value>{serverInfo?.IpAddress || "unknown"}</Value>
                    </InfoItem>
                    <InfoItem>
                        <Label>Server health</Label>
                        <Value className={serverInfo?.State === 0 ? (serverInfo ? "good" : "bad") : "alert"}>{serverInfo?.State === 0 ? (serverInfo ? "Good" : "Bad") : "Alert"}</Value>
                    </InfoItem>
                    <InfoItem>
                        <Label>Version</Label>
                        <Value>{serverInfo?.Version || "unknown"}</Value>
                    </InfoItem>
                    <InfoItem>
                        <Label>Used CPU</Label>
                        <Value>{serverInfo?.CPU || 0}%</Value>
                    </InfoItem>
                    <InfoItem>
                        <Label>Used RAM</Label>
                        <Value>{serverInfo?.RAM || 0}%</Value>
                    </InfoItem>
                    <InfoItem>
                        <Label>Users online</Label>
                        <Value>{serverInfo?.UserOnline || 0}</Value>
                    </InfoItem>
                </Info>
                <Visualiser visualizer={visualizer}/>
            </DashboardWrap>
        </StyledDashboard>
    );
}

export default Dashboard;