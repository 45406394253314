import React from 'react';
import styled, {css, ThemeProvider} from "styled-components";
import DefenderModal from "./DefenderModal";
import {theme} from "../theme";

const Button = styled.div`
  background: white;
  padding: 5px 15px;
  border-radius: 7px;
  color: #010B2B;
  cursor: pointer;

  &.red {
    background: #ff5555;
    color: white;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;

  ${Button} {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
`;

const StyledLogoutModal = styled(DefenderModal)(({theme: {logoutModal}}) => css`
  font-size: 16px;
  color: ${logoutModal.color};

  h1 {
    font-size: 16px;
    color: ${logoutModal.color};
  }

  p {
    font-size: 14px;
  }
`);

type LogoutModalProps = {
    onOk: () => void,
    onCancel: () => void
}

function LogoutModal({onOk, onCancel}: LogoutModalProps) {
    return (
        <ThemeProvider theme={theme}>
            <StyledLogoutModal>
                <h1>Logout</h1>
                <p>Do you want delete keys?</p>
                <Actions>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button onClick={onOk} className={"red"}>Logout</Button>
                </Actions>
            </StyledLogoutModal>
        </ThemeProvider>
    );
}

export default LogoutModal;