import React from 'react';
import styled, {css} from "styled-components";

const StyledMicrophone = styled.svg(({theme: {svg}}) => css`
  path {
    fill: ${svg.color1};
  }
`);

function Microphone(props: any) {
    return (
        <StyledMicrophone {...props} width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M10.6048 4.12903V11.5154C10.6048 12.9674 9.42778 14.1444 7.97581 14.1444C6.52383 14.1444 5.34677 12.9674 5.34677 11.5154V4.12903C5.34677 2.67706 6.52383 1.5 7.97581 1.5C9.42778 1.5 10.6048 2.67706 10.6048 4.12903ZM3.84677 4.12903C3.84677 1.84863 5.69541 0 7.97581 0C10.2562 0 12.1048 1.84863 12.1048 4.12903V11.5154C12.1048 13.7958 10.2562 15.6444 7.97581 15.6444C5.69541 15.6444 3.84677 13.7958 3.84677 11.5154V4.12903ZM7.97581 17.8278C4.36048 17.8278 1.5 15.0614 1.5 11.7333H0C0 15.7205 3.20394 18.9356 7.22581 19.2945V22H8.72581V19.2945C12.7477 18.9356 15.9516 15.7205 15.9516 11.7333H14.4516C14.4516 15.0614 11.5911 17.8278 7.97581 17.8278Z"
                  fill="#4F86EC"/>
        </StyledMicrophone>
    );
}

export default Microphone;