import {api} from "../config";
import store from "../redux/store";
import {changeICreator, setDialogsAction} from "../redux/actions/pageActions";
import {addMessage} from "./dialogUtils";
import {answer, call, gotAnswer, gotCandidate, gotOffer, hangup, setDigest, start, stopCallSound} from "./webrtc";
import history from "./history";
import {Modal} from "antd";
import {logoutAction} from "../redux/actions/loginActions";
import ringtone from "../audio/ringtone.wav";
import CallModalContent from "../components/CallModalContent";
import React from "react";
import {StyledApp} from "../App";

let websocket;
let interval;
let visualizerRef;

function ws_connect() {
    websocket = new WebSocket(`${window.location.protocol === "http:" ? "ws://" : "wss://"}${window.location.host}${api}/ws`);

    websocket.onopen = (e) => {
        console.log(e);
    }

    websocket.onmessage = async e => {
        const wsMessage = JSON.parse(e.data);

        let callModal;

        switch (wsMessage.Type) {
            case 1: {
                const raw = wsMessage.Data;
                const newDialogs = await addMessage(raw);
                store.dispatch(setDialogsAction(newDialogs))
                window.navigator.vibrate?.(100);
                break;
            }
            case 2: {
                const raw = wsMessage.Data;
                const newDialogs = await addMessage(raw);
                store.dispatch(setDialogsAction(newDialogs))
                window.navigator.vibrate?.(100);
                break;
            }
            case 9: {
                // if (users.has(wsMessage.Data.UserId)) {
                //это если у нас ещё камера не готова и т.п или мы уже разговариваем с кем-то
                // sendAnswerRoom(wsMessage.Data.Digest, wsMessage.Data.UserId, "cancel")
                // return
                // }

                //пришел запрос на звонок нам

                /*Если мы уже с кем-то общаемся, то сбрасываем звонок*/
                if (history.location.pathname.includes("/video/")) {
                    return sendAnswerRoom(wsMessage.Data.Digest, wsMessage.Data.UserId, "cancel")
                }

                const User = store.getState().pageReducer.contacts.filter(el => el.Id === wsMessage.Data.UserId)[0];

                const ringtoneAudio = new Audio(ringtone);
                ringtoneAudio.loop = true;
                ringtoneAudio.play();

                const callResetTimeout = setTimeout(() => {
                    ringtoneAudio.pause();
                    sendAnswerRoom(wsMessage.Data.Digest, wsMessage.Data.UserId, "cancel");
                    callModal.destroy();
                }, 1000 * 60);

                callModal = new Modal.confirm({
                    content: <CallModalContent
                        user={User}
                        onCancel={() => {

                            clearTimeout(callResetTimeout);

                            ringtoneAudio.pause();
                            sendAnswerRoom(wsMessage.Data.Digest, wsMessage.Data.UserId, "cancel");
                            callModal.destroy();
                        }}
                        onOk={() => {

                            clearTimeout(callResetTimeout);

                            /*Устанавливаем что мы не является создателем конференции*/
                            store.dispatch(changeICreator(false));
                            ringtoneAudio.pause();
                            history.push("/video/" + wsMessage.Data.UserId);
                            start(wsMessage.Data.Type === "audio").then(() => {
                                sendAnswerRoom(wsMessage.Data.Digest, wsMessage.Data.UserId, "ok")
                                setDigest(wsMessage.Data.Digest);
                            });
                            callModal.destroy();
                        }}/>,
                    icon: null,
                    className: "callModal",
                    getContainer: () => document.querySelector(`.${StyledApp.styledComponentId}`),
                    bodyStyle: {padding: "0"},
                    style: {top: "auto", bottom: "calc(-100% + 236px)", paddingBottom: "0"},
                    footer: null,
                    closable: false
                })
                break;
            }
            case 10: {
                //пришел ответ от вызываемого
                if (wsMessage.Data.Answer === "ok") {
                    console.log("Call approved by " + wsMessage.Data.UserId)
                    if (history.location.pathname.includes("/video/"))
                        call(wsMessage.Data.UserId)
                    else
                        sendAnswerRoom(wsMessage.Data.Digest, wsMessage.Data.UserId, "cancel")
                } else if (wsMessage.Data.Answer === "cancel") {
                    console.log("store.getState().pageReducer.streams.length", store.getState().pageReducer.streams.length);
                    if (store.getState().pageReducer.streams.length <= 1) {
                        if (history.location.pathname.includes("/video/")) {
                            history.replace("/");
                            hangup();
                        } else {
                            callModal?.destroy && callModal?.destroy()
                        }
                    }
                    console.log("Call canceled by " + wsMessage.Data.UserId)
                }
                stopCallSound();
                break;
            }
            case 11: {
                if (wsMessage.Data.Offer !== undefined) {
                    let desc = JSON.parse(wsMessage.Data.Offer);
                    answer(wsMessage.Data.UserId);
                    gotOffer(desc, wsMessage.Data.UserId);
                } else if (wsMessage.Data.Answer !== undefined) {
                    let desc = JSON.parse(wsMessage.Data.Answer);
                    gotAnswer(desc, wsMessage.Data.UserId);
                } else if (wsMessage.Data.Candidate !== undefined) {
                    gotCandidate(JSON.parse(wsMessage.Data.Candidate), wsMessage.Data.UserId);
                }
                break;
            }
            case 50 : {

                if (!history.location.pathname.includes("/dashboard"))
                    break;

                switch (wsMessage.Service) {
                    case 1: {
                        console.log(wsMessage.Data.UserId);
                        console.log("visualizerRef", visualizerRef);
                        console.log("visualizerRef?.current", visualizerRef?.current);
                        console.log("visualizerRef?.current?.removeUser", visualizerRef?.current?.removeUser);
                        visualizerRef?.current?.removeUser && visualizerRef.current.removeUser(wsMessage.Data.UserId);
                        visualizerRef?.current?.addNew && visualizerRef.current.addNew(wsMessage.Data);
                        break;
                    }
                    case 2 : {
                        visualizerRef?.current?.removeUser && visualizerRef.current.removeUser(wsMessage.Data.UserId);
                        break;
                    }
                    case 3: {
                        if (!document.hidden) {
                            //Если вкладка не скрыта, рисуем трафик
                            visualizerRef?.current?.spawnDotTo && visualizerRef.current.spawnDotTo(wsMessage.Data, wsMessage.Data.Direction !== 1);
                        }
                        break;
                    }
                    default:
                        break;
                }
                break;
            }
            case 99: {
                document.cookie = "_identid=;expires=" + new Date(0).toUTCString()
                store.dispatch(logoutAction())
                history.push("/restart");
                break;
            }
            default:
                return;
        }
    };

    return websocket;
}

interval = setInterval(() => {
    if (websocket !== undefined && websocket.readyState === websocket.CLOSED) {
        ws_connect();
    } else if (websocket.readyState === websocket.OPEN) {
        websocket.send(JSON.stringify({Type: 0}));
    }
}, 3000)


export function getWS(visualizer) {
    if (visualizerRef === undefined)
        visualizerRef = visualizer
    console.log("visualizer", visualizer);
    if (websocket === undefined || websocket.readyState === websocket.CLOSED) {
        return [ws_connect(), interval];
    }
    return [websocket, interval];
}

export function sendOfferMessage(digest, desc, userId) {
    if (websocket !== undefined) {
        let offerMessage = {Digest: digest, UserId: userId, Offer: JSON.stringify(desc)};
        let wsMessage = {Type: 11, Data: offerMessage};
        websocket.send(JSON.stringify(wsMessage));
    }
}

export function sendAnswerMessage(digest, desc, userId) {
    if (websocket !== undefined) {
        let offerMessage = {Digest: digest, UserId: userId, Answer: JSON.stringify(desc)};
        let wsMessage = {Type: 11, Data: offerMessage};
        websocket.send(JSON.stringify(wsMessage));
    }
}

export function sendCandidateMessage(digest, desc, userId) {
    if (websocket !== undefined) {
        let offerMessage = {Digest: digest, UserId: userId, Candidate: JSON.stringify(desc.candidate)};
        let wsMessage = {Type: 11, Data: offerMessage};
        websocket.send(JSON.stringify(wsMessage));
    }
}

export function sendCreateRoom(digest, userIds, type) {
    if (websocket !== undefined) {
        let wsMessage = {Type: 8, Data: {Digest: digest, Type: type, UserIds: userIds}}
        websocket.send(JSON.stringify(wsMessage));
    }
}

export function sendAddRoom(digest, userIds, currentUsers, type) {
    if (websocket !== undefined) {
        for (let userId of userIds) {
            if (!currentUsers.has(userId)) { //пропустим тех кто у нас есть в Map, иначе мы перезапишем
                let wsMessage = {Type: 9, Data: {Digest: digest, Type: type, UserId: userId}}
                websocket.send(JSON.stringify(wsMessage));
            }
        }
    }
}

export function sendAnswerRoom(digest, userId, answer_) {
    if (websocket !== undefined) {
        let wsMessage = {Type: 10, Data: {Digest: digest, UserId: userId, Answer: answer_}}
        websocket.send(JSON.stringify(wsMessage));
    }
}