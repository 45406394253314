import React from 'react';

function DisabledMicrophoneNav(props: any) {
    return (
        <svg {...props} width="20" height="26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
             x="0px" y="0px"
             viewBox="0 0 20 26" xmlSpace="preserve">
            <path d="M10,16.7c2.2,0,4-1.8,4-4v-8c0-2.2-1.8-4-4-4c-2.2,0-4,1.8-4,4v8C6,14.9,7.8,16.7,10,16.7z M17.9,12.7
		c-0.7,0-1.2,0.5-1.3,1.1c-0.5,3.1-3.3,5.5-6.6,5.5c-3.3,0-6-2.4-6.6-5.5c-0.1-0.7-0.7-1.1-1.3-1.1c-0.8,0-1.5,0.7-1.3,1.5
		c0.7,4,3.9,7.1,7.9,7.7v2.8c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-2.8c4-0.6,7.2-3.7,7.9-7.7
		C19.3,13.4,18.7,12.7,17.9,12.7z" fill={"#FFFFFF"}/>

            <path d="M1.1,20.7L1.1,20.7c0.5,0.5,1.3,0.5,1.9,0L18.9,4.8c0.5-0.5,0.5-1.3,0-1.9l0,0c-0.5-0.5-1.3-0.5-1.9,0
		L1.1,18.8C0.6,19.3,0.6,20.2,1.1,20.7z" fill={"#F47171"}/>
        </svg>
    );
}

export default DisabledMicrophoneNav;