import React from 'react';
import styled, {css} from "styled-components";

const StyledCapture = styled.svg(({theme: {svg}}) => css`
  path {
    fill: ${svg.color1};
  }
`);

function Capture(props:any) {
    return (
        <StyledCapture {...props} width="23" height="14" viewBox="0 0 23 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.8891 0.341797H2.21434C1.50403 0.3428 0.928572 1.03163 0.927734 1.8819V12.0169C0.928572 12.8672 1.50403 13.556 2.21434 13.557H12.8891C13.5993 13.556 14.1749 12.8672 14.1758 12.0169V1.8819C14.1749 1.03163 13.5993 0.3428 12.8891 0.341797Z" fill="#4F86EC"/>
            <path d="M15.2793 9.26241L22.0732 12.4558V1.44312L15.2793 4.63648V9.26241Z" fill="#4F86EC"/>
        </StyledCapture>


    );
}

export default Capture;