import React, {useEffect, useState} from 'react';
import styled, {css} from "styled-components";
import axios from "axios";
import {api} from "../config";
import {Dropdown, Form, Input, Menu, message, notification, Select, Spin} from "antd";
import noava from "../styles/images/noava.png";
import TrashSvg from "../styles/images/TrashSvg";
import EditPenSvg from "../styles/images/EditPenSvg";
import Button, {StyledButton} from '../components/Button';
import ZoomSvg from "../styles/images/ZoomSvg";
import KeySvg from "../styles/images/KeySvg";
import MoreSvg from "../styles/images/MoreSvg";
import {StyledModal} from "../styled/StyledModal";
import UserBackgroundSvg from "../styles/images/UserBackgroundSvg";
import CheckedOnSvg from "../styles/images/CheckedOnSvg";
import CheckedOffSvg from "../styles/images/CheckedOffSvg";
import GroupSvg from "../styles/images/GroupSvg";
import ModalAddButtonSvg from "../styles/images/ModalAddButtonSVG";
import ModalRemoveButtonSvg from "../styles/images/ModalRemoveButtonSVG";
import StyledInput from "../styled/StyledInput";
import InputKeySvg from "../styles/images/InputKeySvg";
import StyledSelect from "../styled/StyledSelect";
import InputLoginSvg from "../styles/images/InputFirstnameSvg";
import InputMailSvg from "../styles/images/InputMailSvg";
import InputPhoneSvg from "../styles/images/InputPhoneSvg";
import InputNameSvg from "../styles/images/InputNameSvg";
import StyledForm from '../styled/StyledForm';
import UsbPortSvg from "../styles/images/UsbPortSvg";
import * as uuid from "uuid";
import DownloadSvg from "../styles/images/DownloadSVG";

const {Option} = Select;

const StyledStyledForm = styled(StyledForm)`
  display: flex;
  justify-content: space-around;

  > div:first-child {
    margin-right: 20px;
  }

  div[role="alert"] {
    position: absolute;
  }
`;

const StyledGroupSvg = styled(GroupSvg)`
  fill: rgb(20, 17, 36);
`;
const SearchInput = styled(Input)`
  background: #EEF0F2;
  border: none;
  border-radius: 10px;
  outline: none;
  box-shadow: none !important;
  margin-right: 28px;
  width: 100%;

  .ant-input {
    font-size: 14px;
    background: #EEF0F2;
  }
`;

const Header = styled.div`
  display: flex;
  max-width: 610px;
  margin-bottom: 24px;

  ${StyledButton} {
    width: 100%;
  }
`;

const UsersWrap = styled.div`
  position: relative;
  display: flex;
  overflow: hidden;
  max-width: 610px;
  border-radius: 18px;
  border: 1px solid #E8E8E8;
  background: #FFFFFF;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-right: 5px solid black;
    border-radius: 23px;
  }
`;

const UsersList = styled.div`
  width: 100%;
  border-radius: 18px;
  overflow-y: auto;
  z-index: 1;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #C4C4C4;
    border-radius: 23px;
  }
`;

const UserItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  padding: 10px 0 10px 10px;
  overflow: hidden;
  width: 100%;
  padding-right: 32px;


  &:hover {
    background: #f7f7f7;
  }

  .keyGenerate {
    display: flex;
    align-items: center;
    margin-right: 10px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .state {
    display: flex;
    align-items: center;

    svg {
      height: 30px;
      width: 30px;
    }
  }

  .download {
    display: flex;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;

    a {
      display: flex;
    }

    .ant-spin {
      top: 2px;
      position: relative;

      .ant-spin-dot-item {
        background-color: ${({theme}) => theme.layout.mainColor};
      }
    }

    svg {
      height: 30px;
      width: 30px;

      * {
        stroke: ${({theme}) => theme.layout.mainColor};
      }
    }
  }

  .action {
    height: 20px;
    flex: 0 0 auto;

    circle {
      fill: #b0b0b0;
    }
  }

  .name {
    margin-right: auto;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #010b2b;
    font-weight: bold;
    font-size: 18px;
  }

  img {
    flex: 0 0 auto;
    height: 40px;
    width: 40px;
    object-fit: cover;
    border-radius: 100%;
    margin-right: 10px;
  }
`;


const StyledUserBackgroundSvg = styled(UserBackgroundSvg)(({theme: {svg}}) => css`
  position: absolute;
  right: 20%;
  top: 20%;

  path, circle {
    stroke: ${svg.color6};
  }
`)

const UserList = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({theme}) => theme.layout.textColor};
  flex: 1 1 auto;
  z-index: 1;

  ${UserItem}:not(:last-child) {
    border-bottom: 1px solid ${({theme}) => theme.layout.borderColor};
  }
`;


const GroupItem = styled.div(({theme: {svg}}) => css`
  display: flex;
  align-items: center;
  font-size: 24px;
  justify-content: space-between;
  margin-top: 20px;

  svg {
    cursor: pointer;

    rect {
      fill: ${svg.color4};
    }
  }
`);

const UpdateStatus = styled.div`

`;

const StyledUsers = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 23px;
  display: flex;
  position: relative;
  margin-bottom: 100px;
  flex: 1 1 auto;

  .add_btn {
    background: ${({theme}) => theme.layout.mainColor};
    color: white;
  }

  @media screen and (min-width: 600px) {
    .add_btn {
      height: 60px;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: bold;
    }

    .ant-dropdown-menu-item {
      font-size: 18px;

      svg {
        height: 30px;
        width: 30px;
      }
    }

    ${UserList} {
      ${UserItem} {
        height: 100px;

        .name {
          font-size: 18px;
        }

        img {
          height: 68px;
          width: 68px;
        }

        .keyGenerate {
          cursor: pointer;
          margin-right: 20px;

          svg {
            width: 40px;
            height: 40px;
          }
        }

        .state {
          cursor: pointer;
          margin-right: 20px;

          svg {
            width: 50px;
            height: 50px;
          }
        }

        .action {
          cursor: pointer;
          width: 30px;
          height: 30px;
        }
      }
    }
  }

`;

const Users = () => {
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [groups, setGroups] = useState([]);
    const [userGroups, setUserGroups] = useState<any>([]);
    const [createModalVisible, setCreateModalVisible] = useState(false);
    const [genModal, setGenModal] = useState(false);
    const [addToGroup, setAddToGroup] = useState(false);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [editableUser, setEditableUser] = useState<any>({});
    const [keyUserId, setKeyUserId] = useState<any>(0);
    const [keyLength, setKeyLength] = useState<any>(1024);
    const [port, setPort] = useState<any>(1);
    const [formRef] = Form.useForm();
    const [formEditRef] = Form.useForm();

    const userMenu = (user: any) => (
        <Menu>
            <Menu.Item onClick={() => {
                setEditableUser(user);
                formEditRef.setFieldsValue(user);
                setEditModalVisible(true)
            }} icon={<EditPenSvg/>} key="1">Edit</Menu.Item>
            <Menu.Item onClick={() => {
                setKeyUserId(user.Id);
                getUserGroups(user.Id);
            }} icon={<StyledGroupSvg/>} key="2">Groups</Menu.Item>
            <Menu.Item onClick={() => deleteUser(user.Id)} icon={<TrashSvg/>} danger key="3">Delete</Menu.Item>
        </Menu>
    );

    const getUserGroups = (Id: number) => {
        axios(`${api}/users/${Id}/groups`).then(({data}) => {
            setUserGroups(data);
            setAddToGroup(true);
        })
    }

    const genKey = (userId: number, portValue: number) => {
        const user: any = users.filter((user_: any) => user_.Id === userId)[0];
        const key = uuid.v4();
        const messageText = "Key generation for"
        setGenModal(false);

        notification.open({
            message: `${messageText} ${user.Name || user.Login}`,
            description: <UpdateStatus>
                <Spin/>
            </UpdateStatus>,
            duration: 0,
            key: key
        })

        axios.put(`${api}/users/${userId}/generator/${keyLength}/${portValue}`).then(() => {
            notification.open({
                message: `${messageText} ${user.Name || user.Login} on port ${portValue}`,
                description: <UpdateStatus>
                    Key was generated successfully
                </UpdateStatus>,
                duration: 0,
                key: key
            })
        }).catch(({response: {data}}) => {
            notification.open({
                message: `${messageText} ${user.Name || user.Login} on port ${portValue}`,
                description: <UpdateStatus>
                    Error: {data}
                </UpdateStatus>,
                duration: 0,
                key: key
            })
        })


        setPort(1);
        setKeyLength(1024)
    }
    const switchState = (userId: number) => {
        axios.put(`${api}/users/${userId}/state`).then(() => {
            getUsers();
        }).catch(({response: {data}}) => {
            message.error(data)
        })
    }

    const getGroups = () => {
        axios(`${api}/groups`).then(({data}) => {
            setGroups(data);
        }).catch(({response: {data}}) => {
            message.error(data);
        })
    }

    const deleteUser = (userId: number) => {
        axios.delete(`${api}/users/${userId}`).then(() => {
            message.warning("User successfully deleted");
            getUsers();
        }).catch(({response: {data}}) => {
            message.error(data)
        })
    }

    const addUser = (fields: any) => {
        axios.post(`${api}/users`, fields).then(() => {
            message.success("User successfully added");
            formRef.resetFields();
            setCreateModalVisible(false);
            getUsers();
        }).catch(({response: {data}}) => {
            message.error(data)
        })
    }

    const editUser = (fields: any, userId: number) => {
        axios.put(`${api}/users/${userId}`, fields).then(() => {
            message.success("User was edited successfully");
            setEditModalVisible(false);
            getUsers();
        }).catch(({response: {data}}) => {
            message.error(data)
        })
    }

    const getUsers = () => {
        axios(`${api}/users`).then(({data}) => {
            setUsers(data);
        }).catch(({response: {data}}) => {
            message.error(data);
        })
    }

    const addUserToGroup = (userId: number, groupId: number) => {
        axios.put(`${api}/users/${userId}/groups/${groupId}`).then(() => {
            getUsers();
            getUserGroups(userId);
        })
    }

    const removeUserFromGroup = (userId: number, groupId: number) => {
        axios.delete(`${api}/users/${userId}/groups/${groupId}`).then(() => {
            getUsers();
            getUserGroups(userId);
        })
    }

    const search = ({target: {value: text}}: any) => {
        setFilteredUsers(users.filter((user: any) => user.Name.indexOf(text) !== -1 || user.Login.indexOf(text) !== -1))
    }


    const getDownloadStatus = (userId:number) => {
        axios(`${api}/users/${userId}/generator/download/available`).then(({data}) => {
            window.open(`${api}/users/${userId}/generator/download`)
        }).catch(({response}) => {
            message.error(response?.data || "Error")
        })
    }


    useEffect(() => {
        getGroups();
        getUsers();
    }, [])

    useEffect(() => {
        setFilteredUsers(users);
    }, [users])


    return (
        <StyledUsers>
            <UserList>
                <StyledModal onCancel={() => {
                    setGenModal(false)
                }}
                             okText={"Generate"}
                             onOk={() => genKey(keyUserId, port)}
                             visible={genModal}
                             title={"Type length key in MB"}>
                    <StyledStyledForm layout={"vertical"}>
                        <Form.Item label={"Key"}>
                            <StyledInput suffix={<InputKeySvg/>} value={keyLength}
                                         onChange={({target: {value}}) => setKeyLength(value)}/>
                        </Form.Item>
                        <Form.Item label={"Port"}>
                            <StyledInput suffix={<UsbPortSvg/>} value={port}
                                         onChange={({target: {value}}) => setPort(value)}/>
                        </Form.Item>
                    </StyledStyledForm> </StyledModal>
                <StyledModal
                    onCancel={() => {
                        setAddToGroup(false)
                    }}
                    okText="Ok"
                    onOk={() => setAddToGroup(false)}
                    visible={addToGroup}
                    title={"Group control"}>
                    {groups.map((group: any, index: number) => (
                        <GroupItem key={index}>{group.Name} {!userGroups.includes(group.Id) ?
                            <ModalAddButtonSvg onClick={() => addUserToGroup(keyUserId, group.Id)}/> :
                            <ModalRemoveButtonSvg
                                onClick={() => removeUserFromGroup(keyUserId, group.Id)}/>}</GroupItem>
                    ))}
                </StyledModal>
                <StyledModal onCancel={() => {
                    setCreateModalVisible(false)
                    formRef.resetFields();
                }}
                             okText="Create"
                             onOk={() => formRef.submit()}
                             visible={createModalVisible}
                             title={"Create new user"}>
                    <StyledStyledForm hideRequiredMark layout={"vertical"} onFinish={addUser} form={formRef}
                                      initialValues={{
                                          Role: 30
                                      }}>
                        <div>
                            <Form.Item label={"Login"} name={"Login"} rules={[{
                                required: true,
                                message: "Login is required"
                            }]}>
                                <StyledInput suffix={<InputLoginSvg/>}/>
                            </Form.Item>
                            <Form.Item label={"Name"} name={"Name"}>
                                <StyledInput suffix={<InputNameSvg/>}/>
                            </Form.Item>
                            <Form.Item label={"Email"} name={"Email"}>
                                <StyledInput suffix={<InputMailSvg/>}/>
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item label={"Phone"} name={"Phone"}>
                                <StyledInput suffix={<InputPhoneSvg/>}/>
                            </Form.Item>
                            <Form.Item label={"Role"} name={"Role"}>
                                <StyledSelect>
                                    <Option value={30}>User</Option>
                                    <Option value={90}>Admin</Option>
                                </StyledSelect>
                            </Form.Item>
                            <Form.Item label={"Password"} name={"Password"} rules={[{
                                required: true,
                                message: "Password is required"
                            }]}>
                                <StyledInput/>
                            </Form.Item>
                        </div>
                    </StyledStyledForm>
                </StyledModal>
                <StyledModal onCancel={() => {
                    setEditModalVisible(false)
                    formEditRef.resetFields();
                }}
                             okText="Save"
                             onOk={() => formEditRef.submit()}
                             visible={editModalVisible}
                             title={"Edit user"}>
                    <StyledStyledForm hideRequiredMark layout={"vertical"} form={formEditRef}
                                      onFinish={f => editUser(f, editableUser.Id)}>
                        <div>
                            <Form.Item label={"Login"} name={"Login"}>
                                <StyledInput suffix={<InputLoginSvg/>} disabled/>
                            </Form.Item>
                            <Form.Item label={"Name"} name={"Name"}>
                                <StyledInput suffix={<InputNameSvg/>}/>
                            </Form.Item>
                            <Form.Item label={"Email"} name={"Email"}>
                                <StyledInput suffix={<InputMailSvg/>}/>
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item label={"Phone"} name={"Phone"}>
                                <StyledInput suffix={<InputPhoneSvg/>}/>
                            </Form.Item>
                            <Form.Item label={"Role"} name={"Role"}>
                                <StyledSelect>
                                    <Option value={30}>User</Option>
                                    <Option disabled value={90}>Admin</Option>
                                </StyledSelect>
                            </Form.Item>
                            <Form.Item label={"Password"} name={"Password"} rules={[{
                                required: false,
                                message: "Password is required"
                            }]}>
                                <StyledInput disabled/>
                            </Form.Item>
                        </div>
                    </StyledStyledForm>
                </StyledModal>
                <Header>
                    <SearchInput onChange={search} prefix={<ZoomSvg/>} placeholder={"Search user"}/>
                    <Button onClick={() => setCreateModalVisible(true)} className={"add_btn"}>Add User</Button>
                </Header>
                <UsersWrap>
                    <UsersList>
                        {filteredUsers?.length > 0 && filteredUsers?.map((user: any, index: number) => (
                            <Dropdown key={index}
                                      getPopupContainer={() => document.querySelector("." + StyledUsers.styledComponentId)!}
                                      overlay={() => userMenu(user)} trigger={['contextMenu']}>
                                <UserItem>
                                    <img src={user.Photo || noava} alt="avatar"/>
                                    <div className="name">{user.Name || user.Login}</div>
                                    <div className="state">
                                        {user.State ? <CheckedOnSvg onClick={() => switchState(user.Id)}/> :
                                            <CheckedOffSvg onClick={() => switchState(user.Id)}/>}
                                    </div>
                                    <div className="download">
                                        <DownloadSvg onClick={()=>getDownloadStatus(user.Id)}/>
                                    </div>
                                    <div className="keyGenerate" onClick={() => {
                                        setKeyUserId(user.Id);
                                        setGenModal(true);
                                    }}>
                                        <KeySvg/>
                                    </div>
                                    <Dropdown
                                        getPopupContainer={() => document.querySelector("." + StyledUsers.styledComponentId)!}
                                        overlay={() => userMenu(user)} trigger={['click']}>
                                        <MoreSvg className={"action"}/>
                                    </Dropdown>
                                </UserItem>
                            </Dropdown>
                        ))}
                    </UsersList>
                </UsersWrap>
            </UserList>
            <StyledUserBackgroundSvg/>
        </StyledUsers>
    );
}

export default Users;