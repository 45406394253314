import React from 'react';

import styled from "styled-components";

const StyledLogoutSVG = styled.svg`
  height: 28px;
  width: 28px;
  fill: #8189a5;
`;


function LogoutSvg(props: any) {
    return (
        <StyledLogoutSVG {...props} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                         xmlnsXlink="http://www.w3.org/1999/xlink"
                         x="0px" y="0px"
                         viewBox="0 0 512 512" xmlSpace="preserve">
            <path d="M510.371,226.517c-1.088-2.624-2.645-4.971-4.629-6.955l-63.979-63.979c-8.341-8.341-21.824-8.341-30.165,0
    c-8.341,8.341-8.341,21.824,0,30.165l27.584,27.584h-55.168v-192C384.013,9.557,374.477,0,362.68,0H21.347
    c-0.768,0-1.451,0.32-2.197,0.405c-1.024,0.107-1.92,0.277-2.901,0.533c-2.219,0.555-4.245,1.429-6.123,2.624
    c-0.469,0.299-1.067,0.32-1.515,0.661C8.44,4.352,8.376,4.587,8.205,4.715C5.88,6.549,3.939,8.789,2.531,11.456
    c-0.299,0.576-0.363,1.195-0.597,1.792c-0.683,1.621-1.429,3.2-1.685,4.992c-0.107,0.64,0.085,1.237,0.064,1.856
    c-0.021,0.427-0.299,0.811-0.299,1.237V448c0,10.176,7.189,18.923,17.152,20.907l213.333,42.667
    c1.387,0.299,2.795,0.427,4.181,0.427c4.885,0,9.685-1.685,13.525-4.843c4.928-4.053,7.808-10.091,7.808-16.491v-21.333H362.68
    c11.797,0,21.333-9.557,21.333-21.333V256h55.168l-27.584,27.584c-8.341,8.341-8.341,21.824,0,30.165
    c4.16,4.16,9.621,6.251,15.083,6.251s10.923-2.091,15.083-6.251l63.979-63.979c1.984-1.984,3.541-4.331,4.629-6.955
    C512.525,237.611,512.525,231.723,510.371,226.517z M191.373,325.184c-2.432,9.685-11.115,16.149-20.672,16.149
    c-1.707,0-3.456-0.192-5.184-0.64l-42.667-10.667c-11.435-2.859-18.389-14.443-15.531-25.877
    c2.837-11.413,14.379-18.432,25.856-15.509l42.667,10.667C187.277,302.165,194.232,313.749,191.373,325.184z M341.347,426.667
    h-85.333V85.333c0-9.408-6.187-17.728-15.211-20.437l-74.091-22.229h174.635V426.667z"/>

        </StyledLogoutSVG>

    );
}

export default LogoutSvg;