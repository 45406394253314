// Последовательность достаточно простая:
// 1 инициатор звонка - start() -> gotStream() -> call() -> creationOffer() -> отправка -> получение -> gotAnswer() -> hangup()
// 2 принимающий звонок - start() -> gotStream() -> answer() -> получение -> gotOffer() -> creationAnswer() -> hangup()
// 3 обе стороны могут обмениваться ICE candidate - получение -> gotCandidate()


/*TODO: заменил this.userId на e.currentTarget.userId(и event.currentTarget.userId), потомучто adapter.js ведет себя странно с этими параметрами*/


import {
    sendAddRoom,
    sendAnswerMessage,
    sendAnswerRoom,
    sendCandidateMessage,
    sendCreateRoom,
    sendOfferMessage
} from "./websocket";
import store from "../redux/store";
import {cryptoTurn, turn} from "../config";
import {message} from "antd";
import {changeICreator, changeStreams} from "../redux/actions/pageActions";
import * as uuid from "uuid";
import history from "./history";
import callSound from "../audio/call_sound.wav";
// let currentCameraIndex = 0;
// let true = true;

const callAudio = new Audio(callSound);
callAudio.loop = true;

let video1;

let soundOn = true;
let videoOn = true;

let isAudioCall = false;

let flipped = false;

let users = new Map();

let global_digest;

const offerOptions = {
    offerToReceiveAudio: true,
    offerToReceiveVideo: isAudioCall === false
};

export function callToUsers(chatId, users, type) {
    console.log("Ты звонишь пользователям: ", users)
    playCallSound();

    history.push("/video/" + chatId);

    start(type === "audio").then(() => {
        create(users.map(el => el.Id), type)
    });
}


export function callToUser(userId, type) {
    console.log("Ты звонишь пользователю: ", userId)
    playCallSound();

    history.push("/video/" + userId);

    start(type === "audio").then(() => {
        create([userId], type)
    });
}

function getConfig() {
    return {
        iceTransportPolicy: "relay", // ставим relay, чтобы принудительно использовать TURN
        iceServers: [
            {
                urls: store.getState().pageReducer.encryption ? cryptoTurn : turn,
                username: store.getState().loginReducer.userData.Login,
                credential: store.getState().loginReducer.userData.Digest
            }],
    };
}

//отображение нашего локального изображения
async function gotStream(stream) {
    console.log('Received local stream');

    video1.srcObject = stream;
    window.localStream = stream;
    !isAudioCall && stream.getVideoTracks()[0].applyConstraints({
        width: {
            ideal: parseInt(localStorage.getItem("defender_resolution")?.split("x")[0]) || 640
        },
        height: {
            ideal: parseInt(localStorage.getItem("defender_resolution")?.split("x")[1]) || 480
        },
        frameRate: {max: localStorage.getItem("defender_rate") || 30}
    })
    return Promise.resolve();
}

export function __start(props) {
    video1 = props.video1;
}

export function setDigest(digest) {
    global_digest = digest;
}

export function create(users_, type) {

    let usersId = []
    for (let i = 0; i < users_.length; i++) {
        usersId[i] = Number(users_[i])
    }

    global_digest = uuid.v4()
    sendCreateRoom(global_digest, usersId, type)
}

export function addToRoom(users_) {
    let usersId = []
    for (let i = 0; i < users_.length; i++) {
        usersId[i] = Number(users_[i])
    }

    sendAddRoom(global_digest, usersId, users, isAudioCall ? "audio" : "video")
}

//запрашиваем разрешение на устройства и отображаем
export function start(isAudio) {

    isAudioCall = isAudio === true;
    soundOn = true;
    videoOn = true;
    flipped = false;
    console.log('Requesting local stream');

    return new Promise((res, rej) => {
        const constraints = {
            audio: localStorage.getItem("defender_audioInput") ? {
                deviceId: localStorage.getItem("defender_audioInput")
            } : true,
            video: isAudioCall === false
        }
        console.log(constraints);
        try {
            navigator.mediaDevices
                .getUserMedia(constraints)
                .then(v => {
                    console.log("Начал поток")
                    gotStream(v).then(() => {
                        console.log("Я ПОЛУЧИЛ")
                        return res();
                    })
                })
                .catch(e => {
                    console.log('getUserMedia() error: ', e)
                    message.error("Cannot get camera!")
                    return rej();
                });

        } catch (e) {
            console.log(e);
            message.error("Cannot get camera!")
        }
    })
}

/*Отключение и включение микрофона*/
export function switchMicrophone() {
    console.log(soundOn);

    if (users.size <= 0) {
        return soundOn;
    }
    window.localStream.getAudioTracks()[0].enabled = !soundOn;

    soundOn = !soundOn;

    return soundOn;
}

/*Переключение камеры с фронтальной на переднюю и наоборот*/
export function flipCamera() {
    if (users.size <= 0) {
        return flipped;
    }

    if (isAudioCall) {
        return;
    }

    console.log(flipped);
    flipped = !flipped;

    window.localStream.getTracks().forEach(t => {
        t.stop();
    });

    navigator.mediaDevices.getUserMedia({
        audio: true,
        video: {
            facingMode: flipped ? 'environment' : 'user'
        }
    })
        .then(function (stream) {
            gotStream(stream).then(() => {
                /*TODO: это быстрофикс, audioTrack добавил чтобы не пропадал звук при flipCamera*/
                let videoTrack = stream.getVideoTracks()[0];
                let audioTrack = stream.getAudioTracks()[0];

                users.forEach(el => {
                    let sender = el.getSenders().find(function (s) {
                        return s.track.kind === videoTrack.kind;
                    });
                    console.log('found videoTrack sender:', sender);
                    sender.replaceTrack(videoTrack)
                })

                users.forEach(el => {
                    let sender = el.getSenders().find(function (s) {
                        return s.track.kind === audioTrack.kind;
                    });
                    console.log('found audioTrack sender:', sender);
                    sender.replaceTrack(audioTrack)
                })

                if (!soundOn) {
                    window.localStream.getAudioTracks()[0].enabled = false;
                }
            })
        })
        .catch(function (e) {
            message.error(e.toString());
            console.log(e);
        });

    // window.localStream.getVideoTracks()[0].applyConstraints({
    //     video: {
    //         facingMode: flipped ? 'user' : 'environment',
    //     }
    // })
}

/*Отключение и включение видео*/
export function turnVideo() {
    if (users.size <= 0) {
        return videoOn;
    }

    if (isAudioCall) {
        return true;
    }
    console.log(videoOn);
    console.log(users);

    if (videoOn) {
        users.forEach(el => {
            el.getSenders().forEach(sender => {
                if (sender.track.kind === "video")
                    sender.track.enabled = false;
            })
        })
    } else {
        users.forEach(el => {
            el.getSenders().forEach(sender => {
                if (sender.track.kind === "video")
                    sender.track.enabled = true;
            })
        })
    }

    videoOn = !videoOn;

    return videoOn;
}

export const getCallType = () => {
    return isAudioCall ? "audio" : "video";
}

function createPeer(userId) {
    console.log("createPeer");
    users.set(userId, new RTCPeerConnection(getConfig()));
    users.get(userId).userId = userId;
    users.get(userId).ontrack = gotRemoteStream;
    users.get(userId).onicecandidate = iceCallback;
    users.get(userId).oniceconnectionstatechange = changeState;

    window.localStream.getTracks().forEach(track => users.get(userId).addTrack(track, window.localStream)); //указываем что мы планируем отправлять

    return users.get(userId);
}

//создаем RTCPeerConnection и отправляем offer
export function call(userId) {
    createPeer(userId).createOffer(offerOptions)
        .then(desc => creationOffer(desc, userId), onCreateSessionDescriptionError);
}

export function answer(userId) {
    createPeer(userId);
}

function onCreateSessionDescriptionError(error) {
    console.log(`Failed to create session description: ${error.toString()}`);
}

function creationOffer(desc, userId) {
    users.get(userId).setLocalDescription(desc).catch(e => {
        console.log(e);
    }) //устанавливаем себе desc

    sendOfferMessage(global_digest, desc, userId); //отправляем на сервер информацию о нашем desc
}

export function gotOffer(desc, userId) {
    users.get(userId).setRemoteDescription(desc).catch(e => {
        console.log(e);
    }) //устанавливаем удаленный desc

    users.get(userId).createAnswer()
        .then(desc => creationAnswer(desc, userId), onCreateSessionDescriptionError);
}

function creationAnswer(desc, userId) {
    users.get(userId).setLocalDescription(desc).catch(e => {
        console.log(e);
    })

    sendAnswerMessage(global_digest, desc, userId); //отправляем в ответ наш desc
}

export function gotAnswer(desc, userId) {
    users.get(userId).setRemoteDescription(desc).catch(e => {
        console.log(e);
    }) //получили удаленный desc
}

function changeState(event) {
    if (this.iceConnectionState === "disconnected") {
        store.dispatch(changeStreams([...store.getState().pageReducer.streams.filter(el => el.id !== event.currentTarget.userId)]))

        // users.get(this.userId).videoContainer.remove()
        users.delete(event.currentTarget.userId)
    }
}

export function hangup() {
    console.log('Ending calls');

    stopCallSound();
    users.forEach((value, key) => {
        sendAnswerRoom(global_digest, value.userId, "cancel");
        users.get(key).close();
        users.delete(key);
    })

    window.localStream?.getTracks()?.forEach(function (track) {
        track.stop();
    });
    store.dispatch(changeStreams([]))
    store.dispatch(changeICreator(false))
}

function gotRemoteStream(e) {

    if (store.getState().pageReducer.streams.filter(el => el.id === e.currentTarget.userId).length > 0) {
        store.dispatch(changeStreams([...store.getState().pageReducer.streams.filter(el => el.id !== e.currentTarget.userId), {
            id: e.currentTarget.userId,
            stream: e.streams[0]
        }]))
    } else {
        store.dispatch(changeStreams([...store.getState().pageReducer.streams, {
            id: e.currentTarget.userId,
            stream: e.streams[0]
        }]))
    }
    console.log(e);
    console.log('pc1: received remote stream');
}

function iceCallback(event) {
    sendCandidateMessage(global_digest, event, event.currentTarget.userId) //отправим информацию о нашем ICE
}

export function gotCandidate(candidate, userId) {
    users.get(userId).addIceCandidate(candidate) //получили удаленный ICE, добавляем к себе
        .then(onAddIceCandidateSuccess, onAddIceCandidateError);
}

function onAddIceCandidateSuccess() {
    console.log('AddIceCandidate success.');
}

function onAddIceCandidateError(error) {
    console.log(`Failed to add ICE candidate: ${error.toString()}`);
}

export const playCallSound = () => {
    callAudio.play();
}
export const stopCallSound = () => {
    callAudio.currentTime = 0;
    callAudio.pause();
}