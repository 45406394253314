import React from 'react';

function CaptureNav(props:any) {
    return (
        <svg {...props} width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.6667 6V1.33333C18.6667 0.6 18.0667 0 17.3333 0H1.33333C0.6 0 0 0.6 0 1.33333V14.6667C0 15.4 0.6 16 1.33333 16H17.3333C18.0667 16 18.6667 15.4 18.6667 14.6667V10L21.72 13.0533C22.56 13.8933 24 13.2933 24 12.1067V3.88C24 2.69333 22.56 2.09333 21.72 2.93333L18.6667 6Z" fill="white"/>
        </svg>
    );
}

export default CaptureNav;