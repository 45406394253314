import React from 'react';

function EndCall(props:any) {
    return (
        <svg {...props} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="48" height="48" rx="10" fill="#FF5555"/>
            <path d="M16.51 27.48L18.51 25.89C18.99 25.51 19.27 24.93 19.27 24.32V21.72C22.29 20.74 25.56 20.73 28.59 21.72V24.33C28.59 24.94 28.87 25.52 29.35 25.9L31.34 27.48C32.14 28.11 33.28 28.05 34 27.33L35.22 26.11C36.02 25.31 36.02 23.98 35.17 23.23C28.76 17.57 19.1 17.57 12.69 23.23C11.84 23.98 11.84 25.31 12.64 26.11L13.86 27.33C14.57 28.05 15.71 28.11 16.51 27.48Z" fill="white"/>
        </svg>
    );
}

export default EndCall;