import axios from "axios";
import {api} from "../../config";
import {LOGIN_SUCCESS, LOGOUT_SUCCESS} from "../actionTypes";
import history from "../../utils/history";
import {message} from "antd";
import store from "../store";
import {getChats, getContacts} from "./pageActions";

export function loginAction({login, password}) {
    return (dispatch) => {
        axios(`${api}/auth?a123=${login}&a321=${password}`).then(({data}) => {
            store.dispatch(getChats());
            store.dispatch(getContacts());
            dispatch({type: LOGIN_SUCCESS, payload: data})
            history.push("/")
        }).catch(({response: {data}})=>{
            message.error(data);
        })
    }
}

export function logoutAction() {
    return (dispatch) => {
        dispatch({type: LOGOUT_SUCCESS})
    }
}

export function refreshTokenAction() {
    return (dispatch) => {
        axios(`${api}/user`).then(({data}) => {
            store.dispatch(getChats());
            store.dispatch(getContacts());
            dispatch({type: LOGIN_SUCCESS, payload: data})
            history.location.pathname === "/login" && history.push("/");
        }).catch(err=>{
            console.log(err);
            if (history.location.pathname !== "/login")
                history.push("/restart")
        })
    }
}