import React from 'react';

function MicrophoneNav(props: any) {
    return (
        <svg {...props} width="20" height="26" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.99994 16.6666C12.2133 16.6666 13.9999 14.88 13.9999 12.6666V4.66663C13.9999 2.45329 12.2133 0.666626 9.99994 0.666626C7.78661 0.666626 5.99994 2.45329 5.99994 4.66663V12.6666C5.99994 14.88 7.78661 16.6666 9.99994 16.6666ZM17.8799 12.6666C17.2266 12.6666 16.6799 13.1466 16.5733 13.8C16.0266 16.9333 13.2933 19.3333 9.99994 19.3333C6.70661 19.3333 3.97327 16.9333 3.42661 13.8C3.31994 13.1466 2.77327 12.6666 2.11994 12.6666C1.30661 12.6666 0.666608 13.3866 0.786608 14.1866C1.43994 18.1866 4.63994 21.32 8.66661 21.8933V24.6666C8.66661 25.4 9.26661 26 9.99994 26C10.7333 26 11.3333 25.4 11.3333 24.6666V21.8933C15.3599 21.32 18.5599 18.1866 19.2133 14.1866C19.3466 13.3866 18.6933 12.6666 17.8799 12.6666Z"
                fill="white"/>
        </svg>
    );
}

export default MicrophoneNav;