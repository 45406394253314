import React from 'react';

function ModalSearchSvg(props:any) {
    return (
        <svg {...props} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.890137 7.44629C0.890137 11.0986 3.86182 14.0703 7.51416 14.0703C8.9585 14.0703 10.2783 13.6055 11.3657 12.8252L15.4497 16.9175C15.6406 17.1084 15.8896 17.1997 16.1553 17.1997C16.7197 17.1997 17.1099 16.7764 17.1099 16.2202C17.1099 15.9546 17.0103 15.7139 16.8359 15.5396L12.7769 11.4556C13.6318 10.3433 14.1382 8.95703 14.1382 7.44629C14.1382 3.79395 11.1665 0.822266 7.51416 0.822266C3.86182 0.822266 0.890137 3.79395 0.890137 7.44629ZM2.30957 7.44629C2.30957 4.57422 4.64209 2.2417 7.51416 2.2417C10.3862 2.2417 12.7188 4.57422 12.7188 7.44629C12.7188 10.3184 10.3862 12.6509 7.51416 12.6509C4.64209 12.6509 2.30957 10.3184 2.30957 7.44629Z"
                fill="#b9b6c2" fillOpacity="0.6"/>
        </svg>
    );
}

export default ModalSearchSvg;