/*OLD CODE*/

// function resizeImage(base64Str, maxWidth = 400, maxHeight = 350) {
//     return new Promise((resolve) => {
//         let img = new Image()
//         img.src = base64Str
//         img.onload = () => {
//             let canvas = document.createElement('canvas')
//             const MAX_WIDTH = maxWidth
//             const MAX_HEIGHT = maxHeight
//             let width = img.width
//             let height = img.height
//
//             if (width > height) {
//                 if (width > MAX_WIDTH) {
//                     height *= MAX_WIDTH / width
//                     width = MAX_WIDTH
//                 }
//             } else {
//                 if (height > MAX_HEIGHT) {
//                     width *= MAX_HEIGHT / height
//                     height = MAX_HEIGHT
//                 }
//             }
//             canvas.width = width
//             canvas.height = height
//             let ctx = canvas.getContext('2d')
//             ctx.drawImage(img, 0, 0, width, height)
//             resolve(canvas.toDataURL())
//         }
//     })
// }

/*CODE FOR WIDTH ONLY*/
export function resizeByWidth(base64Str, maxWidth = 900) {
    return new Promise((resolve) => {
        let img = new Image()
        img.src = base64Str
        img.onload = () => {
            let canvas = document.createElement('canvas')
            const MAX_WIDTH = maxWidth
            let width = img.width
            let height = img.height


            if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width
                width = MAX_WIDTH
            }

            canvas.width = width
            canvas.height = height
            let ctx = canvas.getContext('2d')
            ctx.drawImage(img, 0, 0, width, height)
            resolve(canvas.toDataURL())
        }
    })
}