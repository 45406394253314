import React from 'react';

function FingerprintKeySvg(props: any) {
    return (
        <svg {...props} width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="5" cy="9" r="4" stroke="white" strokeWidth="2"/>
            <path d="M8 6L11.5 2.5M13 1L11.5 2.5M11.5 2.5L14 5" stroke="white" strokeWidth="2"
                  strokeLinecap="round"/>
        </svg>
    );
}

export default FingerprintKeySvg;