import React from 'react';
import styled, {css} from "styled-components";

const StyledBackButton = styled.svg(({theme: {svg}}) => css`
  path {
    fill: ${svg.color1};
  }
`);

function BackButton(props: any) {
    return (
        <StyledBackButton {...props} width="14" height="23" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.738304 12.8468L10.5642 22.6481C10.7914 22.875 11.0948 23 11.4183 23C11.7418 23 12.0451 22.875 12.2724 22.6481L12.996 21.9265C13.4669 21.4563 13.4669 20.692 12.996 20.2225L4.74498 11.9919L13.0052 3.75218C13.2324 3.5253 13.3579 3.22286 13.3579 2.90036C13.3579 2.5775 13.2324 2.27506 13.0052 2.048L12.2816 1.32655C12.0541 1.09967 11.7509 0.974681 11.4275 0.974681C11.104 0.974681 10.8006 1.09967 10.5733 1.32655L0.738304 11.1369C0.510505 11.3645 0.385386 11.6683 0.386104 11.9914C0.385386 12.3157 0.510505 12.6194 0.738304 12.8468Z"
                fill="#4F86EC"/>
        </StyledBackButton>
    );
}

export default BackButton;