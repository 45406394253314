import styled from "styled-components";
import {Form} from "antd";

const StyledForm = styled(Form)`
  label {
    font-size: 14px;
    color: #868792;
  }
`;

export default StyledForm;