import React from 'react';

function LittleLogoMenuDeskSvg(props: any) {
    return (
        <svg {...props} version="1.1" xmlns="http://www.w3.org/2000/svg"
             fill={"white"}
             xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="54px" height="84px" viewBox="0 0 104 84"
             xmlSpace="preserve">
            <path className="st0" d="M102.9,17.4L95,11.1c-0.4-0.3-1-0.5-1.5-0.5H82.8c-0.3-0.5-0.6-0.9-1-1.3L70.9,0.7C70.3,0.3,69.6,0,68.9,0
		H48.3H35c-0.7,0-1.4,0.3-2,0.7L22.2,9.3c-0.4,0.3-0.8,0.8-1,1.3H10.5c-0.5,0-1.1,0.2-1.5,0.5l-7.9,6.3C0.4,17.9,0,18.8,0,19.7v2.2
		h0C0,30,0,38.1,0,46.3c0,3.5,1.2,6.6,3,9.4c2.3,3.6,5.2,6.4,8.4,8.9c3.3,2.7,6.8,4.9,10.5,6.9c0.6,0.3,1.3,0.3,1.9,0
		c2-1.1,3.9-2.2,5.8-3.5c0.3,0.3,0.6,0.6,0.9,0.9c6,6.1,12.9,10.8,20.2,14.7c0.8,0.4,1.8,0.4,2.6,0c5-2.7,9.9-5.8,14.4-9.5
		c2.4-1.9,4.7-4,6.7-6.2c1.9,1.3,3.9,2.4,5.8,3.5c0.6,0.3,1.3,0.3,1.9,0c3.7-2,7.2-4.2,10.5-6.9c3.2-2.6,6.1-5.4,8.4-8.9
		c1.8-2.8,3-5.9,3-9.4c0-8.1,0-16.3,0-24.4h0v-2.2C104,18.8,103.6,17.9,102.9,17.4z M98.1,54.1c-2.1,3-4.6,5.5-7.4,7.7
		c-2.9,2.3-6,4.3-9.2,6.1c-0.2,0.1-0.5,0.1-0.7,0c-4.5-2.5-8.8-5.4-12.6-9.1c-0.2-0.2-0.5-0.5-0.7-0.7c-1.3-1.4-3.3-1.6-4.9-0.6l0,0
		c0,0,0,0,0,0c0.9,1.2,1.8,2.3,2.9,3.3c1.6,1.6,3.3,3.1,5.1,4.5c-1.7,1.8-3.6,3.4-5.5,5c-4,3.2-8.2,6-12.6,8.4c-0.3,0.2-0.7,0.2-1,0
		c-6.2-3.4-12.1-7.4-17.3-12.5c-0.3-0.3-0.6-0.6-0.9-0.9c1.8-1.4,3.5-2.9,5.1-4.6c1-1,2-2.1,2.9-3.3c0,0,0,0,0,0l0,0
		c-1.6-1-3.5-0.8-4.9,0.6c-0.2,0.3-0.5,0.5-0.7,0.7c-3.8,3.7-8.1,6.6-12.6,9.1c-0.2,0.1-0.5,0.1-0.7,0c-3.2-1.8-6.3-3.8-9.2-6.1
		c-2.7-2.2-5.3-4.7-7.4-7.7c-1.8-2.6-2.7-5.5-2.7-8.9l0-23.4h0v-0.7c0-0.3,0.1-0.6,0.4-0.8l7.5-5.9c0.2-0.1,0.3-0.2,0.5-0.2h13.8H34
		c0.2,0,0.4,0.1,0.5,0.2c1.2,1,2.8,0.9,4-0.1c0.4-0.3,0.7-0.6,1.1-0.8l-3-2.4c-0.4-0.3-1-0.5-1.5-0.5h-6l6.7-5.3
		C36.1,5.1,36.3,5,36.6,5h11.7h19c0.3,0,0.5,0.1,0.7,0.3l6.7,5.3h-5.9c-0.5,0-1.1,0.2-1.5,0.5l-3,2.4c0.4,0.3,0.8,0.5,1.1,0.8
		c1.2,1,2.8,1,4,0.1c0.2-0.1,0.3-0.2,0.5-0.2h8.6h13.8c0.2,0,0.4,0.1,0.5,0.2l7.5,5.9c0.2,0.2,0.4,0.5,0.4,0.8v0.7h0l0,23.4
		C100.9,48.6,99.9,51.5,98.1,54.1z"/>
            <path className="st0" d="M67.2,25.1c-0.8-5-4.3-7.8-4.4-7.9l-1.3-1l-4.2,6.2c-1.7-0.7-3.6-1.1-5.5-1c-1.7,0-3.3,0.4-4.8,1L42.6,16
		l-1.3,1c-0.2,0.1-3.7,2.9-4.4,7.9c-0.3,2.2-0.1,4.5,0.8,6.8C36.6,34.2,36,37,36,40v7.8c0,0.7,0.3,1.4,0.9,1.7l3.9,2.6
		c1.5,1,2.8,2.4,3.9,3.9c0.3,0.5,0.6,1,1,1.5c1.1,2.7,3.8,2.3,3.8,2.3H52h2.6c0,0,2.6,0.3,3.8-2.3c0.4-0.6,0.7-1.1,1-1.5
		c1.1-1.6,2.4-2.9,3.9-3.9l3.9-2.6c0.5-0.4,0.9-1,0.9-1.7v-8.1h0c0-2.8-0.6-5.5-1.6-7.9C67.3,29.6,67.5,27.3,67.2,25.1z M62,21.5
		c0.7,0.9,1.6,2.4,1.9,4.3C64,26.4,64,27,64,27.6c-1.1-1.4-2.3-2.6-3.7-3.5L62,21.5z M40.2,25.6c0.3-1.9,1.2-3.3,1.9-4.3l1.8,2.7
		c-1.4,1-2.7,2.2-3.8,3.6C40.1,26.9,40.1,26.2,40.2,25.6z M64,46.6l-3.9,2.6c-1,0.7-1.9,1.6-2.5,2.8c-0.9,1.6-2.8,3.8-5.5,3.8
		c-2.8,0-4.6-2.3-5.6-3.9c-0.7-1.1-1.5-2.1-2.6-2.7L40,46.6c0,0-0.4-0.2-0.4-1.1l0.3-5.8c0-7.7,5.4-13.9,12.1-13.9
		c6.7,0,12.1,6.2,12.1,13.9h0l0.3,5.8C64.4,46.4,64,46.6,64,46.6z"/>
            <path className="st0"
                  d="M52,46c-1.1,0-2,2.4-2,3.7c0,1.3,0.9,2.3,2,2.3c1.1,0,2-1,2-2.3C54,48.4,53.1,46,52,46z"/>
            <path className="st0" d="M29.2,39.2c0.4-1,0.5-2.1,0.4-3c-0.4-2.3-2.1-3.5-2.2-3.6l-0.6-0.4l-2.1,2.8c-0.9-0.3-1.8-0.5-2.7-0.5
		c-0.8,0-1.7,0.2-2.4,0.4L17.3,32l-0.6,0.4c-0.1,0.1-1.8,1.3-2.2,3.6c-0.2,1,0,2.1,0.4,3.1c-0.5,1.1-0.8,2.4-0.8,3.8v3.5
		c0,0.3,0.2,0.6,0.4,0.8l1.9,1.2c0.7,0.5,1.4,1.1,1.9,1.8c0.2,0.2,0.3,0.4,0.5,0.7c0.6,1.2,1.9,1.1,1.9,1.1H22h1.3
		c0,0,1.3,0.1,1.9-1.1c0.2-0.3,0.4-0.5,0.5-0.7c0.5-0.7,1.2-1.3,1.9-1.8l1.9-1.2c0.3-0.2,0.4-0.5,0.4-0.8v-3.7h0
		C30,41.5,29.7,40.3,29.2,39.2z M27,34.5c0.4,0.4,0.8,1.1,1,1.9c0,0.3,0.1,0.5,0,0.8c-0.5-0.6-1.2-1.2-1.8-1.6L27,34.5z M16.1,36.3
		c0.1-0.9,0.6-1.5,0.9-1.9l0.9,1.2c-0.7,0.4-1.3,1-1.9,1.6C16,37,16,36.6,16.1,36.3z M28,45.9l-2,1.2c-0.5,0.3-0.9,0.7-1.3,1.3
		c-0.5,0.7-1.4,1.7-2.8,1.7c-1.4,0-2.3-1-2.8-1.8c-0.3-0.5-0.8-0.9-1.3-1.2L16,45.9c0,0-0.2-0.1-0.2-0.5l0.1-2.6
		c0-3.5,2.7-6.3,6-6.3c3.3,0,6,2.8,6,6.3h0l0.1,2.6C28.2,45.8,28,45.9,28,45.9z"/>
            <path className="st0"
                  d="M21,46c-0.6,0-1,0.8-1,1.2s0.4,0.8,1,0.8c0.6,0,1-0.3,1-0.8C22,46.8,21.6,46,21,46z"/>
            <path className="st0" d="M89.2,39.2c0.4-1,0.5-2.1,0.4-3c-0.4-2.3-2.1-3.5-2.2-3.6l-0.6-0.4l-2.1,2.8c-0.9-0.3-1.8-0.5-2.7-0.5
		c-0.8,0-1.7,0.2-2.4,0.4L77.3,32l-0.6,0.4c-0.1,0.1-1.8,1.3-2.2,3.6c-0.2,1,0,2.1,0.4,3.1c-0.5,1.1-0.8,2.4-0.8,3.8v3.5
		c0,0.3,0.2,0.6,0.4,0.8l1.9,1.2c0.7,0.5,1.4,1.1,1.9,1.8c0.2,0.2,0.3,0.4,0.5,0.7c0.6,1.2,1.9,1.1,1.9,1.1H82h1.3
		c0,0,1.3,0.1,1.9-1.1c0.2-0.3,0.4-0.5,0.5-0.7c0.5-0.7,1.2-1.3,1.9-1.8l1.9-1.2c0.3-0.2,0.4-0.5,0.4-0.8v-3.7h0
		C90,41.5,89.7,40.3,89.2,39.2z M87,34.5c0.4,0.4,0.8,1.1,0.9,1.9c0,0.3,0.1,0.5,0,0.8c-0.5-0.6-1.2-1.2-1.8-1.6L87,34.5z
		 M76.1,36.3c0.1-0.9,0.6-1.5,0.9-1.9l0.9,1.2c-0.7,0.4-1.3,1-1.9,1.6C76,37,76,36.6,76.1,36.3z M88,45.9l-2,1.2
		c-0.5,0.3-0.9,0.7-1.3,1.3c-0.5,0.7-1.4,1.7-2.8,1.7c-1.4,0-2.3-1-2.8-1.8c-0.3-0.5-0.8-0.9-1.3-1.2L76,45.9c0,0-0.2-0.1-0.2-0.5
		l0.1-2.6c0-3.5,2.7-6.3,6-6.3s6,2.8,6,6.3h0l0.1,2.6C88.2,45.8,88,45.9,88,45.9z"/>
            <path className="st0"
                  d="M81,46c-0.6,0-1,0.8-1,1.2s0.4,0.8,1,0.8c0.6,0,1-0.3,1-0.8C82,46.8,81.6,46,81,46z"/>
        </svg>
    );
}

export default LittleLogoMenuDeskSvg;