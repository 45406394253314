import React from 'react';

function MoreSvg(props:any) {
    return (
        <svg {...props} width="21" height="4" viewBox="0 0 21 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="2.5" cy="2" r="2" fill="#9E9E9E"/>
            <circle cx="10.749" cy="2" r="2" fill="#9E9E9E"/>
            <circle cx="19" cy="2" r="2" fill="#9E9E9E"/>
        </svg>
    );
}

export default MoreSvg;