import React from 'react';

function HideMenuSvg(props: any) {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path
                    d="M23 18L1 18C0.734783 18 0.480429 18.1185 0.292893 18.3295C0.105356 18.5405 1.24435e-07 18.8266 9.83505e-08 19.125C7.22664e-08 19.4234 0.105356 19.7095 0.292892 19.9205C0.480429 20.1315 0.734783 20.25 1 20.25L23 20.25C23.2652 20.25 23.5196 20.1315 23.7071 19.9205C23.8946 19.7095 24 19.4234 24 19.125C24 18.8266 23.8946 18.5405 23.7071 18.3295C23.5196 18.1185 23.2652 18 23 18Z"
                    fill="white"/>
                <path
                    d="M1 5.25L23 5.25C23.2652 5.25 23.5196 5.13147 23.7071 4.9205C23.8946 4.70952 24 4.42337 24 4.125C24 3.82663 23.8946 3.54048 23.7071 3.32951C23.5196 3.11853 23.2652 3 23 3L1 3C0.734783 3 0.480429 3.11853 0.292893 3.3295C0.105354 3.54048 1.24435e-07 3.82663 9.83505e-08 4.125C7.22664e-08 4.42337 0.105354 4.70952 0.292892 4.92049C0.480429 5.13147 0.734783 5.25 1 5.25Z"
                    fill="white"/>
                <path
                    d="M0.84375 12.75L12.6562 12.75C12.88 12.75 13.0946 12.6315 13.2529 12.4205C13.4111 12.2095 13.5 11.9234 13.5 11.625C13.5 11.3266 13.4111 11.0405 13.2529 10.8295C13.0946 10.6185 12.88 10.5 12.6563 10.5L0.84375 10.5C0.619973 10.5 0.405362 10.6185 0.247128 10.8295C0.088894 11.0405 1.24435e-07 11.3266 9.83506e-08 11.625C7.22664e-08 11.9234 0.0888939 12.2095 0.247128 12.4205C0.405362 12.6315 0.619973 12.75 0.84375 12.75Z"
                    fill="white"/>
                <path
                    d="M21.9515 7.79911L18.3824 10.8373C18.1375 11.0465 18 11.3297 18 11.625C18 11.9203 18.1375 12.2035 18.3824 12.4127L21.9515 15.4509C22.1194 15.5938 22.3333 15.6911 22.5662 15.7304C22.799 15.7698 23.0404 15.7495 23.2597 15.6721C23.479 15.5946 23.6664 15.4636 23.7982 15.2954C23.9299 15.1273 24.0002 14.9297 24 14.7276L24 8.52241C24.0002 8.3203 23.9299 8.12268 23.7982 7.95457C23.6664 7.78645 23.479 7.65538 23.2597 7.57795C23.0404 7.50052 22.799 7.4802 22.5662 7.51957C22.3333 7.55894 22.1194 7.65622 21.9515 7.79911Z"
                    fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="24" height="24" fill="white" transform="translate(24 24) rotate(-180)"/>
                </clipPath>
            </defs>
        </svg>
    );
}

export default HideMenuSvg;