import styled from "styled-components";
import {Select} from "antd";

const StyledSelect = styled(Select)`
  .ant-select-selector {
    border: none !important;
    border-bottom: 1px solid #DADADA !important;
    border-radius: 0 !important;
    box-shadow: none !important;
  }

  margin-top: 6px;
  font-size: 16px;
  color: #010b2b;
`;

export default StyledSelect;