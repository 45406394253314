import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Form, Input, message, Select, Switch} from "antd";
import store from "../redux/store";
import {changeEncryption} from "../redux/actions/pageActions";
import {connect} from "react-redux";
import Button from "../components/Button";

const {Option} = Select;
const StyledOptions = styled.div`
  padding-right: 23px;

  .ant-switch {
    margin-bottom: 20px;
  }

  .ant-row {
    margin-bottom: 20px;
  }
`;

function Options({encryption}: any) {

    const [formRef] = Form.useForm();

    const [availableAudioInput, setAvailableAudioInput] = useState<MediaDeviceInfo[]>([]);
    const [availableAudioOutput, setAvailableAudioOutput] = useState<MediaDeviceInfo[]>([]);

    const handleFinish = (fields: any) => {
        localStorage.setItem("defender_encryption", fields.encryption);
        fields.resolution && localStorage.setItem("defender_resolution", fields.resolution);
        fields.rate && localStorage.setItem("defender_rate", fields.rate);
        fields.audioInput && localStorage.setItem("defender_audioInput", fields.audioInput);
        fields.audioOutput && localStorage.setItem("defender_audioOutput", fields.audioOutput);
        store.dispatch(changeEncryption(fields.encryption));
        message.success("Options successfully saved!")
    }

    useEffect(() => {
        navigator.mediaDevices.enumerateDevices()
            .then(function (MediaDeviceInfo) {
                setAvailableAudioInput(MediaDeviceInfo.filter(el => el.kind === "audioinput"))
                setAvailableAudioOutput(MediaDeviceInfo.filter(el => el.kind === "audiooutput"))
            })
    }, []);


    useEffect(() => {
        formRef.setFieldsValue({
            encryption: encryption
        })
        // eslint-disable-next-line
    }, [encryption])

    return (
        <StyledOptions>
            <Form form={formRef} layout={"vertical"} initialValues={{
                encryption: encryption,
                resolution: localStorage.getItem("defender_resolution"),
                rate: localStorage.getItem("defender_rate"),
                audioInput: localStorage.getItem("defender_audioInput"),
                audioOutput: localStorage.getItem("defender_audioOutput"),
            }} onFinish={handleFinish}>
                <Form.Item valuePropName="checked" noStyle name={"encryption"}>
                    <Switch style={{width: "100%"}} checkedChildren={"Encryption on"}
                            unCheckedChildren={"Encryption off"}/>
                </Form.Item>
                <Form.Item label={"Resolution"} name={"resolution"}>
                    <Select style={{width: "100%"}}>
                        <Option value={"320x240"}>320x240</Option>
                        <Option value={"640x480"}>640x480</Option>
                        <Option value={"1280x720"}>1280x720</Option>
                        <Option value={"1920x1080"}>1920x1080</Option>
                    </Select>
                </Form.Item>
                <Form.Item label={"Video framerate"} name={"rate"}>
                    <Input type="number" min={1} max={30} style={{width: "100%"}}/>
                </Form.Item>
                <Form.Item label={"Audio input device"} name={"audioInput"}>
                    <Select style={{width: "100%"}}>
                        {availableAudioInput.map(audio => (
                            <Option value={audio.deviceId}>{audio.label}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label={"Audio output device"} name={"audioOutput"}>
                    <Select style={{width: "100%"}}>
                        {availableAudioOutput.map(audio => (
                            <Option value={audio.deviceId}>{audio.label}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Button className={"blue"} style={{width: "100%"}} type={"submit"}>Save</Button>
                </Form.Item>
            </Form>
        </StyledOptions>
    );
}

const mapStateToProps = (state: any) => {
    return {
        encryption: state.pageReducer.encryption
    }
}
export default connect(mapStateToProps, null)(Options);