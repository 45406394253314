import React, {useEffect} from 'react';
import styled from "styled-components";
import {connect} from "react-redux";
import noava from "../styles/images/noava.png";
import history from "../utils/history";
import axios from "axios";
import {api} from "../config";
import store from "../redux/store";
import {getChats, getContacts} from "../redux/actions/pageActions";
import {message} from "antd";
import moment from "moment";
import {genBeautifulDate} from "../utils/genBeautifulDate";

const Contact = styled.div`
  display: flex;

  .avatar {
    margin-right: 13px;

    img {
      height: 56px;
      width: 56px;
      object-fit: cover;
      border-radius: 100%;
    }
  }

  .content {
    display: flex;
    flex-direction: column;

    .name {
      font-size: 20px;
      line-height: 23px;
      letter-spacing: -0.3px;
      margin-bottom: 7px;

      &.red {
        color: #ff5252;
      }
    }

    .status {
      font-size: 17px;
      line-height: 20px;
      letter-spacing: -0.3px;
      color: ${({theme}) => theme.layout.highlightedTextColor2};
    }
  }
`;

const NeverSeen = styled.div`
  color: #ff5252;
`;
const Date = styled.div`
  display: flex;
  align-items: center;
  color: #4d4d4d;
  font-size: 12px;
`;
const StyledContacts = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 23px;

  ${Contact} {
    margin-bottom: 22px;
  }
`;

function Contacts({contacts, chats, userData}: any) {

    useEffect(() => {
        store.dispatch(getContacts());
    }, []);

    const handleOpen = (userId: number) => {
        if (userId === userData.Id)
            return;


        let jointChats = chats.filter((el: any) => el.CreatorId === userId || el.CreatorId === userData.Id).filter((el: any) => {
            return el.User.filter((user: any) => user.Id === userId).length > 0
        });

        if (jointChats.length > 0) {
            history.push("/dialog/" + jointChats[0].Id)
        } else {
            axios.post(`${api}/chats`, {
                UserIds: [userId]
            }).then(({data}) => {
                store.dispatch(getChats()).then(() => {
                    history.push("/dialog/" + data.Id);
                });
            }).catch(({response: {data}}) => {
                message.error(data);
            })
        }
    }

    return (
        <StyledContacts>
            {contacts?.map((el: any, index: number) => (
                <Contact key={index} onClick={() => handleOpen(el.Id)}>
                    <div className="avatar">
                        <img src={el.Photo ? `/${el.Photo}` : noava}
                             alt=""/>
                    </div>
                    <div className="content">
                        <div className={`name ${el.State === false ? "red" : ""}`}>
                            {el.Name || el.Login}
                        </div>
                        <div className="status">
                            {el.Online ? "online" : moment(el.Seen).year() === 1 ? <NeverSeen>never seen</NeverSeen> :
                                <Date>{genBeautifulDate(el.Seen)}</Date>}
                        </div>
                    </div>
                </Contact>
            ))}
        </StyledContacts>
    );
}

const mapStateToProps = (state: any) => {
    return {
        contacts: state.pageReducer.contacts,
        chats: state.pageReducer.chats,
        userData: state.loginReducer.userData
    }
}

export default connect(mapStateToProps, null)(Contacts);