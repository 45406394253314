import React from 'react';
import styled from "styled-components";

const StyledAdminSvg = styled.svg`
  path {
    fill: #868792;
  }
  height: 33px;
  width: 33px;
`;

function AdminSvg(props: any) {
    return (
        <StyledAdminSvg {...props} version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                        viewBox="0 0 512 512">
            <path d="M416.831,492.998c-57.813-27.95-105.694-74.877-134.82-132.134c-1.335-2.624-3.759-4.527-6.625-5.201
    c-2.864-0.673-5.883-0.05-8.248,1.705c-1.6,1.187-3.576,2.628-5.607,4.097c-3,2.172-8.067,2.171-11.068,0
    c-2.028-1.468-4.003-2.908-5.604-4.096c-2.364-1.755-5.381-2.377-8.248-1.705c-2.866,0.674-5.29,2.577-6.625,5.201
    c-29.127,57.257-77.007,104.182-134.819,132.132c-4.198,2.029-6.436,6.69-5.395,11.235c1.041,4.546,5.085,7.768,9.748,7.768
    h312.96c4.664,0,8.708-3.222,9.747-7.767C423.268,499.688,421.029,495.027,416.831,492.998z M137.63,492
    c43.385-28.095,79.697-66.781,104.857-112.056c8.341,4.202,18.682,4.201,27.025-0.001c25.158,45.275,61.47,83.961,104.856,112.057
    H137.63z"/>
            <path d="M321.719,465.13c-0.13-0.64-0.32-1.27-0.57-1.87c-0.25-0.6-0.56-1.18-0.92-1.73c-0.359-0.54-0.779-1.05-1.239-1.51
			c-0.46-0.47-0.981-0.88-1.521-1.25c-0.549-0.36-1.129-0.67-1.729-0.92c-0.6-0.25-1.23-0.44-1.87-0.57c-1.29-0.26-2.62-0.26-3.91,0
			c-0.641,0.13-1.271,0.32-1.87,0.57s-1.179,0.56-1.73,0.92c-0.54,0.37-1.05,0.78-1.51,1.25c-0.47,0.46-0.88,0.97-1.25,1.51
			c-0.359,0.55-0.67,1.13-0.92,1.73c-0.25,0.6-0.439,1.23-0.57,1.87c-0.129,0.65-0.189,1.3-0.189,1.96c0,0.65,0.059,1.3,0.189,1.95
			c0.131,0.64,0.32,1.27,0.57,1.87c0.25,0.6,0.561,1.18,0.92,1.73c0.37,0.54,0.78,1.06,1.25,1.52c0.46,0.46,0.97,0.88,1.51,1.24
			c0.55,0.36,1.131,0.67,1.73,0.92s1.229,0.44,1.87,0.57c0.649,0.13,1.3,0.19,1.96,0.19c0.649,0,1.309-0.06,1.95-0.19
			c0.64-0.13,1.269-0.32,1.87-0.57c0.599-0.25,1.179-0.56,1.729-0.92c0.541-0.36,1.061-0.78,1.521-1.24
			c0.46-0.46,0.88-0.98,1.239-1.52c0.36-0.55,0.67-1.13,0.92-1.73c0.25-0.6,0.44-1.23,0.57-1.87c0.13-0.65,0.2-1.3,0.2-1.95
			C321.919,466.43,321.849,465.78,321.719,465.13z"/>
            <path d="M306.855,197.115h-10.03c-5.522,0-10,4.477-10,10s4.478,10,10,10h10.03c5.523,0,10-4.477,10-10
			S312.377,197.115,306.855,197.115z"/>
            <path d="M215.174,197.115h-10.031c-5.523,0-10,4.477-10,10s4.477,10,10,10h10.031c5.523,0,10-4.477,10-10
			S220.698,197.115,215.174,197.115z"/>
            <path d="M256,70.352c-5.523,0-10,4.477-10,10v12.036c0,5.523,4.477,10,10,10c5.522,0,10-4.477,10-10V80.352
			C266,74.829,261.522,70.352,256,70.352z"/>
            <path d="M167.392,126.386h0.002C167.387,126.36,167.38,126.334,167.392,126.386z"/>
            <path d="M397.75,330.004c-0.584-0.105-1.176-0.158-1.77-0.158h-55.102c15.899-14.207,24.849-33.452,25.953-55.953
				c0.496-10.083-0.702-18.855-1.871-24.681c4.472-12.456,6.737-25.5,6.737-38.82l-0.019-52.844c0-12.064-1.849-23.925-5.501-35.3
				l13.454-55.746c0.721-2.984,0.03-6.134-1.873-8.543c-1.896-2.402-4.788-3.803-7.848-3.803h-0.028L354.331,54.2
				c-6.948-24.582-29.408-41.865-55.485-41.865c-8.041,0-15.746,1.595-22.965,4.748c-1.275-1.487-2.582-2.967-3.923-4.435
				l-8.577-9.392C261.487,1.182,258.808,0,255.997,0c-2.81,0-5.49,1.182-7.384,3.256l-8.577,9.393
				c-1.339,1.467-2.645,2.946-3.919,4.434c-7.219-3.152-14.925-4.747-22.967-4.747c-26.072,0-48.528,17.276-55.481,41.851
				l-15.567-0.022h-0.014c-3.062,0-5.956,1.403-7.853,3.808c-1.899,2.409-2.588,5.556-1.868,8.538l13.453,55.744
				c-3.651,11.373-5.5,23.23-5.5,35.294l-0.018,52.844c0,13.32,2.265,26.363,6.736,38.818c-1.17,5.827-2.367,14.598-1.872,24.682
				c1.105,22.502,10.053,41.747,25.952,55.954H116.02c-0.593,0-1.186,0.053-1.77,0.158c-32.921,5.919-56.816,34.492-56.816,67.942
				V502c0,5.523,4.477,10,10,10h377.133c5.522,0,10-4.477,10-10V397.946C454.566,364.496,430.672,335.922,397.75,330.004z
				 M395.047,349.847c22.951,4.531,39.519,24.625,39.519,48.099v31.832c-33.353-18.935-61.319-46.704-80.491-79.931H395.047z
				 M298.846,32.335c15.625,0,29.262,9.49,34.914,23.443c-8.657,1.417-18.189,4.022-26.971,8.692
				c-4.306-8.736-10.289-19.547-18.091-30.767C291.961,32.794,295.353,32.335,298.846,32.335z M213.15,32.335
				c3.501,0,6.9,0.46,10.168,1.373c-7.763,11.189-13.751,22.009-18.074,30.764c-8.792-4.692-18.332-7.305-26.996-8.722
				C183.908,41.813,197.536,32.335,213.15,32.335z M158.773,74.188c10.868,0.015,31.065,1.688,44.221,12.718
				c2.42,2.029,5.657,2.798,8.73,2.067c3.073-0.728,5.622-2.865,6.875-5.765c5.335-12.351,17.135-36.188,36.206-57.073l1.193-1.307
				l1.192,1.306c19.233,21.061,30.946,44.782,36.217,57.047c1.247,2.903,3.793,5.046,6.866,5.779
				c3.072,0.732,6.313-0.028,8.736-2.056c13.128-10.978,33.332-12.67,44.206-12.701l3.986-0.011l-12.598,52.194H167.393
				c0.006,0.026,0.012,0.051-0.002,0l-12.599-52.205L158.773,74.188z M351.679,157.548v13.29
				c-22.34,0.108-52.867,0.251-79.501-3.819c-25.545-3.904-42.46-10.834-50.407-20.631h129.263v-0.001
				C351.463,150.074,351.679,153.799,351.679,157.548z M160.32,157.548c0-3.75,0.216-7.474,0.645-11.161h37.942
				c-11.108,17.584-28.129,22.626-38.586,24.008v-12.847H160.32z M160.302,210.393v-19.895c12.88-1.283,34.622-6.553,50.604-26.5
				c11.952,11.153,31.19,18.657,58.25,22.792c28.148,4.302,59.548,4.162,82.54,4.049v19.554c0,8.91-1.225,17.668-3.651,26.136
				c-0.575,0.383-1.115,0.829-1.608,1.334c-10.953,11.212-25.059,24.026-31.354,27.958c-3.232-1.673-8.545-4.384-14.077-7.045
				c-17.698-8.512-22.964-9.857-28.176-7.191l-13.226,6.766c-2.122,1.085-5.086,1.086-7.209,0l-13.227-6.766
				c-5.211-2.666-10.477-1.322-28.175,7.191c-5.532,2.661-10.845,5.372-14.077,7.045c-6.296-3.932-20.403-16.746-31.355-27.958
				c-0.493-0.505-1.033-0.95-1.608-1.333C161.529,228.061,160.302,219.303,160.302,210.393z M165.063,265.273
				c1.526,1.421,3.113,2.875,4.723,4.319c13.66,12.248,24.899,21.18,33.889,15.258c10.769-5.674,23.804-11.963,29.33-13.954
				l10.283,5.26c7.842,4.012,17.583,4.011,25.426,0l10.282-5.26c5.525,1.991,18.561,8.279,29.329,13.954
				c8.986,5.923,20.228-3.01,33.889-15.258c1.583-1.42,3.146-2.851,4.648-4.25c0.69,14.805-2.422,36.095-21.756,51.666
				c-3.874,2.787-4.283,7.173-4.512,9.624c-0.218,2.338-0.522,5.611-1.938,9.044c-0.076,0.166-0.149,0.335-0.216,0.506
				c-3.517,7.937-13.196,16.521-41.567,16.295c-2.663-0.049-5.231,1.021-7.125,2.899c-0.018,0.019-0.036,0.037-0.055,0.055
				c-0.334,0.265-1.029,0.803-2.432,1.845c-0.082,0.059-0.162,0.119-0.242,0.18c-1.247,0.924-3.007,2.214-5.488,4.01
				c-3,2.171-8.068,2.171-11.068,0c-2.477-1.792-4.235-3.081-5.481-4.005c-0.082-0.063-0.165-0.125-0.249-0.185
				c-1.403-1.042-2.098-1.58-2.431-1.845l-0.055-0.055c-1.893-1.877-4.427-2.946-7.125-2.899
				c-41.238,0.334-42.989-18.008-43.72-25.845c-0.229-2.451-0.638-6.837-4.512-9.625
				C167.403,301.316,164.32,280.051,165.063,265.273z M77.434,397.946c0-23.474,16.568-43.568,39.519-48.1h40.972
				c-19.172,33.227-47.138,60.996-80.491,79.931V397.946z M77.434,492v-39.535c43.51-22.003,79.527-57.469,102.22-100.617
				c0.799,1.085,1.68,2.164,2.65,3.229c8.969,9.851,22.949,15.557,41.652,17.023c-29.11,51.495-73.661,93.72-126.747,119.9H77.434z
				 M137.63,492.001c7.262-4.704,14.326-9.703,21.172-14.98c0.373,0.042,0.753,0.065,1.137,0.065h111.977c5.522,0,10-4.477,10-10
				s-4.478-10-10-10h-89.684c23.874-22.385,44.305-48.438,60.255-77.141c4.171,2.101,8.841,3.152,13.512,3.152
				c4.672,0,9.343-1.051,13.514-3.153c25.16,45.279,61.469,83.96,104.858,112.057H137.63z M434.567,492h-19.776
				c-53.087-26.181-97.638-68.405-126.746-119.9c18.701-1.467,32.683-7.174,41.651-17.024c0.97-1.066,1.851-2.146,2.65-3.23
				c22.693,43.149,58.71,78.615,102.221,100.619V492z"/>
        </StyledAdminSvg>
    );
}

export default AdminSvg;