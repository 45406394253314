import {applyMiddleware, compose, createStore} from "redux";
import thunkMiddleware from 'redux-thunk'
import {createLogger} from "redux-logger";
import {initialState} from "./initialState";
import rootReducer from "./reducers/rootReducer";


const store = createStore(
    rootReducer,
    initialState,
    compose(
        applyMiddleware(thunkMiddleware), applyMiddleware(createLogger())
    )
);

export default store;