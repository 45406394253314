import React from 'react';

function CloseModalSvg(props: any) {
    return (
        <svg {...props} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 1L1 13" stroke="#CCD2E3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1 1L13 13" stroke="#CCD2E3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default CloseModalSvg;