import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import Phone from "../styles/images/phone";
import Info from "../styles/images/Info";
import axios from "axios";
import {api} from "../config";
import {message} from "antd";
import moment from "moment";
import {connect} from "react-redux";
import noava from "../styles/images/noava.png";
import Capture from "../styles/images/Capture";
import {callToUser} from "../utils/webrtc";

const Call = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  padding-right: 23px;
  padding-bottom: 8px;

  &.missed {
    .content {
      .name {
        color: ${({theme}) => theme.layout.redTextColor};
      }
    }
  }
  
  .avatar {
    img {
      height: 44px;
      width: 44px;
      object-fit: cover;
      border-radius: 100%;
    }

    margin-right: 15px;
  }

  .content {
    padding-left: 2px;
    margin-right: auto;

    .name {
      font-size: 18px;
      color: ${({theme}) => theme.layout.textColor};
      line-height: 21px;
      letter-spacing: -0.3px;
      margin-bottom: 5px;
    }

    .event {
      display: flex;
      align-items: center;
      line-height: 16px;
      letter-spacing: -0.3px;
      color: ${({theme}) => theme.layout.nonActiveColor};
      font-size: 14px;

      svg {
        margin-right: 10px;
      }
    }
  }

  .date {
    margin-right: 10px;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.2px;
    color: ${({theme}) => theme.layout.nonActiveColor};
  }
`;


const StyledCalls = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  
  ${Call} {
    margin-bottom: 10px;

    &:after {
      content: '';
      width: 100%;
      position: absolute;
      margin-left: 59px;
      bottom: 0;
      border-bottom: 1px solid ${({theme}) => theme.layout.borderColor};
    }
  }
`;

function Calls({contacts}: any) {
    const [history, setHistory] = useState([]);

    useEffect(()=>{
        axios(`${api}/user/history`).then(({data})=>{
            setHistory(data);
        }).catch(({response: {data}})=>{
            message.error(data);
        })
    }, [])



    return (
        <StyledCalls>
            {history.map((el:any, index: number)=>(
                <Call onClick={()=>callToUser(el.UserId, el.Type)} key={index} className={el.Status === "ok" ? "" : "missed"}>
                    <div className="avatar">
                        <img
                            src={contacts.filter((el_: any) => el_.Id === el.UserId)[0]?.Photo || noava}
                            alt=""/>
                    </div>
                    <div className="content">
                        <div className="name">
                            {contacts.filter((el_: any) => el_.Id === el.UserId)[0]?.Name || contacts.filter((el_: any) => el_.Id === el.UserId)[0]?.Login}
                        </div>
                        <div className="event">
                            {el.Type === "video" ? <Capture/> : <Phone/>}{el.Status === "ok" ? el.Direction === "out" ? "Outgoing" : "Incoming" : "Missed"}
                        </div>
                    </div>
                    <div className="date">
                        {moment(el.Time).format("DD/MM/YY HH:mm")}
                    </div>
                    <Info onClick={() => {
                    }}/>
                </Call>
            ))}
        </StyledCalls>
    );
}
const mapStateToProps = (state: any) => {
    return {
        contacts: state.pageReducer.contacts,
    }
}
export default connect(mapStateToProps, null)(Calls);