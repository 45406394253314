import React from 'react';
import styled, {css} from "styled-components";
import Lock from "../styles/images/Lock";
import Button from "../components/Button";
import {api, localCrypterIp, localCrypterIp_IOS} from "../config";
import NavBar, {StyledNavBar} from "../components/NavBar";
import {Link} from 'react-router-dom';
import EncryptSvg from "../styles/images/EncryptSvg";
import {connect} from "react-redux";
import DesktopMenu, {StyledDesktopMenu} from "../components/DesktopMenu";
import {detectIOS} from "../utils/detectIOS";
import {Modal} from "antd";
import LogoutModal from "../components/LogoutModal";
import {StyledApp} from "../App";


const Desktop = styled.div`

`;

const Title = styled.div`
  display: none;
  color: #4f4f4f;
  font-size: 42px;
  font-weight: bold;
  margin-bottom: 60px;
  margin-top: 60px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: hidden;
`;

const Mobile = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 1 1 auto;

`;

const StyledLayout = styled.div(({theme: {svg, layout}}) => css`
  display: flex;
  width: 100%;
  padding: 20px 0 80px 23px;
  background: ${layout.background};

  /*Prevent Address-Bar hiding in mobile Browsers https://stackoverflow.com/questions/18061308/prevent-address-bar-hiding-in-mobile-browsers*/
  position: fixed;

  height: 100%;

  &.scrollable {
    ${Content} {
      overflow: auto;
    }
  }

  ${Mobile} {
    > .header {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      padding-right: 23px;

      .title {
        margin-right: auto;
        font-size: 17px;
        color: ${({theme}) => theme.layout.textColor};
      }

      svg {
        margin-right: 15px;
        margin-top: 2px;
      }
    }


    .options {
      margin-right: 10px;
      display: flex;
      align-items: center;

      svg {
        // transform: rotateZ(-90deg);
        width: 25px;
        height: 25px;
        margin-right: 0;
        fill: ${svg.color4};
      }
    }
  }


  @media (min-width: 1100px) {
    padding: 0;

    ${Mobile} {
      .header {
        display: none;
      }
    }

    ${Desktop} {

    }

    ${Title} {
      display: block;
    }

    ${Content} {
      padding-left: 80px;
    }

    ${StyledDesktopMenu} {
      display: flex;
    }

    ${StyledNavBar} {
      display: none;
    }
  }
`);

type LayoutProps = {
    encryption?: boolean,
    children: any,
    hideOptions?: boolean,
    className?: any,
    title: any
}

function Layout({encryption, children, hideOptions, title, className = ""}: LayoutProps) {

    const logoutModal = () => {
        let modal = new (Modal as any).confirm({
            content: <LogoutModal onCancel={() => modal.destroy()}
                                  onOk={() => window.location.href = encryption ? `${detectIOS() ? localCrypterIp_IOS : localCrypterIp}/logout` : `${api}/exit`}/>,
            icon: null,
            className: "callModal",
            getContainer: () => document.querySelector(`.${StyledApp.styledComponentId}`),
            bodyStyle: {padding: "0"},
            footer: null,
            closable: false
        })
    }

    return (
        <StyledLayout className={className}>
            <Desktop>
                <DesktopMenu/>
            </Desktop>
            <Mobile>
                <div className="header">
                    <div className="title">{title}</div>
                    {encryption && <Lock/>}
                    {!hideOptions && <React.Fragment>
                        <Link className="options" to={`/options`}>
                            <EncryptSvg/>
                        </Link>
                    </React.Fragment>}
                    {/*<Button.Link*/}
                    {/*    href={encryption ? `${detectIOS() ? localCrypterIp_IOS : localCrypterIp}/logout` : `${api}/exit`}>Log*/}
                    {/*    out</Button.Link>*/}
                    <Button onClick={logoutModal}>Log out</Button>
                </div>
                <Content>
                    <Title>{title}</Title>
                    {children}
                </Content>
                <NavBar/>
            </Mobile>
        </StyledLayout>
    );
}

const mapStateToProps = (state: any) => {
    return {
        encryption: state.pageReducer.encryption,
        hideOptions: state.pageReducer.hideOptions
    }
}

export default connect(mapStateToProps, null)(Layout);