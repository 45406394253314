import React from 'react';
import styled, {css} from "styled-components";

const StyledRoundRoundedPlusSvg = styled.svg(({theme: {svg}}) => css`
  path {
    fill: ${svg.color1};
  }
`);

function RoundRoundedPlusSvg(props:any) {
    return (
        <StyledRoundRoundedPlusSvg {...props} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM9 15C8.44771 15 8 14.5523 8 14V10H4C3.44772 10 3 9.55229 3 9C3 8.44771 3.44772 8 4 8H8V4C8 3.44772 8.44771 3 9 3C9.55228 3 10 3.44772 10 4V8H14C14.5523 8 15 8.44771 15 9C15 9.55229 14.5523 10 14 10H10V14C10 14.5523 9.55229 15 9 15Z"
                  fill="#4F86EC"/>
        </StyledRoundRoundedPlusSvg>
    );
}

export default RoundRoundedPlusSvg;