import React from 'react';
import styled, {css} from "styled-components";
import {Link} from "react-router-dom";
import ChatsSvg from "../styles/images/ChatsSvg";
import CallsSvg from "../styles/images/CallsSvg";
import ContactsSvg from "../styles/images/ContactsSvg";
import ProfileSvg from "../styles/images/ProfileSvg";
import history from "../utils/history";
import AdminSvg from "../styles/images/AdminSvg";
import {connect} from "react-redux";

const NavItem = styled(Link)(({theme: {layout, menu}})=>css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: ${menu.nonActiveColor};
  letter-spacing: -0.3px;
  transition: none;
  user-select: none;

  svg {
    path {
      fill: ${menu.nonActiveColor};
    }
  }
  
  &.active, &:active, &:hover {
    color: ${layout.activeColor};

    svg {
      path {
        fill: ${layout.activeColor};
      }
    }
  }
`);

export const StyledNavBar = styled.div`
  display: flex;
  align-items: center;
  background: ${({theme}) => theme.layout.mainColor};
  height: 80px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
`;

function NavBar({userData, ...props}: any) {
    return (
        <StyledNavBar {...props}>
            {userData.Role !== 90 && <React.Fragment>
                <NavItem to={"/"} className={history.location.pathname === "/" ? "active" : ""}>
                    <ChatsSvg/>
                    Chats
                </NavItem>
                <NavItem to={"/calls"} className={history.location.pathname === "/calls" ? "active" : ""}>
                    <CallsSvg/>
                    Calls
                </NavItem>
                <NavItem to={"/contacts"} className={history.location.pathname === "/contacts" ? "active" : ""}>
                    <ContactsSvg/>
                    Contacts
                </NavItem>
                <NavItem to={"/profile"} className={history.location.pathname === "/profile" ? "active" : ""}>
                    <ProfileSvg/>
                    Your Profile
                </NavItem>
            </React.Fragment>}
            {userData.Role === 90 &&
            <NavItem to={"/users"} className={history.location.pathname === "/users" ? "active" : ""}>
                <AdminSvg/>
                Admin
            </NavItem>}
        </StyledNavBar>
    );
}

const mapStateToProps = (state: any) => {
    return {
        userData: state.loginReducer.userData
    }
}

export default connect(mapStateToProps, null)(NavBar);