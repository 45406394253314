import React from 'react';

function ExitSvg(props: any) {
    return (
        <svg {...props} width="14" height="22" viewBox="0 0 14 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M3.37905 1.66859L7.06858 1.08881C10.2892 0.582717 11.8995 0.329671 12.9497 1.22779C14 2.12591 14 3.75596 14 7.01607V9.99959H8.08062L10.7809 6.62428L9.21913 5.37489L5.21913 10.3749L4.71938 10.9996L5.21913 11.6243L9.21913 16.6243L10.7809 15.3749L8.08062 11.9996H14V14.9831C14 18.2432 14 19.8733 12.9497 20.7714C11.8995 21.6695 10.2892 21.4165 7.06857 20.9104L3.37905 20.3306C1.76632 20.0771 0.95995 19.9504 0.479975 19.3891C0 18.8279 0 18.0116 0 16.3791V5.6201C0 3.98758 0 3.17132 0.479975 2.61003C0.95995 2.04874 1.76632 1.92202 3.37905 1.66859Z"
                  fill="#F82020"/>
        </svg>
    );
}

export default ExitSvg;