import React from 'react';
import styled from "styled-components";

const StyledRestartApp = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 24px;
  flex-wrap: wrap;
  text-align: center;
  font-weight: bold;
`;

function RestartApp() {
    return (
        <StyledRestartApp>
            Network was changed.<br/>Please restart application.
        </StyledRestartApp>
    );
}

export default RestartApp;