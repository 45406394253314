import React from 'react';
import styled, {css} from "styled-components";

const StyledAttachment = styled.svg(({theme: {svg}}) => css`
  path {
    fill: ${svg.color1};
  }
`);

function Attachment(props: any) {
    return (
        <StyledAttachment {...props} width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.83201 19.344C2.87201 19.344 1.84801 18.96 1.14401 18.192C-0.327991 16.72 -0.327991 14.288 1.14401 12.752L12.024 1.87203C14.008 -0.111967 17.272 -0.111967 19.256 1.87203C21.24 3.85603 21.24 7.12003 19.256 9.10403L11.064 17.296L10.168 16.4L18.296 8.27203C19.768 6.80003 19.768 4.36803 18.296 2.83203C16.824 1.36003 14.392 1.36003 12.856 2.83203L1.97601 13.712C1.46401 14.224 1.20801 14.864 1.20801 15.504C1.20801 16.208 1.46401 16.848 1.97601 17.296C3.00001 18.32 4.60001 18.32 5.62401 17.296L13.752 9.16803C14.264 8.65603 14.264 7.88803 13.752 7.37603C13.24 6.86403 12.472 6.86403 11.96 7.37603L6.52001 12.752L5.62401 11.856L11.064 6.41603C12.088 5.39203 13.688 5.39203 14.712 6.41603C15.736 7.44003 15.736 9.04003 14.712 10.064L6.52001 18.192C5.81601 18.96 4.79201 19.344 3.83201 19.344Z"
                fill="#4F86EC"/>
        </StyledAttachment>
    );
}

export default Attachment;