import React from 'react';
import styled, {css} from "styled-components";

const StyledKeySvg = styled.svg(({theme: {svg}}) => css`
  path, circle {
    stroke: ${svg.color4};
  }
`);

function KeySvg(props: any) {
    return (
        <StyledKeySvg {...props} width="34" height="34" viewBox="0 0 34 34" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
            <circle cx="12.7497" cy="19.8333" r="5.66667" stroke="#03134C" strokeWidth="2.83333"/>
            <path d="M17 15.5833L21.9583 10.625M24.0833 8.5L21.9583 10.625M21.9583 10.625L25.5 14.1667" stroke="#03134C"
                  strokeWidth="2.83333" strokeLinecap="round"/>
        </StyledKeySvg>
    );
}

export default KeySvg;