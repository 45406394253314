import React, {useEffect, useRef, useState} from 'react';
import styled, {css} from "styled-components";
import arrowRight from "../styles/images/arrowRight.svg";
import defender_template from "../styles/images/DefenderTemplate.svg";
import noava from "../styles/images/noava.png";
import {connect} from "react-redux";
import history from "../utils/history";
import {Dropdown, Menu, message, Modal} from "antd";
import TrashSvg from "../styles/images/TrashSvg";
import {api} from "../config";
import axios from "axios";
import store from "../redux/store";
import {getChats} from "../redux/actions/pageActions";
import RoundPlusSvg from "../styles/images/RoundPlusSvg";
import {genBeautifulDate} from '../utils/genBeautifulDate';
import CreateChatModal from "../components/CreateChatModal";
import {SwipeAction} from "antd-mobile";

const StyledMenu = styled(Menu)`
  background: none;
  box-shadow: none;
  font-size: 14px;

  .ant-dropdown-menu-item {
    background: #ff4d4f;
    color: white;
    border-radius: 7px;
    font-size: 18px;
    padding: 10px;

    svg {
      height: 20px;
      width: 20px;
    }
  }
`;

const StyledSwipeTrashSvg = styled(TrashSvg)`
  width: 25px;
  height: 30px;

  path {
    fill: white;
  }
`;
const StyledTrashSvg = styled(TrashSvg)`
  path {
    fill: white;
  }
`;

const Chat = styled.div`
  display: flex;
  position: relative;
  padding-right: 23px;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;

  .avatar {
    margin-right: 5px;

    img {
      height: 56px;
      width: 56px;
      border-radius: 100%;
      object-fit: cover;
    }
  }

  .content {
    padding-left: 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-right: 15px;
    padding-bottom: 12px;

    .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      .name {
        font-size: 20px;
        line-height: 23px;

        &.red {
          color: #ff5252;
        }
      }

      .time {
        font-size: 14px;
        line-height: 16px;
        color: ${({theme}) => theme.layout.highlightedTextColor};
        margin-left: 10px;
        display: flex;
        justify-content: flex-end;
      }
    }

    .body {
      color: ${({theme}) => theme.layout.nonActiveColor};
      font-size: 17px;
      line-height: 20px;
      overflow: hidden;
      max-width: 220px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .arrowRight {
    height: 16px;
    align-self: center;
  }
`;


const AddChat = styled.div(({theme: {chats}}) => css`
  position: fixed;
  bottom: 100px;
  right: 20px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${chats.plusBackground};
  border-radius: 100%;
  z-index: 9;

  svg {
    flex: 0 0 auto;
    width: 30px;
    height: 30px;
  }
`);

const StyledChats = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1 1 auto;
  position: relative;

  .adm-swipe-action {
    background: none;
  }

  .ant-modal-content {
    background: none;
    box-shadow: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  ${Chat} {
    margin-bottom: 13px;

    &:not(:last-child):after {
      content: '';
      width: 100%;
      position: absolute;
      margin-left: 61px;
      bottom: 0;
      border-bottom: 1px solid ${({theme}) => theme.layout.borderColor};
    }
  }
`;

function Chats({dialogs, userData, chats, contacts, ...props}: any) {

    const [modalVisible, setModalVisible] = useState(false);
    const [addGroupVisible, setAddGroupVisible] = useState(true);
    const [selectedUsers, setSelectedUsers] = useState<number[]>([]);

    const chatsRef = useRef<any>(null);

    useEffect(() => {
        store.dispatch(getChats());
        if (userData.Role === 90) {
            history.push("/dashboard");
        }
        // eslint-disable-next-line
    }, []);

    const createGroupChat = (users: number[]) => {
        if (users.length > 0) {
            axios.post(`${api}/chats`, {
                UserIds: users,
                Group: true
            }).then(() => {
                store.dispatch(getChats());
                setSelectedUsers([]);
                setModalVisible(false);
            })
        } else {
            message.warning("You must select at least one user!")
        }
    }

    const deleteChat = (chatId: number) => {
        axios.delete(`${api}/chats/${chatId}`).then(() => {
            message.warning("Chat successfully deleted");
            store.dispatch(getChats());
        })
    }

    const chatMenu = (chatId: number) => (
        <StyledMenu>
            <Menu.Item
                onClick={() => deleteChat(chatId)}
                icon={<StyledTrashSvg/>} danger key="1">Delete</Menu.Item>
        </StyledMenu>
    );

    return (
        <StyledChats {...props} ref={chatsRef} onScroll={() => {
            if (addGroupVisible && chatsRef.current?.scrollTop !== 0) {
                setAddGroupVisible(false);
            } else if (!addGroupVisible && chatsRef.current?.scrollTop === 0) {
                setAddGroupVisible(true);
            }
        }}>

            <Modal style={{background: "none", top: "50px"}}
                   getContainer={() => document.querySelector("." + StyledChats.styledComponentId)!} forceRender
                   visible={modalVisible} closable={false} footer={null}>

                <CreateChatModal
                    onRemove={(userId) => {
                        setSelectedUsers(selectedUsers.filter(el => el !== userId))
                    }}
                    selected={selectedUsers}
                    onCreate={() => createGroupChat(selectedUsers)}
                    onAdd={(userId) => {
                        setSelectedUsers([...selectedUsers, userId]);
                    }}
                    onCancel={() => setModalVisible(false)}
                    users={contacts}/>
            </Modal>

            {chats.map((chat: any, index: number) => {
                const Users = chat.User.filter((x: any) => x.Id !== userData.Id);

                return <Dropdown key={index} overlay={() => chatMenu(chat.Id)} trigger={['contextMenu']}>
                    <SwipeAction rightActions={[
                        {
                            key: 'delete',
                            text: <StyledSwipeTrashSvg/>,
                            color: 'danger',
                            onClick: async () => {
                                deleteChat(chat.Id)
                            },
                        },
                    ]}>
                        <Chat onClick={_ => history.push(`/dialog/${chat.Id}`)}>
                            <div className="avatar">
                                <img
                                    src={chat.Group ? defender_template : Users[0]?.Photo ? `/${Users[0].Photo}` : noava}
                                    alt=""/>
                            </div>
                            <div className="content">
                                <div className="header">
                                    <div className={`name ${!chat.Group && Users[0]?.State === false ? "red" : ""}`}>
                                        {!chat.Group ? (Users[0]?.Name || Users[0]?.Login) : (chat.Name || `Chat ${chat.Id}`)}
                                    </div>
                                    <div className="time">
                                        {genBeautifulDate(chat.LastMessageDate)}
                                    </div>
                                </div>
                                <div className="body">
                                    {dialogs[chat.Id] !== undefined && dialogs[chat.Id].length > 0 ? (dialogs[chat.Id][dialogs[chat.Id].length - 1].from === userData.Id ? "You: " : "") + (dialogs[chat.Id][dialogs[chat.Id].length - 1].message || dialogs[chat.Id][dialogs[chat.Id].length - 1].Type) : false}
                                </div>
                            </div>
                            <img className={"arrowRight"} src={arrowRight} alt=""/>
                        </Chat>
                    </SwipeAction>
                </Dropdown>
            })}
            {addGroupVisible && <AddChat onClick={() => setModalVisible(true)}>
                <RoundPlusSvg/>
            </AddChat>}
        </StyledChats>
    );
}

const mapStateToProps = (state: any) => {
    return {
        userData: state.loginReducer.userData,
        dialogs: state.pageReducer.dialogs,
        chats: state.pageReducer.chats,
        contacts: state.pageReducer.contacts
    }
}

export default connect(mapStateToProps, null)(Chats);
