const palette = {
    // color1: "green",
    color1: "#057477",
    // color2: "green",
    color2: "#FFFFFF",
    // color3: "green",
    color3: "#057477",
    // color4: "green",
    color4: "#868792",
    // color5: "green",
    color5: "#DADADA",
    // color6: "green",
    color6: "#bcd8d9",
    // color7: "green",
    color7: "#f47d7d",
    // color8: "green",
    color8: "#057477",
    // color9: "green",
    color9: "#057477",
    // color10: "green",
    color10: "#bdedee",
    // color11: "green",
    color11: "#057477",
    // color12: "green",
    color12: "#5ba4a6",
    // color13: "green",
    color13: "rgba(5,116,119,0.7)",
    // color14: "green",
    color14: "#7f87a4",
    // color15: "green",
    color15: "#1dc20f",
    // color16: "green",
    color16: "#e8f0ef",
    // color17: "green",
    color17: "#5ba4a6",
    // color18: "green",
    color18: "#ffc241",
    color19: "#317e3526",
}

export const theme = {
    logoutModal: {
        color: palette.color2
    },
    menu: {
        nonActiveColor: palette.color2
    },
    dashboard: {
        infoBackground: palette.color1,
        labelColor: palette.color14,
        textColor: palette.color2,
        redColor: palette.color7,
        greenColor: palette.color15,
        orangeColor: palette.color18,
    },
    modal: {
        background: palette.color13,
        color: palette.color2,
        buttonBackground: palette.color1
    },
    callModal: {
        background: palette.color17
    },
    svg: {
        color1: palette.color11,
        color2: palette.color12,
        color3: palette.color4,
        color4: palette.color1,
        color5: palette.color2,
        color6: palette.color19,
    },
    login: {
        buttonBackground: palette.color1,
        buttonColor: palette.color2,
        textColor: palette.color3,
        labelColor: palette.color4,
        borderColor: palette.color5,
    },
    layout: {
        background: palette.color16,
        buttonBackground: palette.color6,
        buttonBackground2: palette.color1,
        buttonColor2: palette.color2,
        textColor: palette.color3,
        redTextColor: palette.color7,
        highlightedTextColor: palette.color8,
        highlightedTextColor2: palette.color9,
        mainColor: palette.color1,
        nonActiveColor: palette.color4,
        activeColor: palette.color10,
        borderColor: palette.color5
    },
    desktopMenu: {
        background: palette.color1
    },
    chats: {
        plusBackground: palette.color1
    },
    dialogSettings: {
        headerColor: palette.color1,
        userColor: palette.color3,
        statusColor: palette.color9
    }
}