import React from 'react';
import styled, {css} from "styled-components";
import noava from "../styles/images/noava.png";
import DropCall from "../styles/images/DropCall";
import AcceptCall from "../styles/images/AcceptCall";
import DefenderModal from "./DefenderModal";

const Avatar = styled.img`
  height: 64px;
  width: 64px;
  border-radius: 100%;
  object-fit: cover;
  margin-right: 15px;
  @media (max-width: 480px) {
    height: 48px;
    width: 48px;
    margin-right: 10px;
  }
`;

const Name = styled.div(({theme: {layout}})=>css`
  font-size: 16px;
  font-weight: 500;
  color: ${layout.textColor};
`);

const Actions = styled.div`
  margin-left: auto;

  svg {
    cursor: pointer;
    @media (max-width: 480px) {
      height: 35px;
      width: 35px;
    }


    &:not(:last-child) {
      margin-right: 16px;
      @media (max-width: 480px) {
        margin-right: 10px;
      }
    }
  }
`;

const StyledCallDefenderModal = styled(DefenderModal)`
  display: flex;
  align-items: center;
`;

type CallModalContentProps = {
    user: any,
    onOk: () => void,
    onCancel: () => void,
}

function CallModalContent({user, onOk, onCancel}: CallModalContentProps) {
    return (
        <StyledCallDefenderModal>
            <Avatar src={user?.Photo ? `/${user.Photo}` : noava} alt="avatar"/>
            <Name>{(user?.Name || user?.Login) || "unknown"}</Name>
            <Actions>
                <DropCall onClick={onCancel}/>
                <AcceptCall onClick={onOk}/>
            </Actions>
        </StyledCallDefenderModal>
    );
}

export default CallModalContent;