import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {message as antmessage, Modal, Spin, Upload} from 'antd';
import styled, {css} from "styled-components";
import Capture from "../styles/images/Capture";
import Phone from "../styles/images/phone";
import BackButton from "../styles/images/BackButton";
import Attachment from "../styles/images/Attachment";
import Microphone from "../styles/images/Microphone";
import SendMessage from "../styles/images/SendMessage";
import {getWS} from "../utils/websocket";
import tail from "../styles/images/tail.svg";
import rightTail from "../styles/images/rightTail.svg";
import {connect} from "react-redux";
import {addHistoryMessage, addMessage} from "../utils/dialogUtils";
import store from "../redux/store";
import {changeICreator, setDialogsAction} from "../redux/actions/pageActions";
import {Link} from 'react-router-dom';
import noava from "../styles/images/noava.png";
import {callToUser, callToUsers} from "../utils/webrtc";
import audioWave from "../styles/images/audio-wave.gif"
import CloseSvg from "../styles/images/CloseSvg";
import axios from "axios";
import {api, localCrypterIp, localCrypterIp_IOS} from "../config";
import {detectIOS} from "../utils/detectIOS";
import {resizeByWidth} from "../utils/resizeByWidth";
import ExpandDownSvg from "../styles/images/ExpandDownSvg";
import history from "../utils/history";
import {PullToRefresh} from "antd-mobile";
import {PullStatus} from "antd-mobile/es/components/pull-to-refresh";
import * as vmsg from "vmsg";
import defender_template from "../styles/images/DefenderTemplate.svg";

const GroupName = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
`;
const UsersCount = styled.div`
  color: #666666;
  font-size: 14px;
  line-height: 16px;
`;

const StyledSpin = styled(Spin)`
  i {
    background-color: white;
  }
`;

const Message = styled.div`
  display: flex;
  width: fit-content;
  background: #FAFAFA;
  border-radius: 10px;
  padding: 5px 6px 6px 10px;
  min-width: 40%;
  max-width: 80%;
  box-shadow: -4px 4px 10px rgba(0, 0, 0, 0.05);
  position: relative;

  .tail {
    position: absolute;
    bottom: 5px;
    left: -9px;
    height: 20px;
  }

  &.my {
    background: #C6DDFF;
    align-self: flex-end;

    .tail {
      right: -9px;
      left: auto;
    }
  }

  .text {
    word-wrap: anywhere;
    overflow-wrap: anywhere;
    color: #000;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.3px;
    margin-right: auto;

    &.from_admin {
      color: #da5151;
    }

    img {
      max-width: 200px;
      max-height: 200px;
    }

    audio {
      max-width: 100%;
      height: 30px;
    }
  }

  .time {
    font-size: 11px;
    margin-left: 8px;
    line-height: 13px;
    letter-spacing: 0.5px;
    align-self: flex-end;
    color: #0000003F;
  }

  .status {
    display: flex;
    align-self: flex-end;
    margin-bottom: 2px;
  }
`;


const StyledModal = styled(Modal)`
  .ant-modal-content {
    background: none;
    box-shadow: none;
  }
`;
const RecordText = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  margin-bottom: 5px;
  font-size: 14px;
`;

const RedDot = styled.div`
  width: 18px;
  height: 18px;
  background: #ff5757;
  border-radius: 100%;
  margin-bottom: 7px;
`

const Avatar = styled.img`
  width: 32px;
  height: 32px;
  overflow: hidden;
  border-radius: 100%;
  object-fit: cover;

  &.transparent {
    opacity: 0;
  }
`;

const Name = styled.div`
  font-size: 12px;
  color: #3289c8;
`;

const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;
const MessageWrap = styled.div`
  display: flex;

  ${Avatar} {
    margin-right: 5px;
    transform: translate(5px, 0);
    align-self: end;
    position: relative;
    top: -18px;
    right: 2px;
  }

  &.my {
    justify-content: flex-start;
    flex-direction: row-reverse;
  }
`;

const MessageList = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  justify-content: flex-end;

  ${MessageWrap} {
    &:first-child {
      margin-top: 10px;
    }
  }
`;

const StyledDialog = styled.div(({theme: {layout}})=>css`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  position: fixed;
  top: 0;


  .adm-pull-to-refresh {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    .adm-pull-to-refresh-content {
      display: flex;
      flex: 1 1 auto;
    }
  }

  .header {
    display: flex;
    position: fixed;
    width: 100%;
    align-items: center;
    background: white;
    padding: 20px 18px 15px 13px;
    z-index: 999;

    .avatar {
      margin-left: 23px;
      margin-right: 20px;
      padding: 2px;

      border-radius: 100%;

      &.online {
        border: 2px solid #64b161;
      }


      img {
        height: 53px;
        width: 53px;
        border-radius: 100%;
        object-fit: cover;
      }

    }

    .info {
      display: flex;
      flex-direction: column;

      .name {
        color: ${({theme}) => theme.layout.mainColor};
        font-size: 20px;
        line-height: 23px;
        letter-spacing: -0.3px;
        display: flex;
        align-items: center;

        svg {
          margin-left: 5px;
          cursor: pointer;
        }
      }

      .status {
        font-size: 20px;
        line-height: 23px;
        letter-spacing: -0.3px;
        color: #666666;
      }
    }

    .actions {
      margin-left: auto;
      display: flex;

      svg {
        transform: scale(1.1);
        width: 20px;
        height: 20px;
        flex: 0 0 auto;
        cursor: pointer;

        &:not(:last-child) {
          margin-right: 30px;
        }
      }
    }
  }

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: 10px 13px 0 13px;
    background: ${layout.background};
    margin-top: 96px;
    overflow-y: auto;

    ${Message} {
      margin-bottom: 7px;
    }
  }

  .footer_wrap {
    flex: 0 0 auto;

    svg, img {
      user-select: none;
    }
  }

  .footer {
    display: flex;
    flex: 1 0 auto;
    position: fixed;
    bottom: 0;
    align-items: flex-end;
    padding: 11px 15px 11px 15px;
    background: #F6F6F6;
    width: 100%;
    max-height: 200px;
    min-height: 59px;
    border-top: 0.5px solid #C6DDFF;

    &.show_audio {
      .recorded_audio {
        display: flex;
      }

      .attachment, .textarea {
        display: none;
      }
    }

    .recorded_audio {
      width: 100%;
      display: none;
      align-items: center;
      margin-right: 20px;

      audio {
        width: 100%;
        height: 30px;
        border: 1px solid #C6DDFF;
        border-radius: 1000px;
        margin-right: 20px;
      }

      svg {
        top: 0;
        height: 20px;
        width: 20px;
        cursor: pointer;
      }
    }

    .attachment {
      transform: scale(1.2);
    }

    .send {
      display: flex;
      align-items: center;
      width: 30px;
      position: relative;
      user-select: none;
    }


    svg, img {
      user-select: none;
      cursor: pointer;

      &.transparent {
        opacity: 0;
      }
    }

    img {
      position: absolute;
      height: 25px;
      left: -5px;
      top: -5px;
    }

    svg {
      position: relative;
      height: 22px;
      bottom: 5px;
    }

    .ant-upload {
      svg {
        bottom: 0;
      }
    }

    .textarea {
      resize: none;
      border-radius: 15px;
      background: white;
      outline: none;
      padding: 10px;
      border: none;
      width: 100%;
      max-height: 100px;
      overflow-y: scroll;
      box-shadow: none;
      margin-left: 10px;
      margin-right: 12px;
      font-size: 16px;
      line-height: 16px;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
`);

const statusRecord: Record<PullStatus, any> = {
    pulling: '',
    canRelease: <Spin/>,
    refreshing: <Spin/>,
    complete: <Spin/>,
}

function Dialog({dialogs, userData, match, chats, encryption, contacts}: any) {
    const chatId = parseInt(match.params.chatId);
    const chat = chats.filter((x: any) => x.Id === chatId)[0]
    const Users = chat?.User.filter((x: any) => x.Id !== userData.Id)

    const [isRecording, setIsRecording] = useState(false);

    const [canSendAudio, setCanSendAudio] = useState(false);

    const lastBlobAudio = useRef<any>(null);

    const isFirstLoad = useRef<any>(true);
    const audio = useRef<any>(null);


    /*mp3 recorder*/
    const recorderRef = useRef<any>();

    useEffect(() => {
        /*initialization mp3 recorder with web assembly */
        recorderRef.current = new vmsg.Recorder({
            wasmURL: "/vmsg.wasm"
        });
    }, []);


    const getOldMessages = async () => {
        await axios(`${api}/chats/${chatId}/history`).then(async ({data}) => {

            let messages: any = Object.fromEntries(Object.entries(dialogs).filter(([key, value]: any) => key !== chatId.toString()));
            console.log("Object.fromEntries(Object.entries(dialogs).filter(([key, value]: any) => key !== chatId))", Object.fromEntries(Object.entries(dialogs).filter(([key, value]: any) => {
                console.log(key);
                console.log(value);
                console.log(chatId);
                return key !== chatId.toString()
            })));

            for (let message of data) {
                console.log("defMessage", messages);
                let test = await addHistoryMessage(messages, message.Data);
                console.log("message", message);
                console.log("test", test);
                messages = test
            }

            console.log("messages", messages);


            store.dispatch(setDialogsAction(messages))

            console.log(data);
        })
    }

    useEffect(() => {
        console.log("isRecording ", isRecording);
        if (isFirstLoad.current)
            return

        if (isRecording) {
            startRecord();
            window.navigator.vibrate?.(100);
        } else {
            if (recorderRef.current) {
                stopRecord();
                window.navigator.vibrate?.(100);
            }
        }
        // eslint-disable-next-line
    }, [isRecording]);

    useEffect(() => {
        isFirstLoad.current = false;

        const constraints = {audio: true};
        try {
            navigator.mediaDevices.getUserMedia(constraints).then((stream_) => {
                stream_.getTracks().forEach(function (track: any) {
                    track.stop();
                });
            }).catch(e => {
                console.log(e);
                antmessage.error("Cannot get audio");
            })
        } catch (e) {
            console.log(e);
            antmessage.error("Cannot get audio");
        }

        return () => {
            isFirstLoad.current = true
        }
    }, []);

    useEffect(() => {
        if (isFirstLoad.current)
            return
        if (!canSendAudio) {
            lastBlobAudio.current = null;
        }
    }, [canSendAudio]);


    const startRecord = async () => {
        try {
            await recorderRef.current.initAudio();
            await recorderRef.current.initWorker();
            recorderRef.current.startRecording();
        } catch (e: any) {
            console.log(e);
            antmessage.error("Cannot get audio");
            setIsRecording(false);
        }
    }

    const stopRecord = async () => {
        const blob = await recorderRef.current.stopRecording();
        console.log(blob);
        const audioURL = window.URL.createObjectURL(blob);
        audio.current.src = audioURL;
        setCanSendAudio(true);
        lastBlobAudio.current = blob;
    }

    const blobToBase64 = async (blob: any) => {
        return new Promise((res, rej) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob[0]);
            reader.onloadend = async () => {
                console.log(reader.result);
                return res(reader.result);
            }
        })
    }


    const handleMicrophoneStart = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();


        setIsRecording(true)

    }

    const handleMicrophoneEnd = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        if (isRecording) {
            setIsRecording(false)
            setCanSendAudio(true);
        }
    }


    const [message, setMessage] = useState("");
    const [fileLoading, setFileLoading] = useState(false);
    const chatRef = useRef<any>(null);

    const footerRef = useRef<HTMLDivElement>(null);
    const footerWrapRef = useRef<HTMLDivElement>(null);

    const local_encrypt = async (dataToEncrypt: any) => {
        let result = await axios.post(`${detectIOS() ? localCrypterIp_IOS : localCrypterIp}/encrypt`, dataToEncrypt);
        return result.data;
    }

    const sendMessage = async (file?: any) => {
        const [ws] = getWS();
        const imageTypes = [
            "image/apng",
            "image/bmp",
            "image/gif",
            "image/jpeg",
            "image/pjpeg",
            "image/png",
            "image/svg+xml",
            "image/tiff",
            "image/webp",
            "image/x-icon"
        ]

        if (ws.readyState === ws.OPEN) {
            if (file) {
                setFileLoading(true);
                console.log(file);
                if (imageTypes.includes(file.type)) {
                    blobToBase64([file]).then(async base64 => {


                        /*Convert to 900px width*/
                        await resizeByWidth(base64).then(async (res) => {
                            ws.send(JSON.stringify({
                                "Type": 2,
                                "Data": {
                                    "ChatId": chatId,
                                    Type: "image",
                                    "Name": file.name,
                                    "Body": encryption ? await local_encrypt(res) : res
                                }
                            }));
                            const newDialogs = await addMessage({
                                SourceId: userData.Id,
                                Name: file.name,
                                ChatId: chatId,
                                Body: res,
                                Type: "image"
                            }, true)
                            setFileLoading(false);
                            store.dispatch(setDialogsAction(newDialogs));
                        })

                    })
                } else {
                    blobToBase64([file]).then(async base64 => {
                        ws.send(JSON.stringify({
                            "Type": 2,
                            "Data": {
                                "ChatId": chatId,
                                Type: "file",
                                "Name": file.name,
                                "Body": encryption ? await local_encrypt(base64) : base64
                            }
                        }));
                        const newDialogs = await addMessage({
                            SourceId: userData.Id,
                            Name: file.name,
                            ChatId: chatId,
                            Body: base64,
                            Type: "file"
                        }, true);
                        setFileLoading(false);
                        store.dispatch(setDialogsAction(newDialogs));
                    })
                }
                return;
            }
            if (canSendAudio) {
                console.log(lastBlobAudio.current);
                blobToBase64([lastBlobAudio.current]).then(async base64 => {
                    ws.send(JSON.stringify({
                        "Type": 2,
                        "Data": {
                            "ChatId": chatId,
                            Type: "audio",
                            "Name": "audio",
                            "Body": encryption ? await local_encrypt(base64) : base64
                        }
                    }));
                    const newDialogs = await addMessage({
                        SourceId: userData.Id,
                        ChatId: chatId,
                        Body: base64,
                        Type: "audio"
                    }, true);
                    store.dispatch(setDialogsAction(newDialogs));
                    setCanSendAudio(false)
                })
            } else {
                let messageToSend = false;
                if (encryption) {
                    messageToSend = await local_encrypt(message);
                    console.log(messageToSend);
                } else {
                    messageToSend = false;
                }

                ws.send(JSON.stringify({"Type": 1, "Data": {"ChatId": chatId, "Message": messageToSend || message}}));
                let textarea = document.querySelector(".textarea") as HTMLElement;
                textarea.innerText = "";
                textarea?.focus();
                const newDialogs = await addMessage({SourceId: userData.Id, ChatId: chatId, Message: message}, true);
                store.dispatch(setDialogsAction(newDialogs));
                setMessage("");
            }
        } else {
            antmessage.error("Server error");
        }
    }

    useLayoutEffect(() => {
        if (footerRef.current !== null && footerWrapRef.current !== null) {
            footerWrapRef.current.style.height = footerRef.current.offsetHeight + "px";
            // chatRef.current.style.marginBottom = footerRef.current.offsetHeight + "px";
        }
    }, [dialogs, message]);


    useEffect(() => {
        if (chatRef.current !== null) {
            chatRef.current.scrollTo(0, chatRef.current.scrollHeight);
        }
    }, [dialogs])

    if (chats.length > 0)
        return <StyledDialog>
            <StyledModal visible={fileLoading} footer={false} closable={false} centered={true}>
                <StyledSpin size={"large"} style={{margin: "0 auto", display: "block"}}/>
            </StyledModal>
            <div className="header">
                <div className="back">
                    <Link to={"/"}><BackButton/></Link>
                </div>
                <div className={`avatar ${chat.Group ? "" : Users?.[0]?.Online ? "online" : ""}`}>
                    <img src={chat.Group ? defender_template : Users?.[0]?.Photo ? `/${Users[0]?.Photo}` : noava}
                         alt=""/>
                </div>
                <div className="info">
                    <div className="name">
                        {!chat.Group ? (Users?.[0]?.Name || Users?.[0]?.Login) :
                            <GroupName>{chat.Name || `Chat ${chat.Id}`}</GroupName>}
                        {chat.Group && <ExpandDownSvg onClick={() => history.push(`/dialog/${chatId}/settings`)}/>}
                    </div>
                    <div className="status">
                        {chat.Group ?
                            <UsersCount>{chat?.User.length} users</UsersCount> : (Users?.[0]?.Online ? "Online" : "Offline")}
                    </div>
                </div>
                <div className="actions">
                    <Capture onClick={() => {
                        /*Устанавливаем что мы являемся создателем конференции*/
                        store.dispatch(changeICreator(true));

                        if (chat.Group) {
                            callToUsers(chat.Id, Users, "video")
                        } else {
                            callToUser(Users?.[0]?.Id, "video")
                        }
                    }}/>
                    <Phone onClick={() => {
                        /*Устанавливаем что мы являемся создателем конференции*/
                        store.dispatch(changeICreator(true));
                        if (chat.Group) {
                            callToUsers(chat.Id, Users, "audio")
                        } else {
                            callToUser(Users?.[0]?.Id, "audio")
                        }
                    }}/>
                </div>
            </div>
            <div className="content" ref={chatRef}>
                <PullToRefresh
                    renderText={status => {
                        return <div>{statusRecord[status]}</div>
                    }}
                    onRefresh={async () => {
                        await getOldMessages()
                    }}>
                    <MessageList>
                        {dialogs[chatId]?.map((el: any, index: number) => {
                            const authorRepeating: boolean = dialogs[chatId][index - 1]?.from === el.from;
                            const author = contacts.find((contact: any) => contact.Id === el.from);
                            const isGroupChat: boolean = chat.Group;

                            return <MessageWrap className={userData.Id === el.from ? "my" : ""}>
                                {(isGroupChat && userData.Id !== el.from) && <Avatar src={noava}
                                                                                     className={authorRepeating ? "transparent" : ""}
                                                                                     alt="avatar"/>}
                                <Message key={index} className={userData.Id === el.from ? "my" : ""}>
                                    <img className="tail" src={userData.Id === el.from ? rightTail : tail} alt=""/>
                                    <TextWrap>
                                        {isGroupChat && !authorRepeating && <Name>
                                            {author?.Name || author?.Login}
                                        </Name>}
                                        <div className={`text ${el.from === undefined ? "from_admin" : ""}`}>
                                            {(() => {
                                                switch (el.Type) {
                                                    case "audio":
                                                        return <audio controls controlsList="nodownload"
                                                                      src={el.Body}/>;
                                                    case "image":
                                                        return <img src={el.Body} alt={el.Name}/>;
                                                    case "file":
                                                        return <a href={el.Body} download={el.Name}>{el.Name}</a>;
                                                    default:
                                                        return el.message;
                                                }
                                            })()}
                                        </div>
                                    </TextWrap>
                                    <div className="time">
                                        {el.time.format("H:mm")}
                                    </div>
                                    {/*<div className="status">*/}
                                    {/*    <Readed/>*/}
                                    {/*</div>*/}
                                </Message>
                            </MessageWrap>
                        })}
                    </MessageList>
                </PullToRefresh>
            </div>
            <div ref={footerWrapRef} className={"footer_wrap"}>
                <div ref={footerRef} className={`${canSendAudio ? "show_audio " : ""}footer`}>
                    <div className="recorded_audio">
                        <audio ref={audio} controls controlsList="nodownload" src=""/>
                        <CloseSvg onClick={() => setCanSendAudio(false)}/>
                    </div>
                    {isRecording ? <RedDot/> : <Upload showUploadList={false} customRequest={(_) => {
                        console.log(_);
                        console.log(_.file);
                        sendMessage(_.file)
                    }}>
                        <Attachment onClick={() => window.navigator.vibrate?.(100)} className="attachment"/>
                    </Upload>}
                    {isRecording ? <RecordText>Audio recording in progress</RecordText> : <div onKeyPress={e => {
                        if (e.code === "Enter" && !e.shiftKey) {
                            e.preventDefault();
                            sendMessage()
                        }
                    }} className="textarea" onInput={(e: any) => setMessage(e.target.innerText)}
                                                                                               onChange={console.log}
                                                                                               contentEditable={true}/>}
                    <div className="send">
                        <img className={!isRecording ? "transparent" : ""} src={audioWave} alt=""/>

                        {message || canSendAudio ? <SendMessage onClick={() => sendMessage()}/> :
                            <Microphone
                                className={isRecording ? "transparent" : ""}
                                onClick={(e: any) => {
                                    if (isRecording) {
                                        handleMicrophoneEnd(e)
                                    } else {
                                        handleMicrophoneStart(e)
                                    }
                                }}
                            />
                        }
                    </div>
                </div>
            </div>
        </StyledDialog>

    return (
        <div>Loading</div>
    );
}

const mapStateToProps = (state: any) => {
    return {
        userData: state.loginReducer.userData,
        dialogs: state.pageReducer.dialogs,
        chats: state.pageReducer.chats,
        contacts: state.pageReducer.contacts,
        encryption: state.pageReducer.encryption
    }
}
export default connect(mapStateToProps, null)(Dialog);