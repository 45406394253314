import store from "../redux/store";
import moment from "moment";
import {base64ToBlob} from "./base64ToBlob";
import axios from "axios";
import {localCrypterIp, localCrypterIp_IOS} from "../config";
import {detectIOS} from "./detectIOS";

async function local_decrypt(dataToDecrypt) {
    let result = await axios.post(`${detectIOS() ? localCrypterIp_IOS : localCrypterIp}/decrypt`, dataToDecrypt);
    return result.data;
}

export async function addMessage(raw, doNotDecrypt) {
    let newDialogs;
    console.log("raw", raw);
    let {SourceId, ChatId, Message, Body, Type, Name} = raw;

    const encryption = store.getState().pageReducer.encryption;

    if (encryption && doNotDecrypt !== true) {
        if (Body && Type) {
            Body = await local_decrypt(Body);
        } else {
            Message = await local_decrypt(Message);
        }
    }
    const dialogs = store.getState().pageReducer.dialogs;


    let new_body;
    if (Body && Type) {

        switch (Type) {
            case "audio": {
                new_body = window.URL.createObjectURL(new Blob([base64ToBlob(Body)], {'type': 'audio/mp3'}));
                break;
            }
            case "image":
            case "file": {
                new_body = window.URL.createObjectURL(new Blob([base64ToBlob(Body)]));
                break
            }
            default:
                return;
        }
    }
    let new_message = {
        message: Message,
        from: SourceId,
        time: moment(),
        Body: new_body,
        Type: Type,
        Name: Name
    }
    if (dialogs[ChatId] !== undefined) {
        newDialogs = {
            ...dialogs, [ChatId]: [...dialogs[ChatId], new_message]
        }
    } else {
        newDialogs = {
            ...dialogs, [ChatId]: [new_message]
        }
    }
    return newDialogs;
}

export async function addHistoryMessage(old, raw, doNotDecrypt) {
    let newDialogs;
    console.log("raw", raw);
    let {SourceId, ChatId, Message, Body, Type, Name, Time} = raw;

    const dialogs = old;
    const encryption = store.getState().pageReducer.encryption;

    if (encryption && doNotDecrypt !== true) {
        if (Body && Type) {
            Body = await local_decrypt(Body);
        } else {
            Message = await local_decrypt(Message);
        }
    }

    let new_body;
    if (Body && Type) {

        switch (Type) {
            case "audio": {
                new_body = window.URL.createObjectURL(new Blob([base64ToBlob(Body)], {'type': 'audio/mp3'}));
                break;
            }
            case "image":
            case "file": {
                new_body = window.URL.createObjectURL(new Blob([base64ToBlob(Body)]));
                break
            }
            default:
                return;
        }
    }
    let new_message = {
        message: Message,
        from: SourceId,
        time: moment(Time),
        Body: new_body,
        Type: Type,
        Name: Name
    }
    if (dialogs[ChatId] !== undefined) {
        newDialogs = {
            ...dialogs, [ChatId]: [...dialogs[ChatId], new_message]
        }
    } else {
        newDialogs = {
            ...dialogs, [ChatId]: [new_message]
        }
    }
    return newDialogs;
}
