import React from 'react';
import styled, {css} from "styled-components";

const StyledGroupSvg = styled.svg(({theme: {svg}}) => css`
  path {
    fill: ${svg.color4};
  }
`);

function GroupSvg(props: any) {
    return (
        <StyledGroupSvg {...props} version="1.1" xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px" y="0px"
                        fill={"#E9ECF7"}
                        viewBox="0 0 28 28" xmlSpace="preserve">
            <g>
                <path
                    d="m 18.008502,15.434208 c -1.741631,10e-7 -3.456411,0.381977 -4.936504,1.111318 -0.05021,0.02474 -0.100157,0.04989 -0.149822,0.07543 1.544679,0.126855 2.550522,0.438237 3.812822,0.926018 0.414201,-0.05298 0.826142,-0.06979 1.273504,-0.06979 1.63674,0 3.207046,0.407028 4.464844,1.13086 1.258411,0.724181 2.097923,1.699764 2.466797,2.732421 0.189777,0.531167 0.773522,0.808918 1.304687,0.619141 0.531169,-0.189777 0.806965,-0.775475 0.617188,-1.306641 -0.561978,-1.573102 -1.773893,-2.898334 -3.369141,-3.816406 -1.595948,-0.918423 -3.524104,-1.402345 -5.484375,-1.402344 z"/>
                <path
                    d="m 18.010455,3.1803019 c -0.937547,1e-7 -1.81634,0.2520143 -2.571621,0.6926234 0.784156,0.6033444 0.947223,0.7843058 1.562183,1.5195993 0.316097,-0.1107713 0.655787,-0.1712068 1.009437,-0.1712068 1.692062,0 3.0625,1.3724001 3.0625,3.0644531 0,1.6920358 -1.370438,3.0644531 -3.0625,3.0644531 -0.05434,0 -0.108353,-0.0014 -0.16199,-0.0042 -0.262019,0.730064 -0.484752,1.239029 -0.980052,1.9179 0.367146,0.08442 0.749366,0.129285 1.14205,0.129285 2.820132,0 5.105469,-2.287316 5.105469,-5.1074221 0,-2.8200973 -2.285337,-5.1054688 -5.105469,-5.1054688 z"/>
                <path
                    d="m 5.1072004,19.090671 c 1.82315,-1.0492 4.02432,-1.602 6.2637186,-1.602 2.2394,0 4.4405,0.5528 6.2637,1.602 1.8224,1.0488 3.2082,2.5633 3.8502,4.3604 0.2168,0.6068 -0.0993,1.2744 -0.7061,1.4912 -0.6068,0.2168 -1.2744,-0.0994 -1.4912,-0.7062 -0.4214,-1.1797 -1.3792,-2.2957 -2.8168,-3.123 -1.4369,-0.8269 -3.23,-1.2911 -5.0998,-1.2911 -1.8697986,0 -3.6629986,0.4642 -5.0998686,1.2911 -1.43755,0.8273 -2.39533,1.9433 -2.81681,3.123 -0.21678,0.6068 -0.88439,0.923 -1.49117,0.7062 -0.60677,-0.2168 -0.92292,-0.8844 -0.70614,-1.4912 0.64206,-1.7971 2.0278,-3.3116 3.85027,-4.3604 z"/>
                <path
                    d="m 11.371519,5.8220003 c -1.9329986,0 -3.4999986,1.56701 -3.4999986,3.5000004 0,1.9329703 1.567,3.4999703 3.4999986,3.4999703 1.933,0 3.5,-1.567 3.5,-3.4999703 0,-1.9329904 -1.567,-3.5000004 -3.5,-3.5000004 z M 5.5382104,9.3220007 c 0,-3.2216604 2.61171,-5.8333304 5.8333086,-5.8333304 3.2217,0 5.8334,2.61167 5.8334,5.8333304 0,3.2216703 -2.6117,5.8333703 -5.8334,5.8333703 -3.2215986,0 -5.8333086,-2.6117 -5.8333086,-5.8333703 z"/>
            </g>
        </StyledGroupSvg>
    );
}

export default GroupSvg;