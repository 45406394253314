import React, {useState} from 'react';
import styled, {css} from "styled-components";
import CloseModalSvg from "../styles/images/CloseModalSVG";
import noava from "../styles/images/noava.png";
import {Input} from "antd";
import ModalSearchSvg from "../styles/images/ModalSearchSVG";
import ModalAddButtonSvg from "../styles/images/ModalAddButtonSVG";

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    cursor: pointer;
  }
`;
export const Title = styled.div`
  font-size: 17px;
`;
export const User = styled.div`
  display: flex;
  align-items: center;
`;
export const Content = styled.div`
  max-height: 600px;
  overflow-y: auto;
  margin-right: -20px;
  padding-right: 20px;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(240, 240, 255, 0.2);
    border-radius: 2px;
  }

  ${User} {
    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }
`;
export const Left = styled.div`

`;
export const Avatar = styled.img`
  height: 64px;
  width: 64px;
  border-radius: 100%;
  object-fit: cover;
  margin-right: 15px;
`;
export const Right = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
export const Info = styled.div`

`;
export const Name = styled.div`
  font-size: 20px;
`;
export const Status = styled.div`
  font-size: 17px;
  color: #868792;
`;
export const Add = styled.div`
  svg {
    cursor: pointer;
  }
`;
export const Search = styled.div`
  margin: 30px 0;
`;
export const StyledSearchInput = styled(Input)`
  background: none;
  border: none !important;
  outline: none;
  box-shadow: none !important;
  background: rgba(255, 255, 255, 0.12);
  border-radius: 10px;

  input {
    color: white;
    font-size: 17px;
    background: none;
  }
`;

export const StyledAddUserModal = styled.div(({theme: {modal}}) => css`
  max-height: 55vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: ${modal.background};
  color: ${modal.color};
  border-radius: 24px;
  backdrop-filter: blur(16px);
  padding: 30px 25px;
`);

type AddUserModalProps = {
    users: any[],
    onCancel: () => void,
    onAdd: (user: any) => void
}

function AddUserModal({users, onCancel, onAdd}: AddUserModalProps) {

    const [searchValue, setSearchValue] = useState<any>("");

    return (
        <StyledAddUserModal>
            <Top>
                <Title>Select user</Title> <CloseModalSvg onClick={onCancel}/>
            </Top>
            <Search>
                <StyledSearchInput onChange={({currentTarget: {value}}) => setSearchValue(value)}
                                   prefix={<ModalSearchSvg/>} placeholder={"Search"}/>
            </Search>
            <Content>
                {users.filter(user => {
                    return (user.Login.includes(searchValue) || user.Name.includes(searchValue))
                }).length ? users.filter(user => {
                    return (user.Login.includes(searchValue) || user.Name.includes(searchValue))
                }).map((user, index) => (
                    <User key={index}>
                        <Left>
                            <Avatar src={user.Photo ? "/" + user.Photo : noava} alt="avatar"/>
                        </Left>
                        <Right>
                            <Info>
                                <Name>{user.Name || user.Login}</Name>
                                <Status>{user.Online ? "Online" : "Offline"}</Status>
                            </Info>
                            <Add>
                                <ModalAddButtonSvg onClick={() => onAdd(user.Id)}/>
                            </Add>
                        </Right>
                    </User>
                )) : "No one was found"}
            </Content>
        </StyledAddUserModal>
    );
}

export default AddUserModal;