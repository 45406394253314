import React from 'react';

function AcceptCall(props: any) {
    return (
        <svg {...props} width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="42" width="42" height="42" rx="10" transform="rotate(-90 0 42)" fill="#64B161"/>
            <path
                d="M27.6503 25.4808L25.2734 24.5437C24.7043 24.318 24.062 24.3655 23.5337 24.6705L21.282 25.9705C18.9233 23.8451 17.2797 21.0182 16.622 17.8992L18.8824 16.5942C19.4106 16.2892 19.7729 15.7567 19.862 15.151L20.2353 12.6376C20.3809 11.6298 19.759 10.6725 18.7754 10.409L17.1089 9.96244C16.0161 9.66962 14.8642 10.3346 14.6397 11.4457C12.943 19.827 17.773 28.1928 25.8797 30.914C26.9542 31.2751 28.1061 30.6101 28.3989 29.5173L28.8454 27.8507C29.114 26.8759 28.5959 25.8586 27.6503 25.4808Z"
                fill="white"/>
        </svg>
    );
}

export default AcceptCall;