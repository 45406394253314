import React from 'react';

function SendMessage(props: any) {
    return (
        <svg {...props} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="M481.508,210.335L68.414,38.926c-17.403-7.222-37.063-4.045-51.309,8.287C2.859,59.547-3.099,78.55,1.557,96.808
			L42.151,256L1.557,415.192c-4.656,18.258,1.301,37.261,15.547,49.595c14.273,12.358,33.938,15.495,51.31,8.287l413.094-171.409
			C500.316,293.861,512,276.363,512,256C512,235.637,500.316,218.139,481.508,210.335z M470.009,273.955L56.916,445.364
			c-6.947,2.881-14.488,1.665-20.175-3.259c-5.686-4.923-7.971-12.212-6.113-19.501L69.287,271h149.065
			c8.285,0,15.001-6.716,15.001-15.001c0-8.285-6.716-15.001-15.001-15.001H69.288L30.628,89.396
			c-1.858-7.288,0.427-14.578,6.113-19.501c5.686-4.923,13.225-6.141,20.174-3.259l413.094,171.409
			c11.125,4.616,11.99,14.91,11.99,17.955S481.134,269.339,470.009,273.955z" fill="#4F86EC"/>
        </svg>
    );
}

export default SendMessage;