import React from 'react';

function CheckedOffSvg(props: any) {
    return (
        <svg {...props} width="64" height="34" viewBox="0 0 64 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="64" height="34" rx="17" fill="#EE5050"/>
            <ellipse cx="19.1765" cy="16.999" rx="13.1765" ry="13.2222" fill="#EBEBEB"/>
            <path
                d="M44.9551 18.2832C44.9551 19.2585 44.7865 20.1152 44.4492 20.8535C44.112 21.5872 43.6289 22.1523 43 22.5488C42.3757 22.9408 41.6556 23.1367 40.8398 23.1367C40.0332 23.1367 39.3132 22.9408 38.6797 22.5488C38.0508 22.1523 37.5632 21.5895 37.2168 20.8604C36.875 20.1312 36.7018 19.2904 36.6973 18.3379V17.7773C36.6973 16.8066 36.8682 15.9499 37.21 15.207C37.5563 14.4642 38.0417 13.8968 38.666 13.5049C39.2949 13.1084 40.015 12.9102 40.8262 12.9102C41.6374 12.9102 42.3551 13.1061 42.9795 13.498C43.6084 13.8854 44.0938 14.446 44.4355 15.1797C44.7773 15.9089 44.9505 16.7588 44.9551 17.7295V18.2832ZM43.2256 17.7637C43.2256 16.6608 43.016 15.8154 42.5967 15.2275C42.182 14.6396 41.5918 14.3457 40.8262 14.3457C40.0788 14.3457 39.4932 14.6396 39.0693 15.2275C38.6501 15.8109 38.4359 16.638 38.4268 17.709V18.2832C38.4268 19.377 38.6387 20.2223 39.0625 20.8193C39.4909 21.4163 40.0833 21.7148 40.8398 21.7148C41.6055 21.7148 42.1934 21.4232 42.6035 20.8398C43.0182 20.2565 43.2256 19.4043 43.2256 18.2832V17.7637ZM47.1016 23V16.834H45.9736V15.6035H47.1016V14.9268C47.1016 14.1064 47.3294 13.473 47.7852 13.0264C48.2409 12.5798 48.8789 12.3564 49.6992 12.3564C49.9909 12.3564 50.3008 12.3975 50.6289 12.4795L50.5879 13.7783C50.4056 13.7419 50.1937 13.7236 49.9521 13.7236C49.1592 13.7236 48.7627 14.1315 48.7627 14.9473V15.6035H50.2666V16.834H48.7627V23H47.1016ZM52.0645 23V16.834H50.9365V15.6035H52.0645V14.9268C52.0645 14.1064 52.2923 13.473 52.748 13.0264C53.2038 12.5798 53.8418 12.3564 54.6621 12.3564C54.9538 12.3564 55.2637 12.3975 55.5918 12.4795L55.5508 13.7783C55.3685 13.7419 55.1566 13.7236 54.915 13.7236C54.1221 13.7236 53.7256 14.1315 53.7256 14.9473V15.6035H55.2295V16.834H53.7256V23H52.0645Z"
                fill="white"/>
        </svg>
    );
}

export default CheckedOffSvg;