import React, {useState} from 'react';
import styled, {css} from "styled-components";
import CloseModalSvg from "../styles/images/CloseModalSVG";
import noava from "../styles/images/noava.png";
import ModalSearchSvg from "../styles/images/ModalSearchSVG";
import ModalAddButtonSvg from "../styles/images/ModalAddButtonSVG";
import Button, {StyledButton} from "./Button";
import ModalAddedButtonSvg from "../styles/images/ModalAddedButtonSVG";
import {Add, Avatar, Content, Info, Left, Name, Right, Search, Status, StyledSearchInput, User} from "./AddUserModal";

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    cursor: pointer;
  }
`;
const Title = styled.div`
  font-size: 32px;
  font-weight: bold;
`;

const StyledCloseModalSvg = styled(CloseModalSvg)`
  position: absolute;
  right: 20px;
  top: 20px;
`;

const StyledCreateChatModal = styled.div(({theme: {modal}}) => css`
  max-height: 55vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: ${modal.background};
  color: ${modal.color};
  border-radius: 24px;
  backdrop-filter: blur(16px);
  padding: 30px 25px;
  position: relative;
  @media (max-width: 375px) {
    max-height: 70vh;
  }

  ${StyledButton} {
    margin-top: 20px;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    padding-top: 12px;
    padding-bottom: 12px;
  }

`);

type AddUserModalProps = {
    users: any[],
    onCancel: () => void,
    onAdd: (user: any) => void,
    onRemove: (user: any) => void,
    onCreate: () => void,
    selected: number[]
}

function CreateChatModal({users, onCancel, onAdd, onRemove, selected, onCreate}: AddUserModalProps) {

    const [searchValue, setSearchValue] = useState<any>("");

    return (
        <StyledCreateChatModal>
            <StyledCloseModalSvg onClick={onCancel}/>
            <Top>
                <Title>Create group chat</Title>
            </Top>
            <Search>
                <StyledSearchInput onChange={({currentTarget: {value}}) => setSearchValue(value)}
                                   prefix={<ModalSearchSvg/>} placeholder={"Search"}/>
            </Search>
            <Content>
                {users.filter(user => {
                    return (user.Login.includes(searchValue) || user.Name.includes(searchValue))
                }).length ? users.filter(user => {
                    return (user.Login.includes(searchValue) || user.Name.includes(searchValue))
                }).map((user, index) => (
                    <User key={index}>
                        <Left>
                            <Avatar src={user.Photo ? "/" + user.Photo : noava} alt="avatar"/>
                        </Left>
                        <Right>
                            <Info>
                                <Name>{user.Name || user.Login}</Name>
                                <Status>{user.Online ? "Online" : "Offline"}</Status>
                            </Info>
                            <Add>
                                {selected.includes(user.Id) ? <ModalAddedButtonSvg onClick={() => onRemove(user.Id)}/> :
                                    <ModalAddButtonSvg onClick={() => onAdd(user.Id)}/>}
                            </Add>
                        </Right>
                    </User>
                )) : "No one was found"}
            </Content>
            <Button onClick={onCreate}>Create</Button>
        </StyledCreateChatModal>
    );
}

export default CreateChatModal;