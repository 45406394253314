export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const CHANGE_DIALOGS = "CHANGE_DIALOGS";
export const CHANGE_OPTIONS = "CHANGE_OPTIONS";
export const CHANGE_CHATS = "CHANGE_CHATS";
export const CHANGE_CONTACTS = "CHANGE_CONTACTS";
export const CHANGE_LOCK = "CHANGE_LOCK";
export const CHANGE_ENCRYPTION = "CHANGE_ENCRYPTION";
export const CHANGE_STREAMS = "CHANGE_STREAMS";
export const CHANGE_I_CREATOR = "CHANGE_I_CREATOR";
export const MENU_COLLAPSED = "MENU_COLLAPSABLE";