import styled, {css} from "styled-components";
import {Modal} from "antd";

export const StyledModal = styled(Modal)(({theme: {modal}}) => css`
  .ant-modal-content {
    border-radius: 24px;
    font-family: Roboto, sans-serif;

    .ant-modal-header {
      border-bottom: none;
      border-radius: 24px 24px 0 0;

      .ant-modal-title {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #444444;
        font-size: 32px;
      }
    }

    .ant-modal-body {

    }

    .ant-modal-footer {
      border-top: none;
      display: flex;
      padding-bottom: 30px;

      .ant-btn {
        font-size: 16px;
        color: #C1C1C1;
        border: none;
        box-shadow: none;
        font-weight: bold;
        padding: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }

      .ant-btn-primary {
        background: ${modal.buttonBackground};
        box-shadow: -4px 4px 14px rgba(0, 0, 0, 0.08);
        border-radius: 7px;
        color: #ffffff;
      }
    }
  }
`);