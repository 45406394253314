import React from 'react';

function ModalAddedButtonSvg(props: any) {
    return (
        <svg {...props} width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.624603" width="48" height="48" rx="10" fill="#64B161"/>
            <path d="M17 26.6246L20.2331 29.0494C20.6618 29.3709 21.2677 29.2974 21.607 28.8827L30 18.6246" stroke="white" strokeWidth="2" strokeLinecap="round"/>
        </svg>
    );
}

export default ModalAddedButtonSvg;