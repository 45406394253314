import React from 'react';

function DropCall(props: any) {
    return (
        <svg {...props} width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="42" height="42" rx="10" fill="#FF5555"/>
            <g clipPath="url(#clip0_86:0)">
                <path
                    d="M14.4459 24.0449L16.1959 22.6537C16.6159 22.3212 16.8609 21.8137 16.8609 21.2799V19.0049C19.5034 18.1474 22.3647 18.1387 25.0159 19.0049V21.2887C25.0159 21.8224 25.2609 22.3299 25.6809 22.6624L27.4222 24.0449C28.1222 24.5962 29.1197 24.5437 29.7497 23.9137L30.8172 22.8462C31.5172 22.1462 31.5172 20.9824 30.7734 20.3262C25.1647 15.3737 16.7122 15.3737 11.1034 20.3262C10.3597 20.9824 10.3597 22.1462 11.0597 22.8462L12.1272 23.9137C12.7484 24.5437 13.7459 24.5962 14.4459 24.0449Z"
                    fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_86:0">
                    <rect width="21" height="21" fill="white" transform="translate(10.5 10.5)"/>
                </clipPath>
            </defs>
        </svg>
    );
}

export default DropCall;