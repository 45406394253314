import React from 'react';
import styled, {css} from "styled-components";

const StyledInfo = styled.svg(({theme: {svg}}) => css`
  path {
    fill: ${svg.color2};
  }
`);

function Info(props: any) {
    return (
        <StyledInfo {...props} width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M24.2849 12.9733C24.2849 6.26722 18.8485 0.830872 12.1424 0.830872C5.43635 0.830872 0 6.26722 0 12.9733C0 19.6794 5.43635 25.1157 12.1424 25.1157C18.8485 25.1157 24.2849 19.6794 24.2849 12.9733ZM1.10373 12.9733C1.10373 6.87684 6.04586 1.93471 12.1423 1.93471C18.2387 1.93471 23.1809 6.87684 23.1809 12.9733C23.1809 19.0697 18.2387 24.0118 12.1423 24.0118C6.04586 24.0118 1.10373 19.0697 1.10373 12.9733ZM13.2463 10.2136V19.0445H14.3502V19.5964H9.93475V19.0445H11.0386V10.7656H9.93475V10.2136H13.2463ZM13.467 7.28843C13.467 6.46541 12.7998 5.79823 11.9768 5.79823C11.1538 5.79823 10.4866 6.46541 10.4866 7.28843C10.4866 8.11145 11.1538 8.77864 11.9768 8.77864C12.7998 8.77864 13.467 8.11145 13.467 7.28843Z"
                  fill="#3497F9"/>
        </StyledInfo>
    );
}

export default Info;