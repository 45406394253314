import React from 'react';

function UserDeskSvg(props: any) {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M7.73598 19.102C9.55913 18.0528 11.7603 17.5 13.9997 17.5C16.2391 17.5 18.4402 18.0528 20.2634 19.102C22.0858 20.1508 23.4716 21.6653 24.1136 23.4624C24.3304 24.0692 24.0143 24.7368 23.4075 24.9536C22.8007 25.1704 22.1331 24.8542 21.9163 24.2474C21.4949 23.0677 20.5371 21.9517 19.0995 21.1244C17.6626 20.2975 15.8695 19.8333 13.9997 19.8333C12.1299 19.8333 10.3367 20.2975 8.89983 21.1244C7.46228 21.9517 6.5045 23.0677 6.08302 24.2474C5.86624 24.8542 5.19863 25.1704 4.59185 24.9536C3.98508 24.7368 3.66893 24.0692 3.88571 23.4624C4.52777 21.6653 5.91351 20.1508 7.73598 19.102Z"
                  fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M14.0003 5.83333C12.0673 5.83333 10.5003 7.40034 10.5003 9.33333C10.5003 11.2663 12.0673 12.8333 14.0003 12.8333C15.9333 12.8333 17.5003 11.2663 17.5003 9.33333C17.5003 7.40034 15.9333 5.83333 14.0003 5.83333ZM8.16699 9.33333C8.16699 6.11167 10.7787 3.5 14.0003 3.5C17.222 3.5 19.8337 6.11167 19.8337 9.33333C19.8337 12.555 17.222 15.1667 14.0003 15.1667C10.7787 15.1667 8.16699 12.555 8.16699 9.33333Z"
                  fill="white"/>
        </svg>

    );
}

export default UserDeskSvg;