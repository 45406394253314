import React, {useEffect, useRef, useState} from 'react';
import {__start, addToRoom, flipCamera, getCallType, hangup, switchMicrophone, turnVideo} from "../utils/webrtc";
import styled from "styled-components";
import SwapCamera from "../styles/images/swapCamera";
import EndCall from "../styles/images/EndCall";
import MicrophoneNav from "../styles/images/MicrophoneNav";
import AddUser from "../styles/images/AddUser";
import CaptureNav from "../styles/images/CaptureNav";
import history from "../utils/history";
import {connect} from "react-redux";
import Lock from "../styles/images/Lock";
import DisabledMicrophoneNav from "../styles/images/DisabledMicrophoneNav";
import DisabledCaptureNav from "../styles/images/DisabledCaptureNav";
import {message, Modal} from "antd";
import AddUserModal from "../components/AddUserModal";
import noava from "../styles/images/noava.png";
import axios from "axios";
import {localCrypterIp, localCrypterIp_IOS} from "../config";
import SpeakerSvg from "../styles/images/SpeakerSvg";
import HeadphonesSvg from "../styles/images/HeadphonesSvg";
import {detectIOS} from "../utils/detectIOS";

const Avatar = styled.img`
  width: 100%;
  object-fit: cover;
`;
const StyledRoom = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100vh;

  .ant-modal-content {
    background: none;
    box-shadow: none;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${({theme}) => theme.layout.mainColor};
    padding: 17px 15px 17px 23px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    height: 62px;

    .title {
      color: white;
      display: flex;
      align-items: center;
      font-size: 17px;
      line-height: 20px;
      letter-spacing: -0.3px;

      svg {
        cursor: pointer;
        margin-left: 20px;
      }
    }
  }

  .content {
    display: flex;
    flex: 1 1 auto;
    position: relative;
    padding-bottom: 96px;
    margin-top: 62px;
    flex-wrap: wrap;
    max-height: 100%;
    background: ${({theme}) => theme.layout.mainColor};

    video {
      position: absolute;
      height: 100%;
      object-fit: cover;
    }

    .mini {
      width: 90px;
      height: 154px;
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 999;
      border-radius: 5px;
      background: #2d2d2d;

      img {
        display: none;
      }

      video {
        border-radius: 5px;
        width: 100%;
      }
    }

    .full {
      max-height: calc(100% - 62px);
      background: #222;
      flex: 1 1 50%;
      display: flex;
      justify-content: center;
      overflow: hidden;
      position: relative;

      .name {
        position: absolute;
        color: white;
        bottom: 0;
        font-size: 24px;
        height: 50px;
        width: 100%;
        text-align: center;
        text-shadow: 1px 1px 3px black;
      }

      video {
        overflow: hidden;
        object-fit: contain;
      }
    }
  }

  .nav {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 96px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 23px 0;
    background: ${({theme}) => theme.layout.mainColor};

    svg {
      cursor: pointer;
    }

    &.disabled {
      .switchable {
        opacity: .2;
      }
    }
  }

  svg {
    &.notAllowed {
      cursor: not-allowed;
      opacity: .2;
    }
  }
`;

function Room({encryption, match, contacts, streams, usersInRoom, iCreator}: any) {

    const userId = parseInt(match.params.userId);
    console.log(contacts.filter((el: any) => el.Id === userId));
    let User = contacts.filter((el: any) => el.Id === userId)[0];
    const video1 = useRef(null);
    const isMounted = useRef(false);
    const [userCounter, setUserCounter] = useState(0);

    /*Тип выбранного устройства - true - динамики, false - наушники*/
    const [isSpeaker, setIsSpeaker] = useState<boolean>(true);
    /*Есть ли доступ к смене вывода*/
    const [swapAllowed, setSwapAllowed] = useState<boolean>(false);

    /*Получаем текущее устройство вывода*/
    const getOutDevice = () => {
        axios(`${detectIOS() ? localCrypterIp_IOS : localCrypterIp}/audio/status`).then(({data}) => {
            setIsSpeaker(data.Speaker);
            setSwapAllowed(true);
        }).catch(err => {
            setSwapAllowed(false);
        })
    }

    /*Меняем тип текущего устройства вывода*/
    const switchOutDevice = () => {
        if (!swapAllowed)
            return
        axios(`${detectIOS() ? localCrypterIp_IOS : localCrypterIp}/audio/toggle`).then(({data}) => {
            setIsSpeaker(data.Speaker);
            setSwapAllowed(true);
        }).catch(({response: {data}}) => {
            message.error(data || "error")
        })
    }

    useEffect(() => {
        console.log("STREAMS");

        streams.forEach((el: any) => {
            if (el.ref.current.srcObject !== el.stream && el.stream !== undefined) {
                console.log("el.ref.current.srcObject !== el.stream");
                el.ref.current.srcObject = el.stream;
                el.ref.current.play();
            }
            localStorage.getItem("defender_audioOutput") && el.ref.current.setSinkId(localStorage.getItem("defender_audioOutput"));
        })

        /*Если все вышли и мы не создатель конференции, то удаляемся из страницы*/
        // if (isMounted.current === true && (streams.length <= 0)) {
        //     console.log("Hangup because streams length is 0")
        //     console.log(streams);
        //     handleHangup();
        // }
        if (streams.length !== userCounter) {
            console.log("streams.length", streams.length);
            setUserCounter(streams.length)
        }
        // eslint-disable-next-line
    }, [streams, userCounter]);

    useEffect(() => {

        /*Если все вышли и мы не создатель конференции, то удаляемся из страницы*/
        if (isMounted.current === true && userCounter <= 0) {
            console.log("Hangup because streams length is 0")
            console.log(streams);
            handleHangup();
        } else if (isMounted.current === true && iCreator === false && userCounter <= 0) {
            console.log("Hangup because streams length is 0")
            console.log(streams);
            handleHangup();
        }
        // eslint-disable-next-line
    }, [userCounter])

    const [swapped, setSwapped] = useState(false);
    const [soundOn, setSoundOn] = useState(true);
    const [videoOn, setVideoOn] = useState(true);
    const [modalVisible, setModalVisible] = useState(false);

    useEffect(() => {
        console.log(swapped);
    }, [swapped]);

    const handleHangup = () => {
        history.replace("/");
        hangup();
    }

    const handleSwap = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        console.log(e);
        setSwapped(!swapped);
    }

    useEffect(() => {
        isMounted.current = true;
        getOutDevice();
        __start({
            video1: video1.current
        });
        return () => {
            hangup()
        }

    }, []);

    return (
        <StyledRoom>
            <div className="header">
                <div className="title">
                    {getCallType() === "audio" ? "Audio" : "Video"} Call
                    with {contacts.length > 0 && (User?.Name || User?.Login)}
                    <AddUser onClick={() => setModalVisible(true)}/>
                </div>
                {encryption && <Lock/>}
            </div>
            <div className="content">
                <div style={getCallType() === "audio" ? {display: "none"} : {}} className={swapped ? "full" : "mini"}
                     onTouchEnd={swapped ? () => {
                         console.log("test")
                     } : handleSwap} onClick={swapped ? () => {
                    console.log("test")
                } : handleSwap}>
                    <video ref={video1} id="video1" autoPlay
                           playsInline muted webkit-playsinline/>
                </div>

                {streams.map((el: any) => {
                    console.log("RENDER!");
                    const currentUser = contacts.filter((contact: any) => contact.Id === el.userId)[0];
                    return <div
                        className={swapped ? "mini" : "full"}
                        onTouchEnd={swapped ? handleSwap : () => {
                            console.log("test")
                        }} onClick={swapped ? handleSwap : () => {
                        console.log("test")
                    }}>
                        <Avatar style={getCallType() === "audio" ? {} : {opacity: 0}}
                                src={currentUser?.Photo ? `/${currentUser.Photo}` : noava} alt="avatar"/>
                        <video style={getCallType() === "audio" ? {opacity: 0} : {}} ref={el.ref} autoPlay
                               playsInline webkit-playsinline/>
                        <div className="name">{(currentUser?.Name || currentUser?.Login) || "unknown"}</div>
                    </div>
                })}
            </div>
            <div className={`nav ${streams.length > 0 ? "" : "disabled"}`}>
                <SwapCamera className={"switchable"} onClick={() => flipCamera()}/>
                {videoOn ? <CaptureNav className={"switchable"}
                                       onClick={() => setVideoOn(turnVideo())}/> :
                    <DisabledCaptureNav className={"switchable"} onClick={() => setVideoOn(turnVideo())}/>}
                <EndCall onClick={handleHangup}/>
                {soundOn ? <MicrophoneNav className={"switchable"} onClick={() => setSoundOn(switchMicrophone())}/> :
                    <DisabledMicrophoneNav className={"switchable"} onClick={() => setSoundOn(switchMicrophone())}/>}
                {/*<AddUser onClick={() => setModalVisible(true)}/>*/}
                {isSpeaker ? <SpeakerSvg className={`switchable ${swapAllowed ? "" : "notAllowed"}`}
                                         onClick={switchOutDevice}/> :
                    <HeadphonesSvg className={`switchable ${swapAllowed ? "" : "notAllowed"}`}
                                   onClick={switchOutDevice}/>}
            </div>
            <Modal style={{background: "none"}}
                   getContainer={() => document.querySelector("." + StyledRoom.styledComponentId)!} forceRender
                   visible={modalVisible} closable={false} footer={null}>

                <AddUserModal
                    onAdd={(selectedUser) => {
                        console.log(selectedUser);
                        addToRoom([selectedUser]);
                        setModalVisible(false);
                    }}
                    onCancel={() => setModalVisible(false)}
                    users={contacts.filter((el: any) => !usersInRoom.includes(el.Id))}/>
            </Modal>
        </StyledRoom>
    )
}

const mapStateToProps = (state: any) => {
    return {
        contacts: state.pageReducer.contacts,
        encryption: state.pageReducer.encryption,
        streams: state.pageReducer.editedStreams,
        usersInRoom: state.pageReducer.streams.map((stream: any) => stream.id),
        iCreator: state.pageReducer.iCreator
    }
}

export default connect(mapStateToProps, null)(Room);