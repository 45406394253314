import React, {useEffect, useState} from 'react';
import styled, {css} from "styled-components";
import axios from "axios";
import {api} from "../config";
import {Form, Input, message} from "antd";
import Button, {StyledButton} from '../components/Button';
import ZoomSvg from "../styles/images/ZoomSvg";
import {StyledModal} from "../styled/StyledModal";
import TrashSvg from "../styles/images/TrashSvg";
import GroupSvg from "../styles/images/GroupSvg";
import StyledInput from '../styled/StyledInput';
import StyledForm from "../styled/StyledForm";

const SearchInput = styled(Input)`
  background: #EEF0F2;
  border: none;
  border-radius: 10px;
  outline: none;
  box-shadow: none !important;
  margin-right: 28px;
  width: 100%;

  .ant-input {
    font-size: 14px;
    background: #EEF0F2;
  }
`;

const Header = styled.div`
  display: flex;
  max-width: 610px;
  margin-bottom: 24px;

  ${StyledButton} {
    width: 100%;
  }
`;

const UsersWrap = styled.div`
  position: relative;
  display: flex;
  overflow: hidden;
  max-width: 610px;
  border-radius: 18px;
  border: 1px solid #E8E8E8;
  background: #fff;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-right: 5px solid black;
    border-radius: 23px;
  }
`;

const Users = styled.div`
  width: 100%;
  border-radius: 18px;
  overflow-y: auto;
  z-index: 1;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #C4C4C4;
    border-radius: 23px;
  }
`;

const UserItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  padding: 10px 0 10px 10px;
  overflow: hidden;
  width: 100%;
  padding-right: 32px;


  &:hover {
    background: #f7f7f7;
  }

  .keyGenerate {
    display: flex;
    align-items: center;
    margin-right: 10px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .state {
    display: flex;
    align-items: center;

    svg {
      height: 20px;
      width: 20px;
    }
  }

  .action {
    height: 20px;
    flex: 0 0 auto;

    circle {
      fill: #b0b0b0;
    }
  }

  .name {
    margin-right: auto;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #010b2b;
    font-weight: bold;
    font-size: 18px;
  }

  img {
    flex: 0 0 auto;
    height: 40px;
    width: 40px;
    object-fit: cover;
    border-radius: 100%;
    margin-right: 10px;
  }
`;


const StyledGroupSvg = styled(GroupSvg)(({theme: {svg}}) => css`
  position: absolute;
  right: 20%;
  top: 20%;
  width: 500px;

  path, circle {
    fill: ${svg.color6};
  }
`);

const UserList = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({theme}) => theme.layout.textColor};
  flex: 1 1 auto;
  z-index: 1;

  ${UserItem}:not(:last-child) {
    border-bottom: 1px solid ${({theme}) => theme.layout.borderColor};
  }
`;


const StyledAdmin = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 23px;
  display: flex;
  position: relative;
  margin-bottom: 100px;
  flex: 1 1 auto;

  .add_btn {
    background: ${({theme}) => theme.layout.mainColor};
    color: white;
  }

  @media screen and (min-width: 600px) {
    .add_btn {
      height: 60px;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: bold;
    }

    .ant-dropdown-menu-item {
      font-size: 18px;

      svg {
        height: 30px;
        width: 30px;
      }
    }

    ${UserList} {
      ${UserItem} {
        height: 100px;

        .name {
          font-size: 18px;
        }

        img {
          height: 68px;
          width: 68px;
        }

        .keyGenerate {
          cursor: pointer;
          margin-right: 20px;

          svg {
            width: 40px;
            height: 40px;
          }
        }

        .state {
          cursor: pointer;

          svg {
            width: 30px;
            height: 30px;
          }
        }

        .action {
          cursor: pointer;
          width: 30px;
          height: 30px;
        }
      }
    }
  }

`;

function Groups() {

    const [groups, setGroups] = useState([]);
    const [filteredGroups, setFilteredGroups] = useState([]);
    const [createModalVisible, setCreateModalVisible] = useState(false);
    const [formRef] = Form.useForm();


    const getGroups = () => {
        axios(`${api}/groups`).then(({data}) => {
            setGroups(data);
        }).catch(({response: {data}}) => {
            message.error(data || "error");
        })
    }

    const addGroup = (fields: any) => {
        axios.post(`${api}/groups`, fields).then(() => {
            getGroups()
            setCreateModalVisible(false)
            formRef.resetFields();
        }).catch(({response: {data}}) => {
            message.error(data);
        })
    }

    const deleteGroup = (id: number) => {
        axios.delete(`${api}/groups/${id}`).then(() => {
            getGroups();
        }).catch(({response: {data}}) => {
            message.error(data);
        })
    }

    const search = ({target: {value: text}}: any) => {
        console.log(groups.filter((group: any) => group.Name.indexOf(text)));
        setFilteredGroups(groups.filter((group: any) => group.Name.indexOf(text) !== -1))
    }

    useEffect(() => {
        getGroups();
    }, [])

    useEffect(() => {
        setFilteredGroups(groups);
    }, [groups])

    return (
        <StyledAdmin>
            <UserList>
                <StyledModal onCancel={() => {
                    setCreateModalVisible(false)
                    formRef.resetFields();
                }}
                             okText="Create"
                             onOk={() => formRef.submit()}
                             visible={createModalVisible}
                             title={"Create new group"}>
                    <StyledForm hideRequiredMark layout={"vertical"} onFinish={addGroup} form={formRef}>
                        <Form.Item label={"Name"} name={"Name"} rules={[{
                            required: true,
                            message: "Name is required"
                        }]}>
                            <StyledInput/>
                        </Form.Item>
                    </StyledForm>
                </StyledModal>
                <Header>
                    <SearchInput onChange={search} prefix={<ZoomSvg/>} placeholder={"Search group"}/>
                    <Button onClick={() => setCreateModalVisible(true)} className={"add_btn"}>Add group</Button>
                </Header>
                <UsersWrap>
                    <Users>
                        {filteredGroups.length > 0 && filteredGroups?.map((group: any, index: number) => (
                            <UserItem key={index}>
                                <div className="name">{group.Name}</div>
                                <div className="state">
                                    <TrashSvg onClick={() => deleteGroup(group.Id)}/>
                                </div>
                            </UserItem>
                        ))}
                    </Users>
                </UsersWrap>
            </UserList>
            <StyledGroupSvg/>
        </StyledAdmin>
    );
}

export default Groups;