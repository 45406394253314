import React from 'react';

function FingerprintScanSvg(props: any) {
    return (
        <svg {...props} width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17 8L17 7C17 6.07003 17 5.60504 16.8978 5.22354C16.6204 4.18827 15.8117 3.37962 14.7765 3.10222C14.395 3 13.93 3 13 3"
                stroke="white" strokeWidth="2" strokeLinejoin="round"/>
            <path
                d="M17 12L17 13C17 13.93 17 14.395 16.8978 14.7765C16.6204 15.8117 15.8117 16.6204 14.7765 16.8978C14.395 17 13.93 17 13 17"
                stroke="white" strokeWidth="2" strokeLinejoin="round"/>
            <path
                d="M7 17L6 17C4.13077 17 3.19615 17 2.5 16.5981C2.04394 16.3348 1.66523 15.9561 1.40192 15.5C1 14.8038 1 13.8692 1 12"
                stroke="white" strokeWidth="2" strokeLinejoin="round"/>
            <path
                d="M7 3L6 3C4.13077 3 3.19615 3 2.5 3.40192C2.04394 3.66523 1.66523 4.04394 1.40192 4.5C1 5.19615 1 6.13077 1 8"
                stroke="white" strokeWidth="2" strokeLinejoin="round"/>
            <path d="M7 19L7 1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default FingerprintScanSvg;