import React, {MutableRefObject, useEffect, useLayoutEffect, useRef} from 'react';
import styled from "styled-components";
import server from "../styles/images/server.svg";
import apple from "../styles/images/apple.svg";
import windows from "../styles/images/windows.svg";
import linux from "../styles/images/linux.svg";
import android from "../styles/images/android.svg";
import unknown from "../styles/images/unknown.svg";
import {connect} from "react-redux";
import {getWS} from "../utils/websocket";

const StyledVisualiser = styled.div`
  flex: 1 1 auto;
  position: relative;

  canvas {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
`;

type VisualiserProps = {
    visualizer: MutableRefObject<any>,
    menuCollapsed?: boolean
}

function Visualiser({visualizer, menuCollapsed}: VisualiserProps) {
    const canvasRef = useRef<any>(null);
    const requestRef = useRef<number | null>(null);
    // eslint-disable-next-line
    // const [scale, setScale] = useState(1);
    const scale = useRef(1);


    const serverRef = useRef<any>(null);
    const appleRef = useRef<any>(null);
    const windowsRef = useRef<any>(null);
    const linuxRef = useRef<any>(null);
    const androidRef = useRef<any>(null);
    const unknownRef = useRef<any>(null);

    const balls = useRef<Dot[]>([]);

    let ctx: any = useRef();

    let mouseDown: any = useRef(false);
    let startDragOffset: any = useRef({x: 0, y: 0});
    let translatePos: any = useRef({x: 0, y: 0});

    let halfWidth = useRef<number>(0);
    let halfHeight = useRef<number>(0);

    // const degrees = 360 / 16;
    const offset = 100;

    // const spawnDot = () => {
    //     console.log("Вызываюсь!");
    //     let newDots = []
    //     for (let item of items) {
    //         newDots.push(generateDot(item, true));
    //     }
    //     balls.current = [...balls.current, ...newDots]
    //     console.log(balls.current);
    // }

    // eslint-disable-next-line
    const spawnDotTo = (user: WSUser, reverse: boolean) => {
        // console.log("Вызываюсь!");
        let newDots = []
        for (let item of items.current) {
            if (item.user.UserId === user.UserId) {
                newDots.push(generateDot(item, reverse));
            }
        }
        balls.current = [...balls.current, ...newDots]
        // console.log(balls.current);
    }

    // eslint-disable-next-line
    const removeUser = (UserId: number) => {
        let newIndex = 0;
        balls.current = [];

        /*TODO: принимать в качестве аргумента getCoords измененный массив*/
        const newItems = items.current.filter(item => item.user.UserId !== UserId)

        items.current = [...newItems.map(el => {
            return getCoords(newItems, el.user, newIndex++, true)
        })];
        console.log("items.filter(item => item.user.UserId !== UserId)", items.current.filter(item => item.user.UserId !== UserId));
    }

    // eslint-disable-next-line
    const addNew = (user: WSUser) => {
        console.log("user", user);
        let newIndex = 0;
        balls.current = balls.current.filter(el => el.item.user.UserId !== user.UserId);
        balls.current = [];

        const newItems = items.current.filter(item => item.user.UserId !== user.UserId)


        // setItems([...items.filter(item => item.user.UserId !== user.UserId).map(el => {
        //     return getCoords(el.user, newIndex++)
        // }), getCoords(user, newIndex)])

        items.current = [...newItems.map(el => {
            return getCoords(newItems, el.user, newIndex++, false)
        }), getCoords(newItems, user, newIndex, false)]

        // setItems([...items.map(el => {
        //     return getCoords(el.user, newIndex++)
        // }), getCoords(user, newIndex)])

        // setItems([...items.map(el => {
        //     return getCoords(el.user, newIndex++)
        // }), getCoords(user, newIndex)])

    }

    useEffect(() => {
        visualizer.current.spawnDotTo = spawnDotTo;
        visualizer.current.addNew = addNew;
        visualizer.current.removeUser = removeUser;
        // console.log(visualizer);
    }, [visualizer, spawnDotTo, addNew, removeUser]);


    const getCoords = (oldItems: Item[], user: WSUser, itemIndex: number, remove: boolean, random?: boolean): Item => {

        /*TODO: remove - для того чтобы не трогать размер массива, обычно когда remove undefined/true - мы ждем что текущая итерация производится для длины массива + 1, тоесть для будущего кол-ва элементов массива, а то что мы вернем и будет тем самым новым элементом*/

        /*Максимально кол-во элементов в ряду*/
        const MaxItems = 8;

        /*Получение ряда круга - первый круг - 1, второй круг - 2...*/
        const seriesNumber = Math.ceil((itemIndex + 1) / MaxItems);


        /*TODO:START!!!ИДЕАЛЬНО!!!*/
        const second = itemIndex - (MaxItems * Math.floor(itemIndex / MaxItems))
        /*TODO: END!!!ИДЕАЛЬНО!!!*/

        // const test2 = (items.length + 1) >= MaxItems && (items.length + 1) - (itemIndex) >= MaxItems ? MaxItems : (items.length + 1) % MaxItems > 0 ? (items.length + 1) % MaxItems : MaxItems;


        /* Номер элемента в зависимости от ряда - от 0 до MaxItems */
        const elementNumber = (oldItems.length + (remove ? 0 : 1)) <= MaxItems ? (oldItems.length + (remove ? 0 : 1)) : itemIndex / MaxItems <= Math.floor((oldItems.length + (remove ? 0 : 1)) / MaxItems) ? MaxItems : (oldItems.length + (remove ? 0 : 1)) % MaxItems;

        const first = (360 / (elementNumber));

        /*Градусы между элементами*/
        const itemDegree = first * second;


        // console.log("items.length - MaxItems * (seriesNumber - 1)", test);
        console.log("test2", elementNumber);
        console.log("first", first);
        console.log("second", second);
        console.log("seriesNumber", seriesNumber);
        console.log("itemIndex", itemIndex);
        console.log("itemDegree", itemDegree);

        /*Множитель расстояния между рядами кругов*/
        const seriesOffset = seriesNumber > 1 ? seriesNumber / 1.1 : seriesNumber;

        let randomOffset = Math.floor(Math.random() * (offset + 10 - offset + 1) + offset)
        randomOffset = Math.max(((73.6 / 1.5) + 20), randomOffset)

        /*Расстояние от центра до элемента*/
        const distance = random === false ? (oldItems.find(el => el.index === itemIndex)?.distance || (randomOffset * seriesOffset)) : randomOffset * seriesOffset;

        console.log("distance", distance);

        /*Перевод градусов в радианы*/
        const toRadian = (degree: number): number => {
            return degree * (Math.PI / 180)
        }

        const x = halfWidth.current + Math.sin(toRadian(itemDegree)) * distance;
        const y = halfHeight.current + Math.cos(toRadian(itemDegree)) * distance;
        return {user, index: itemIndex, x, y, distance}
    }


    // const [items, setItems] = useState<Item[]>([]);
    const items = useRef<Item[]>([]);
    let canvasWidth = useRef<any>();
    let canvasHeight = useRef<any>();

    const drawCircle = (x: number, y: number, r: number) => {
        ctx.current.beginPath();
        ctx.current.ellipse(x, y, r, r, 0, 0, Math.PI * 2)
        ctx.current.fill();
    }

    const drawImage = (x: number, y: number, image: CanvasImageSource) => {
        const factor = 1.5;

        ctx.current.beginPath();
        ctx.current.drawImage(image, x - (image.width as number / (2 * factor)), y - (image.height as number / (2 * factor)), image.width as number / factor, image.height as number / factor);
    }

    const generateDot = (item: Item, reverse: boolean): Dot => {
        const itemX = item.x;
        const itemY = item.y;

        return {
            item: item,
            index: balls.current.length > 0 ? balls.current[balls.current.length - 1].index + 1 : 0,
            startPos: reverse ? {
                x: halfWidth.current,
                y: halfHeight.current
            } : {
                x: itemX,
                y: itemY,
            },
            toPos: reverse ? {
                x: itemX,
                y: itemY,
            } : {
                x: halfWidth.current,
                y: halfHeight.current
            },
            pos: reverse ? {
                x: halfWidth.current,
                y: halfHeight.current
            } : {
                x: itemX,
                y: itemY,
            }
        }
    }


    const draw = () => {
        clear();
        ctx.current.save();
        ctx.current.translate(translatePos.current.x / scale.current, translatePos.current.y / scale.current)
        const time = new Date().getTime();

        ctx.current.fillStyle = "orange";
        /*Draw lines*/
        for (let item of items.current) {
            ctx.current.lineWidth = 1;
            ctx.current.moveTo(item.x, item.y);
            ctx.current.lineTo(halfWidth.current, halfHeight.current);
            ctx.current.strokeStyle = "#03134C";
            ctx.current.stroke();
        }

        /*Draw moving balls*/
        balls.current.forEach((dot, index) => {
            const color = (Math.sin(time / 100) * Math.sin(time / 100) + 1) / 2 * 255;
            const color2 = (Math.sin(time / 100) * Math.cos(time / 100) + 1) / 2 * 255;
            const color3 = (Math.sin(time / 200) * Math.cos(time / 200) + 1) / 2 * 255;

            ctx.current.strokeStyle = `rgb(${color}, ${color2}, ${color3})`;
            // ctx.current.strokeStyle = `white`;
            ctx.current.fillStyle = `white`;
            // ctx.current.shadowColor = `rgb(${color}, ${color2}, ${color3})`;
            // ctx.current.shadowColor = `black`;
            ctx.current.shadowBlur = 0;
            ctx.current.lineWidth = 2;
            dot.index % 2 > 0 ? drawCircle(dot.pos.x, dot.pos.y, 3) : drawCircle(dot.pos.x, dot.pos.y, 5)
            ctx.current.stroke();
        })
        ctx.current.shadowBlur = 0;


        /*Draw balls*/
        for (let item of items.current) {
            let imageRef;
            switch (item.user.Device) {
                case "unknown":
                    imageRef = unknownRef.current;
                    break;
                case "android":
                    imageRef = androidRef.current;
                    break;
                case "windows":
                    imageRef = windowsRef.current;
                    break;
                case "linux":
                    imageRef = linuxRef.current;
                    break;
                case "iphone":
                    imageRef = appleRef.current;
                    break;
                case "macos":
                    imageRef = appleRef.current;
                    break;
                default:
                    imageRef = unknownRef.current;
                    break;
            }

            drawImage(item.x, item.y, imageRef)
        }

        drawImage(halfWidth.current, halfHeight.current, serverRef.current);

        balls.current = balls.current.reduce((memo: any, dot) => {

            const distanceToEndX = Math.abs(dot.pos.x - dot.startPos.x);
            const distanceToEndY = Math.abs(dot.pos.y - dot.startPos.y);

            const defaultDistanceBetweenX = Math.abs(dot.toPos.x - dot.startPos.x);
            const defaultDistanceBetweenY = Math.abs(dot.toPos.y - dot.startPos.y);

            const deltaX = dot.pos.x - ((dot.startPos.x - dot.toPos.x) / 100);
            const deltaY = dot.pos.y - ((dot.startPos.y - dot.toPos.y) / 100);


            if (!(Math.round(distanceToEndX) >= Math.round(defaultDistanceBetweenX) && Math.round(distanceToEndY) >= Math.round(defaultDistanceBetweenY))) {
                memo.push({
                    ...dot, pos: {
                        x: deltaX,
                        y: deltaY
                    }
                })
            }

            return memo;
        }, []);
        // console.log(balls.current);
        ctx.current.restore();
        requestRef.current = requestAnimationFrame(draw);
    }

    const generateImages = () => {
        serverRef.current = new Image();
        appleRef.current = new Image();
        windowsRef.current = new Image();
        linuxRef.current = new Image();
        androidRef.current = new Image();
        unknownRef.current = new Image();


        serverRef.current.src = server;
        appleRef.current.src = apple;
        windowsRef.current.src = windows;
        linuxRef.current.src = linux;
        androidRef.current.src = android;
        unknownRef.current.src = unknown;
    }

    const clear = () => {
        canvasRef.current && ctx.current?.clearRect(0, 0, canvasWidth.current / scale.current, canvasHeight.current / scale.current);
    }

    // useEffect(() => {
    //     if (ctx.current) {
    //         clear()
    //         ctx.current.resetTransform();
    //         ctx.current.scale(scale.current, scale.current);
    //     }
    // }, [scale.current]);


    const onResize = () => {
        evalCanvasSize();
        ctx.current.scale(scale.current, scale.current);
        ctx.current.fillStyle = "white";
        ctx.current.fillRect(0, 0, canvasRef.current.width, canvasRef.current.height)
        balls.current = []

        items.current = [...items.current.map(
            (el, index) => {
                return getCoords(items.current, el.user, el.index, true, false)
            })]
    }


    const evalCanvasSize = () => {
        canvasWidth.current = canvasRef.current.width = canvasRef.current.getBoundingClientRect().width;
        canvasHeight.current = canvasRef.current.height = canvasRef.current.getBoundingClientRect().height;

        halfWidth.current = canvasWidth.current / 2;
        halfHeight.current = canvasHeight.current / 2;

    }

    const getUsersFromWS = (ws: any) => {
        if (ws.readyState === ws.OPEN)
            ws.send(JSON.stringify({
                Type: 51
            }))
        else
            setTimeout(() => getUsersFromWS(ws), 1000)
    }

    useEffect(() => {
        const [ws] = getWS();
        getUsersFromWS(ws)
        //    eslint-disable-next-line
    }, []);


    useLayoutEffect(() => {

        console.log("FIRST!!!!");
        generateImages();

        ctx.current = canvasRef.current.getContext("2d");
        canvasRef.current.onmousedown = (e: MouseEvent) => {
            mouseDown.current = true;
            startDragOffset.current.x = e.clientX - translatePos.current.x;
            startDragOffset.current.y = e.clientY - translatePos.current.y;
        }
        canvasRef.current.onmousemove = (e: MouseEvent) => {
            if (mouseDown.current) {
                translatePos.current.x = (e.clientX - startDragOffset.current.x);
                translatePos.current.y = (e.clientY - startDragOffset.current.y);
            }
        }
        canvasRef.current.onmouseup = (e: MouseEvent) => {
            if (mouseDown.current) {
                mouseDown.current = false;
            }
        }
        canvasRef.current.onmouseleave = (e: MouseEvent) => {
            if (mouseDown.current) {
                mouseDown.current = false;
            }
        }
        canvasRef.current.onmouseout = (e: MouseEvent) => {
            if (mouseDown.current) {
                mouseDown.current = false;
            }
        }
        canvasRef.current.onwheel = (e: WheelEvent) => {
            e.preventDefault();
            if (e.ctrlKey) {
                scale.current = scale.current * (e.deltaY < 0 ? 1.1 : 0.9);
                ctx.current.resetTransform();

                ctx.current.scale(scale.current, scale.current);
            }
        }

        window.addEventListener("resize", onResize)

        evalCanvasSize();

        requestRef.current = requestAnimationFrame(draw);

        return () => {
            window.removeEventListener("resize", onResize);
            if (requestRef.current)
                cancelAnimationFrame(requestRef.current)
        }
        // eslint-disable-next-line
    }, [])

    /*Перерасчет размера канваса при скрытии и открытии меню*/
    useEffect(() => {
        onResize();
        //    eslint-disable-next-line
    }, [menuCollapsed]);


    useEffect(() => {
        requestRef.current = requestAnimationFrame(draw)

        return () => {
            if (requestRef.current)
                cancelAnimationFrame(requestRef.current)
        };
        // eslint-disable-next-line
    }, [items.current]);


    return (
        <StyledVisualiser>
            <canvas ref={canvasRef}/>
        </StyledVisualiser>
    );
}

const mapStateToProps = (state: any) => {
    return {
        menuCollapsed: state.pageReducer.menuCollapsed
    }
}

export default connect(mapStateToProps, null)(Visualiser);