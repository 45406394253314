import React from 'react';
import styled, {css, ThemeProvider} from "styled-components";
import {theme} from "../theme";

const StyledDefenderModal = styled.div(({theme: {callModal}}) => css`
  border-radius: 13px;
  background: ${callModal.background};
  padding: 18px 20px 18px 15px;
`);

type DefenderModalProps = {
    children: any
}

function DefenderModal({children, ...props}: DefenderModalProps) {
    return (
        <ThemeProvider theme={theme}>
            <StyledDefenderModal {...props}>
                {children}
            </StyledDefenderModal>
        </ThemeProvider>
    );
}

export default DefenderModal;