import React from 'react';

function UsbPortSvg(props: any) {
    return (
        <svg {...props} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             width="30px" height="20px" viewBox="0 0 164.1 66">
<path fill="#FFFFFF" stroke="#CCD2E3" strokeWidth="5" strokeMiterlimit="10" d="M156.8,63.5H7.3c-2.6,0-4.8-2.1-4.8-4.8V7.3
	c0-2.6,2.1-4.8,4.8-4.8h149.6c2.6,0,4.8,2.1,4.8,4.8v51.5C161.6,61.4,159.4,63.5,156.8,63.5z"/>
            <path fill="#CCD2E3" d="M150.4,35.9H13.7c-1.7,0-3-1.4-3-3V13.7c0-1.7,1.4-3,3-3h136.6c1.7,0,3,1.4,3,3v19.2
	C153.4,34.6,152,35.9,150.4,35.9z"/>
            <path fill="#CCD2E3" d="M36.3,41.5h-8.5c-0.9,0-1.5-0.7-1.5-1.5V23.2c0-0.9,0.7-1.5,1.5-1.5h8.5c0.9,0,1.5,0.7,1.5,1.5V40
	C37.8,40.8,37.1,41.5,36.3,41.5z"/>
            <path fill="#CCD2E3" d="M69.4,41.5h-8.5c-0.9,0-1.5-0.7-1.5-1.5V23.2c0-0.9,0.7-1.5,1.5-1.5h8.5c0.9,0,1.5,0.7,1.5,1.5V40
	C70.9,40.8,70.3,41.5,69.4,41.5z"/>
            <path fill="#CCD2E3" d="M103.7,41.5h-8.5c-0.9,0-1.5-0.7-1.5-1.5V23.2c0-0.9,0.7-1.5,1.5-1.5h8.5c0.9,0,1.5,0.7,1.5,1.5V40
	C105.2,40.8,104.5,41.5,103.7,41.5z"/>
            <path fill="#CCD2E3" d="M137.9,41.5h-8.5c-0.9,0-1.5-0.7-1.5-1.5V23.2c0-0.9,0.7-1.5,1.5-1.5h8.5c0.9,0,1.5,0.7,1.5,1.5V40
	C139.4,40.8,138.8,41.5,137.9,41.5z"/>
</svg>

    );
}

export default UsbPortSvg;