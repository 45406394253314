import React from 'react';
import styled, {css} from "styled-components";

const styles = css(({theme: {layout}}) => css`
  outline: none;
  border: none;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  display: flex;
  justify-content: center;
  cursor: pointer;
  font-size: 15px;
  color: ${layout.textColor};
  background: ${layout.buttonBackground};
  padding: 5px 15px;
  border-radius: 7px;
  box-shadow: -4px 4px 10px rgba(0, 0, 0, 0.05);

  &.blue {
    background: ${layout.buttonBackground2};
    color: ${layout.buttonColor2};
  }
`);

export const StyledButton = styled.button`
  ${styles};
`;

const StyledLink = styled.a`
  ${styles};
`;

function Button({children, ...props}: any) {
    return (
        <StyledButton {...props}>
            {children}
        </StyledButton>
    );
}

function Link({children, href, ...props}: any) {
    return (
        <StyledLink {...props} href={href}>
            {children}
        </StyledLink>
    );
}

Button.Link = Link;

export default Button;